import React from 'react';

import { AwardPropPT } from 'views/modules/moduleConstants';
import { ImageWrapper } from 'views/components/ImageWrapper/ImageWrapper';

// These should match the Award.Type in the CMS
export const AWARD_TYPES = {
  BEST_AWARD: 'Best Awards',
  GOOD_DESIGN: 'Good Design Awards',
  WEBBY: 'Webby Awards',
};

//  Best award outcomes that should have "winner" added to them
const BEST_WINNER_OUTCOMES = ['Gold', 'Silver', 'Bronze'];

export function getAwardName(awardType) {
  switch (awardType) {
    case AWARD_TYPES.BEST_AWARD:
      return 'Best Awards';
    case AWARD_TYPES.GOOD_DESIGN:
      return 'Good Design Awards';
    case AWARD_TYPES.WEBBY:
      return 'Webby Awards';
    default:
      return '';
  }
}

function createAwardOutcome(award) {
  let prefix = award.outcome;

  if (award.type === AWARD_TYPES.BEST_AWARD && BEST_WINNER_OUTCOMES.includes(award.outcome)) {
    prefix = `${award.outcome} winner`;
  }

  return `${prefix} ${award.year}`;
}

export const Award = (props) => {
  const { award } = props;
  if (!award) {
    return null;
  }

  const iconURL = award.icon.file.url;

  return (
    <div className="Award">
      <div className="Award-image">
        <ImageWrapper url={iconURL} altText="" />
      </div>

      <div className="Award-blurb">
        <p className="Award-blurb-title small u-mT-0 u-mB-0 u-textPrimary">
          {getAwardName(award.type)}
        </p>
        <p className="Award-blurb-winner small u-mT-0 u-mB-0 u-textPrimary">
          {createAwardOutcome(award)}
        </p>
      </div>
    </div>
  );
};

Award.propTypes = {
  award: AwardPropPT,
};

Award.defaultProps = {
  award: null,
};
