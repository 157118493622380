// @ts-nocheck
import React, { SyntheticEvent, useState, useEffect } from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import { Loader } from 'views/components/Loader/Loader';
import TrackableLink from 'views/connectors/TrackableLink';
import { trackEvent } from 'tools/analytics/track-event';

/**
 * This is the Button (CallToAction) component.
 *
 * It will render an anchor or button element based on what value is passed into the onClick prop.
 *
 * - If a `function` is passed into `onClick` then a button will be rendered.
 * - If a `string` is passed into `onClick` then a anchor will be rendered.
 *
 * You are required to pass in the correct classes (`Button` or `Link`)
 * The only default classes are ones for when the button isLoading or isDisabled
 *
 * - If `isLoading` is `true` then `is-loading` is added as a class.
 * - If `isDisabled` or `isFauxDisabled` is `true` then `is-disabled` is added as a class.
 *
 * Here's some common examples:
 *
 * 1. Link
  ```
    <Button onClick="/home" className="Link" label="HOME" />
  ```
 *
 * 2. Button
  ```
    <Button onClick={this.myFunction} className="Button Button--primary" label="LETS_TALK" />
  ```
 *
 * 3. Button (submit)
  ```
    <Button
      onClick={this.submitForm}
      className="Button Button--primary"
      label="SEND_MESSAGE"
      isLoading={isLoading}
      isDisabled={isLoading}
      type="submit"
    />
  ```
 */

type functionType = (e?: any) => void;

interface IProps {
  actionEvent?: any // The actionEvent that is passed to google analytics
  className?: string;
  hasArrow?: boolean;
  id?: string;
  intl: any;
  isDisabled?: boolean;
  isFauxDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  linkTarget?: string;
  linkType?: string; // Link props
  locationState?: any; // Passed through to the route location.state prop
  onClick?: string | functionType;
  role?: string;
  shouldTrack?: boolean; // Button props
  type?: 'button' | 'submit' | 'reset'; // Button props
}

const CallToAction = ({
  className,
  id,
  intl,
  isDisabled,
  isFauxDisabled,
  isLoading,
  label: labelId,
  role,
  hasArrow = true,
  onClick,
  linkType = 'internal',
  linkTarget,
  locationState,
  type = 'button',
  actionEvent,
  shouldTrack,
}: IProps) => {
  const handleClick = (event: SyntheticEvent, label: string) => {
    // Capture analytics
    if (shouldTrack) {
      trackEvent({ label, event: actionEvent });
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const [intlLabel, setIntlLabel] = useState('');

  useEffect(() => {
    if (labelId) {
      const containsLowerCase = labelId.match(/[a-z]/);
      if (containsLowerCase) {
        setIntlLabel(labelId);
      } else {
        setIntlLabel(intl.formatMessage({ id: labelId }));
      }
    }
  }, [labelId, setIntlLabel, intl]);

  const arrow = hasArrow && <LinkArrow />;

  // If the onClick is a function - it's a button,
  // otherwise it's a string which is a link
  const isLink = typeof onClick !== 'function';

  if (isLink) {
    return (
      <TrackableLink
        url={onClick}
        type={linkType}
        className={classnames(className, {
          'is-loading': isLoading,
          'is-disabled': isDisabled || isFauxDisabled,
        })}
        disabled={isDisabled}
        label={intlLabel}
        id={id}
        event={actionEvent}
        aria-label={intlLabel}
        locationState={locationState}
        target={linkTarget}
      >
        <span>
          {intlLabel}
          {arrow}
        </span>
      </TrackableLink>
    );
  }

  return (
  // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={classnames(className, {
        'is-loading': isLoading,
        'is-disabled': isDisabled || isFauxDisabled,
      })}
      onClick={(event: SyntheticEvent) => handleClick(event, intlLabel)}
      disabled={isDisabled}
      data-label={intlLabel}
      role={role}
      id={id}
      aria-label={intlLabel}
    >
      <Loader shouldRender={isLoading} />
      <span>
        {intlLabel}
        {arrow}
      </span>
    </button>
  );
};

export const LinkArrow = () => (
  <svg viewBox="0 0 39 8" className="LinkArrow">
    <g stroke="#979797" strokeWidth="2" strokeLinecap="round">
      <path d="M1,4 L38,4" id="body" className="LinkArrow-body" />
      <path d="M38,4 L35,7" id="head-lower" className="LinkArrow-head" />
      <path d="M38,4 L35,1" id="head-upper" className="LinkArrow-head" />
    </g>
  </svg>
);

export const Button = injectIntl(CallToAction);
