// NOTE: This is a generated file, nothing you do here matters
// The source is the all.svg file located at './src/all.svg'
// The script that generates this file is located at tools/svg-generator.js
// To rebuild this file run 'yarn run generate-svgs'
import React from 'react';

export default {
  'alphero-logo': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 40">
      <use xlinkHref="#alphero-logo" />
    </svg>
  ),
  'alphero_logo_reversed': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 40">
      <use xlinkHref="#alphero_logo_reversed" />
    </svg>
  ),
  'app-store': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 36">
      <use xlinkHref="#app-store" />
    </svg>
  ),
  'award-best-awards': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <use xlinkHref="#award-best-awards" />
    </svg>
  ),
  'award-cssda': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <use xlinkHref="#award-cssda" />
    </svg>
  ),
  'award-good-design': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <use xlinkHref="#award-good-design" />
    </svg>
  ),
  'award-webbys': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <use xlinkHref="#award-webbys" />
    </svg>
  ),
  'close-grey': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#close-grey" />
    </svg>
  ),
  'collapse': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#collapse" />
    </svg>
  ),
  'expand': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#expand" />
    </svg>
  ),
  'ic-tsunamiready-small': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
      <use xlinkHref="#ic-tsunamiready-small" />
    </svg>
  ),
  'ic-tsunamiready': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 71">
      <use xlinkHref="#ic-tsunamiready" />
    </svg>
  ),
  'mute': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#mute" />
    </svg>
  ),
  'new-alphero-logo': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 40">
      <use xlinkHref="#new-alphero-logo" />
    </svg>
  ),
  'nosubtitles': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#nosubtitles" />
    </svg>
  ),
  'pause': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#pause" />
    </svg>
  ),
  'play-hover-large': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
      <use xlinkHref="#play-hover-large" />
    </svg>
  ),
  'play-hover-small': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#play-hover-small" />
    </svg>
  ),
  'play-small': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#play-small" />
    </svg>
  ),
  'play': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
      <use xlinkHref="#play" />
    </svg>
  ),
  'signature': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 50">
      <use xlinkHref="#signature" />
    </svg>
  ),
  'subtitles': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#subtitles" />
    </svg>
  ),
  'volume': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#volume" />
    </svg>
  ),
};
