import { connect } from 'react-redux';

import { HeroBannerModule } from 'views/modules/HeroBanner/HeroBanner';

const mapStateToProps = (state) => ({
  isViewportMobile: state.browser.is.mobile,
});

const ConnectedModule = connect(
  mapStateToProps,
  null,
)(HeroBannerModule);

export { ConnectedModule as HeroBannerModule };
