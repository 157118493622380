import React, { Component } from 'react';
import Measure from 'react-measure';
import classNames from 'classnames';

import { ImageProp } from 'views/modules/moduleConstants';
import { Animator } from 'views/components/Animator/Animator';
import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { HeroBannerBackground } from './HeroBannerBackground/HeroBannerBackground';

export const ZEPLIN_ID = 'HER02-01a';

export interface HeroBannerProps {
  backgroundImage?: ImageProp;
  backgroundImageMobile?: ImageProp;
  foregroundImage?: ImageProp;
  foregroundImageMobile?: ImageProp;
  id: string;
  isLightTheme: boolean;
  isViewportMobile: boolean;
  text: string;
  clientName: string;
}

interface HeroBannerState {
  backgroundImageLoaded: boolean;
  maskHeight?: number;
}

export class HeroBannerModule extends Component<HeroBannerProps, HeroBannerState> {
  constructor(props: HeroBannerProps) {
    super(props);

    this.state = {
      backgroundImageLoaded: false,
    };
  }

  onPreviewReady = () => {
    this.setState({
      backgroundImageLoaded: true,
    });
  }

  constructBackground = () => {
    const {
      backgroundImage,
      backgroundImageMobile,
    } = this.props;

    // backgroundAnimation takes priority over the backgroundImage

    const isImage = backgroundImage && backgroundImage.file.details.image != null;

    return (
      <div className="HeroBanner-background">
        <div className="HeroBanner-background-asset">
          {isImage && backgroundImage?.file && backgroundImageMobile?.file && (
            <HeroBannerBackground
              onPreviewReady={this.onPreviewReady}
              smallImage={backgroundImageMobile}
              largeImage={backgroundImage}
            />
          )}
          {/* At the moment of starting to pass `shouldLoop` there was only one Case Study whose hero had an animation.
        If we were to have more, we should shift the `shouldLoop` option for the Hero to the CMS. */}
          {!isImage && backgroundImage && <Animator animation={backgroundImage} shouldLoop alt={backgroundImage.description} />}
        </div>
      </div>
    );
  }

  renderTextContent = (clientName: string, text: string, isLightTheme: boolean) => {
    const textClass = classNames({
      'u-textWhite': isLightTheme,
    });

    return (
      <div className="HeroBanner-content-words u-sm-width1of1 u-md-width6of12">
        <h2 className={`p u-block u-mB-xs u-mT-0 ${textClass}`}>{clientName}</h2>
        <p className={`h3 u-block u-mT-0 u-mB-0 ${textClass}`}>{text}</p>
      </div>
    );
  }

  render() {
    const {
      maskHeight,
      backgroundImageLoaded,
    } = this.state;

    const {
      id,
      clientName,
      text,
      foregroundImage,
      foregroundImageMobile,
      isLightTheme,
      isViewportMobile,
    } = this.props;

    const fgIsImage = foregroundImage && foregroundImage.file.details.image;

    const heroClassnames = classNames('HeroBanner', {
      'HeroBanner--light': isLightTheme,
      'HeroBanner--backgroundLoading': !backgroundImageLoaded,
    });

    return (
      <section className="ModuleWrapper ModuleWrapper--top">
        <div className="Container">
          <ComponentInfo id={id} zeplinId={ZEPLIN_ID} />

          <Measure
            bounds
          >
            {({ measureRef:bannerRef }) => (
              <div className={heroClassnames} ref={bannerRef}>

                {isViewportMobile && this.renderTextContent(clientName, text, false)}
                <div className="HeroBanner-wrapper">
                  {this.constructBackground()}

                  <div className="HeroBanner-content">
                    {!isViewportMobile && this.renderTextContent(clientName, text, isLightTheme)}
                    <Measure
                      bounds
                      onResize={(imageRect) => this.setState({ maskHeight: imageRect?.bounds?.height })}
                    >
                      {({ measureRef: imageRef }) => (
                        <div className="HeroBanner-content-image u-md-before1of12 u-md-width5of12" ref={imageRef}>
                          {fgIsImage && foregroundImage?.file && foregroundImageMobile && (
                            <LargieSmalls
                              mobile={(
                                <ResponsiveCmsImageWrapper
                                  HeroBanner
                                  imageData={foregroundImageMobile}
                                  hasLoader={false}
                                  expectedHeight={maskHeight}
                                  key="mobile"
                                  useImgTag
                                  isContain
                                  url={foregroundImageMobile && foregroundImageMobile.file && foregroundImageMobile.file.url}
                                  altText={foregroundImageMobile.description}
                                />
                              )}
                              tablet={(
                                <ResponsiveCmsImageWrapper
                                  HeroBanner
                                  imageData={foregroundImage}
                                  hasLoader={false}
                                  expectedHeight={maskHeight}
                                  key="tablet"
                                  useImgTag
                                  isContain
                                  url={foregroundImage && foregroundImage.file && foregroundImage.file.url}
                                  altText={foregroundImage.description}
                                />
                              )}
                            />
                          )}
                          {/* At the moment of starting to pass `shouldLoop` there was only one Case Study whose hero had an animation.
                          If we were to have more, we should shift the `shouldLoop` option for the Hero to the CMS. */}
                          {!fgIsImage && foregroundImage && <Animator animation={foregroundImage} shouldLoop alt={foregroundImage.description} />}
                        </div>
                      )}
                    </Measure>
                  </div>
                </div>
              </div>
            )}
          </Measure>
        </div>
      </section>
    );
  }
}
