import React from 'react';
import { FormattedMessage } from 'react-intl';
import TagManager from 'react-gtm-module';

import { Office, IOfficeProps } from 'views/components/Office/Office';

import { useSelector } from 'react-redux';
import { getOffices } from 'redux/modules/cms/selectors';

export const OfficeFooterContent = () => {
  const logOfficeClick = (officeName : string) => {
    TagManager.dataLayer({ dataLayer: { event: 'click-to-call', talkCallLocation: officeName } });
  };

  const offices: IOfficeProps[] = useSelector(getOffices) ?? [];

  return (
    <div className="Footer-offices">
      <h2 className="h3 u-mT-0 u-mB-sm">
        <FormattedMessage id="OUR_OFFICES" />
      </h2>
      <hr className="FancyRule" aria-hidden />
      <ul className="Footer-offices-list">
        {offices.map(({
          addressLineOne,
          addressLineThree,
          addressLineTwo,
          contactName,
          location,
          phoneNumber,
        }) => (
          <li key={`${location}-`} onClick={() => logOfficeClick(location)} className="Footer-offices-list-item u-textWhite">
            <Office
              location={location}
              addressLineOne={addressLineOne}
              addressLineThree={addressLineThree}
              addressLineTwo={addressLineTwo}
              contactName={contactName}
              phoneNumber={phoneNumber}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
