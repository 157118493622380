import React, { Component } from 'react';
import classNames from 'classnames';

import { ImageProp } from 'views/modules/moduleConstants';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { Animator } from 'views/components/Animator/Animator';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { InViewport } from 'views/enhancers/InViewport';
import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';

const ANIMATION_OPTIONS = {
  autoplay: false,
};

export interface DiagonalComponentProps {
  animation?: ImageProp;
  animationMobile?: ImageProp;
  animationThreshold?: number;
  id: string;
  image?: ImageProp;
  imageMobile?: ImageProp;
  inViewport?: boolean;
  isTextTopLeft?: boolean;
  leftBigText?: string;
  leftSubtext?: string;
  leftText?: string;
  rightBigText?: string;
  rightSubtext?: string;
  rightText?: string;
  shouldAnimateOnLeave?: boolean;
  shouldLoop?: boolean;
}

interface DiaginalComponentState {
  wasInViewport: boolean;
  shouldPlay: boolean;
}

export const DiagonalComponent = class DiagonalComponent extends Component<DiagonalComponentProps, DiaginalComponentState> {
  constructor(props: DiagonalComponentProps) {
    super(props);

    this.state = {
      wasInViewport: false,
      shouldPlay: false,
    };
  }

  runAnimation = () => {
    this.setState({
      shouldPlay: true,
    });
  };

  render() {
    const {
      animation,
      animationMobile,
      animationThreshold,
      id,
      image,
      imageMobile,
      isTextTopLeft,
      leftBigText,
      leftText,
      leftSubtext,
      rightBigText,
      rightText,
      rightSubtext,
      shouldLoop,
      shouldAnimateOnLeave,
    } = this.props;

    const inViewportProps = shouldAnimateOnLeave
      ? { onExit: this.runAnimation }
      : { onEnter: this.runAnimation };

    const { shouldPlay } = this.state;
    const zeplinId = isTextTopLeft ? 'DIA01-01a' : 'DIA01-01b';
    const showImage = !animation;
    const captionBaseClasses = 'u-md-width5of12 CaseStudy-diagonal-caption u-textPrimary';

    const leftTextClassNames = classNames('Grid-cell', captionBaseClasses, {
      'CaseStudy-diagonal-caption--bottom': !isTextTopLeft,
    });

    const rightTextClassNames = classNames(
      captionBaseClasses,
      'Grid-cell',
      'CaseStudy-diagonal-caption--right',
      {
        'CaseStudy-diagonal-caption--bottom': isTextTopLeft,
      },
    );

    const animationToDisplay = animationMobile || animation;
    return (
      <InViewport targetThreshold={animationThreshold || 0.9} {...inViewportProps}>
        {({ elementToObserveRef }: any) => (
          <section className="CaseStudy-diagonal ModuleWrapper" ref={elementToObserveRef}>
            <div className="Container">
              <ComponentInfo id={id} zeplinId={zeplinId} />
              <div className="CaseStudy-diagonal-content Grid">
                {/* Left Caption */}
                <div className={leftTextClassNames}>
                  <Caption bigText={leftBigText} text={leftText} subtext={leftSubtext} />
                </div>

                {/* Background Image OR Lottie Animation */}
                <div className="CaseStudy-diagonal-background">
                  {showImage && (
                    <LargieSmalls
                      mobile={
                        imageMobile && (
                          <ResponsiveCmsImageWrapper
                            imageData={imageMobile}
                            url={imageMobile.file.url}
                            key={imageMobile.file.url}
                            useImgTag
                            expectedHeight={250}
                            altText={imageMobile.description}
                          />
                        )
                      }
                      tablet={
                        image && (
                          <ResponsiveCmsImageWrapper
                            imageData={image}
                            url={image.file.url}
                            key={image.file.url}
                            useImgTag
                            expectedHeight={500}
                            altText={image.description}
                          />
                        )
                      }
                    />
                  )}

                  {animation && (
                    <LargieSmalls
                      mobile={
                        animationMobile && (
                          <Animator
                            key="mobile"
                            shouldPlay={shouldPlay}
                            animation={animationToDisplay}
                            lottieOptions={ANIMATION_OPTIONS}
                            shouldLoop={shouldLoop}
                            alt={animationToDisplay?.description}
                          />
                        )
                      }
                      tablet={
                        animation && (
                          <Animator
                            key="tablet"
                            shouldPlay={shouldPlay}
                            animation={animation}
                            lottieOptions={ANIMATION_OPTIONS}
                            shouldLoop={shouldLoop}
                            alt={animation.description}
                          />
                        )
                      }
                    />
                  )}
                </div>

                {/* Right Caption */}
                <div className={rightTextClassNames}>
                  <Caption bigText={rightBigText} text={rightText} subtext={rightSubtext} />
                </div>
              </div>
            </div>
          </section>
        )}
      </InViewport>
    );
  }
};

export const DiagonalModule = DiagonalComponent;

interface CaptionProps {
  bigText?: string;
  subtext?: string;
  text?: string;
}

const Caption = ({ bigText, text, subtext }: CaptionProps) => {
  const subtextMarginTop = classNames('u-mB-0', {
    'u-mT-0': !bigText && !text,
  });

  return (
    <>
      {bigText && <p className="CaseStudy-diagonal-bigText display">{bigText}</p>}
      {text && <p className="h4 u-md-mT-0 u-mB-0 u-textPrimary">{text}</p>}
      {subtext && <p className={subtextMarginTop}>{subtext}</p>}
    </>
  );
};
