export const formatContent = (item) => {
  if (!item) {
    return item;
  }

  const { fields, sys } = item;

  if (!fields) {
    return item;
  }

  const formattedEntry = {};

  Object.keys(fields).forEach((key) => {
    const field = fields[key];

    if (field.constructor === Object) {
      if (field.file) {
        formattedEntry[key] = {
          ...formatContent(field),
          ...formatContent(field.file),
        };
        delete formattedEntry[key].file;
      } else {
        formattedEntry[key] = formatContent(field);
      }
    } else if (Array.isArray(field)) {
      formattedEntry[key] = field.map((content) => formatContent(content));
    } else {
      formattedEntry[key] = field;
    }

    return formattedEntry;
  });

  formattedEntry.id = sys.id;
  formattedEntry.contentType = sys.contentType && sys.contentType.sys ? sys.contentType.sys.id : null;

  return formattedEntry;
};

export const formatEntries = (content) => {
  const { items } = content;

  if (!items || !items.length) {
    return [];
  }

  return items.map((item) => formatContent(item));
};

/*
 * Returns a key to be used for caching CMS content for localStorage
 * based on the Content Type, filters and sorts used for the AJAX request.
 */
export const setStorageKey = (contentType, filter, sort) => {
  let storageKey = contentType;

  if (filter) {
    Object.keys(filter).forEach((key) => {
      storageKey = `${storageKey}-${key}-${filter[key]}`;
    });
  }

  if (sort) {
    Object.keys(sort).forEach((key) => {
      storageKey = `${storageKey}-${key}-${sort[key]}`;
    });
  }

  return storageKey;
};

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatDate = (dateAsString) => {
  const dateAsDate = new Date(dateAsString);
  const month = MONTHS[dateAsDate.getMonth()];

  return `${dateAsDate.getDate()} ${month} ${dateAsDate.getFullYear()}`;
};
