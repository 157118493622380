import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IS_DEVELOPMENT } from 'redux/modules/app/constants';

import { ModuleNotFound } from 'views/components/ModuleNotFound/ModuleNotFound';

export class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-console
    console.error(error);

    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    const { children, name } = this.props;
    const { error } = this.state;

    if (error) {
      return IS_DEVELOPMENT ? (
        <ModuleNotFound
          message="There is an error in this module, please check the console for more information."
          name={name}
        />
      ) : null;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};
