import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { injectIntl } from 'react-intl';

import { Icon } from 'views/components/Icon/Icon';
import { trackEvent } from 'tools/analytics/track-event';
import { ACTION_EVENT } from 'tools/analytics/constants';

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      swiper: null,
    };
  }

  goNext = () => {
    const { swiper } = this.state;

    if (swiper) {
      // Analytics
      const { eventName } = this.props;
      trackEvent({
        label: eventName || ACTION_EVENT.CAROUSEL,
        event: {
          direction: 'next',
        },
      });
    }
  }

  goPrev = () => {
    const { swiper } = this.state;

    if (swiper) {
      // Analytics
      const { eventName } = this.props;
      trackEvent({
        label: eventName || ACTION_EVENT.CAROUSEL,
        event: {
          direction: 'prev',
        },
      });
    }
  }

  renderNavigationButton = (direction = 'prev') => {
    // @NOTE: No need to call swiper.slideNext() or swiper.slidePrev() as
    // this is already handled by swiper

    const buttons = {
      prev: {
        icon: 'arrow-back',
        action: () => this.goPrev(),
      },
      next: {
        icon: 'arrow-forwards',
        action: () => this.goNext(),
      },
    };

    const button = buttons[direction];

    return (
      <button className={`Slider-button Slider-button--${direction}`} type="button" onClick={() => button.action()}>
        <Icon name={button.icon} />
      </button>
    );
  };

  setSwiper = (element) => {
    const { swiper: existingSwiper } = this.state;

    // Prevent infinite loop
    if (existingSwiper) {
      return;
    }

    if (element && element.swiper) {
      this.setState({
        swiper: element.swiper,
      });
    }
  }

  render() {
    const { children, classes, sliderSettings } = this.props;

    const params = {
      // default params
      WrapperEl: 'ul',
      containerClass: 'Slider-container',
      slideClass: 'Slider-slide',
      wrapperClass: 'Slider-wrapper',
      spaceBetween: 12,
      direction: 'horizontal',
      keyboard: true,
      slidesPerView: 'auto',
      grabCursor: true,
      noSwipingSelector: 'button',
      navigation: {
        nextEl: '.Slider-button--next',
        prevEl: '.Slider-button--prev',
      },
      pagination: {
        el: '.Slider-pagination',
        type: 'bullets',
      },
      renderPrevButton: () => this.renderNavigationButton('prev'),
      renderNextButton: () => this.renderNavigationButton('next'),
      shouldSwiperUpdate: true,

      // declared params
      ...sliderSettings,
    };

    return (
      <div className={classnames('Slider', classes)}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Swiper {...params} ref={(element) => this.setSwiper(element)}>{children}</Swiper>
      </div>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  classes: PropTypes.string,
  // The eventName for analytics, defaults to ACTION_EVENT.CAROUSEL
  eventName: PropTypes.string,
  intl: PropTypes.shape({}).isRequired,
  sliderSettings: PropTypes.shape({}),
};

Slider.defaultProps = {
  classes: null,
  sliderSettings: {},
  eventName: null,
};

export default injectIntl(Slider);
