import React from 'react';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';

export interface IQuoteModule {
  contentType: string;
  nameAndRole: string;
  colourVariation: string;
  quote: string;
  id: string;
}

interface IProps {
  content: IQuoteModule;
}

export const QuoteModule = ({ content }: IProps) => (
  <div className="QuoteModule">
    <div className="Container">
      <ComponentInfo zeplinId="FMOD08-01a" id={content.id} isBlogContent />
      <div className="Grid">
        <div className="Grid-cell u-md-before1of12 u-xx-before2of12 u-md-width5of6 u-xx-width2of3 u-textCenter">
          <span className="QuoteModule-quoteIcon" aria-hidden />
          <blockquote className="QuoteModule-text">{content.quote}</blockquote>
          <cite className="QuoteModule-citation">{content.nameAndRole}</cite>
        </div>
      </div>
    </div>
  </div>
);
