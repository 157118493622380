import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DefaultLayout } from 'views/layouts/DefaultLayout/DefaultLayout';
import { RenderModules } from 'views/components/RenderModules/RenderModules';
import { MODULE_NAMES } from 'redux/modules/app/constants';
import { Meta } from 'views/components/Meta/Meta';

const notFoundId = '3r8Qbg6EvwcCG0umz5eFxQ';

const pageNotFoundModule = {
  contentType: MODULE_NAMES.CASE_STUDY.SPLIT_MODULE,
  name: 'pageNotFoundModule',
  text: 'We’ve searched everywhere, but couldn’t find the page you were looking for.',
  title: 'Page not found',
  textAlignment: 'CENTRE',
  id: 'not-found-module',
  image: {
    file: {
      url: '/assets/images/infinite-cats.svg',
    },
  },
};

class PageNotFound extends Component {
  componentDidMount() {
    const { getNotFound } = this.props;

    if (!getNotFound) {
      return;
    }

    getNotFound(notFoundId);
  }

  render() {
    const { page } = this.props;
    let { isLoading } = page;
    let modules = page.data;

    // We haven't requested data yet
    if (isLoading == null && modules == null) {
      isLoading = true;
      modules = [];
    }

    // We've loaded but there's no data from the CMS, so show the fallback
    if (!isLoading && (!modules || modules.length === 0)) {
      modules = [pageNotFoundModule];
    }

    return (
      <DefaultLayout isLoading={isLoading}>
        <Meta title={pageNotFoundModule.title} />
        <div className="u-textCenter">
          <RenderModules modules={modules} hasButton />
        </div>
      </DefaultLayout>
    );
  }
}

PageNotFound.propTypes = {
  getNotFound: PropTypes.func.isRequired,
  page: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
};
export default withRouter(PageNotFound);
