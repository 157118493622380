import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "views/components/Button/Button";
import { OfficeFooterContent } from "views/components/OfficeFooterContent/OfficeFooterContent";

import { ComponentInfo } from "views/components/ComponentInfo/ComponentInfo";
import { Icon } from "../Icon/Icon";

interface IProps {
  isOfficeFooter?: boolean;
}

export const Footer = (props: IProps) => {
  const { isOfficeFooter } = props;

  const zeplinId = isOfficeFooter ? "FOOT01-01b" : "FOOT01-01a";

  // Display the normal footer
  let footerContent = <DefaultFooterContent />;
  if (isOfficeFooter) {
    // Display the office location footer
    footerContent = <OfficeFooterContent />;
  }

  return (
    <footer className="Footer">
      <div className="Container">
        <ComponentInfo zeplinId={zeplinId} />

        {footerContent}

        {/* Links to social media */}
        {/* <SocialLinks className="Footer-social" /> */}

        {/* Footer copyright */}
        <div className="Footer-credit">
          <p className="p u-mT-0 u-mB-0 u-textBlueChalk">
            <FormattedMessage
              id="FOOTER_DISCLAIMER"
              values={{ currentYear: new Date().getFullYear() }}
            />
            {" • "}
            <a
              href="https://alphero.com/privacy-policy"
              className="footer-privacy"
            >
              Privacy Policy
            </a>
          </p>

          <a href="https://alphero.com/">
            <img
              src="/assets/svg-icons/signature.svg"
              data-icon="signature"
              alt="Go home"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

const DefaultFooterContent = () => (
  <div className="Footer-content Grid">
    <div className="Grid-cell u-mB-sm u-md-mB-lg u-md-width8of12 u-xl-width9of12">
      <a href="https://alphero.com/">
        <span className="u-hiddenVisually">Go home</span>

        <Icon name="new-alphero-logo" />
      </a>
    </div>
    <div className="Grid-cell u-sm-textCenter u-sm-width2of3 u-sm-before2of12 u-md-width3of12 u-xl-width3of12">
      <Button
        onClick="https://alphero.com/contact"
        className="Button Button--footer Footer-button"
        label="LETS_TALK"
        linkType="external"
        linkTarget="_blank"
      />
    </div>
  </div>
);
