/**
 * Checks if the user's system preferences say they prefer reduced motion / no animations.
 * This should be used to decide whether to disable animations (especially ones that aren't
 * triggered by the user or affect a large portion of the screen)
 */
export function prefersReducedMotion() {
  if (!window.matchMedia) {
    return false;
  }

  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

  return mediaQuery.matches;
}
