import ReactGA from 'react-ga';

import { createActionEvent } from './constants';

/**
 * TrackEvent is a function that is added to buttons onClick function for recording analytics
 * It takes a label, event (optional), and onClick (optional)
 *
 * It is used to record events via ReactGA - it creates an object with a category, action, and label
 * ReactGA requires the action to be a string - see createActionEvent function declaration
 *
 * @param {string} label The label to be added to the google action event
 * @param {analyticsEvent} event (Optional) The analytics constants event object to be captured (defaults to label if undefined)
 *
 * @param {function} onClick (Optional) The onClick function to run - see example 4.
 *
 * Examples:
 * 1. Used as a function inside your onClick function (just label)
 * `trackEvent({ label: 'myTestButton' });`
 *
 * 2. Used as a function inside your onClick function (event is a `string`)
 * `trackEvent({ label: 'myTestButton', event: secretCodeString });`
 *
 * 3. Used as a function inside your onClick function (event is an `object`)
 * `trackEvent({ label: 'myTestButton', event: { importantVariable: myImportantObject } });`
 *
 * 4. Used within a Button component with onClick passed in
 * `<Button onClick={() => trackEvent({ label: 'myTestButton', onClick: this.executeMyFunc }); }`
 */

export const trackEvent = ({ event, label, onClick }: any) => {
  // Early exit if there's nothing to track
  if (!event && !label) {
    return;
  }

  const actionEvent = createActionEvent(label, event);

  // Fire analytics capture
  if (actionEvent) {
    ReactGA.event(actionEvent);
  }

  // If an onClick function is present then invoke it
  if (onClick) {
    onClick();
  }
};

// Used to replace @ character from text so it's not redacted in analytics
export function replaceAtSymbol(str: string) {
  return str.replace(/[@]/g, '-AT-');
}
