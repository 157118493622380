import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import { ImageProp } from 'views/modules/moduleConstants';
import { Icon } from 'views/components/Icon/Icon';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { Animator } from 'views/components/Animator/Animator';
import { InViewport } from 'views/enhancers/InViewport';

const ANIMATION_OPTIONS = {
  loop: false,
  autoplay: false,
};

enum ColourVariations {
  TEAL = 'Teal',
  GRAPE = 'Grape',
  MIDNIGHT = 'Midnight',
}

export interface Props {
  animation?: ImageProp;
  id: string;
  image?: ImageProp;
  isLoading?: boolean;
  isRound?: boolean;
  shouldAnimateOnLeave?: boolean;
  nameContext?: string;
  quote: string;
  elementToObserveRef: () => void;
  inViewport: boolean;
  colorVariation: ColourVariations;
  animationThreshold?: number;
  name: string;
}

const QuoteComponent = (props: Props) => {
  const [wasInViewport, setWasInViewport] = useState<boolean>(false);

  const {
    id,
    image,
    quote,
    nameContext,
    isLoading,
    isRound = true,
    animation,
    shouldAnimateOnLeave,
    inViewport,
    colorVariation,
    elementToObserveRef,
    name,
  } = props;

  useEffect(() => {
    if (shouldAnimateOnLeave && inViewport) {
      setWasInViewport(true);
    }
  }, [shouldAnimateOnLeave, inViewport]);

  let zeplinID = 'QTE01-01a';

  if (image && !isRound) {
    zeplinID = 'QTE01-01c';
  } else if (!image) {
    zeplinID = 'QTE01-01b';
  } else {
    zeplinID = 'QTE01-01a';
  }

  const hasImage = !!(image && image.file && image.file.url);

  const shouldPlay = (inViewport && !shouldAnimateOnLeave)
    || (!inViewport && wasInViewport && shouldAnimateOnLeave);

  const nameWithoutSpaces = name.replace(/\s/g, '');

  return (
    <section
      id={nameWithoutSpaces}
      className="CaseStudy-quote ModuleWrapper"
      ref={elementToObserveRef}
    >
      <div className="Container">
        <ComponentInfo id={id} zeplinId={zeplinID} />
        <div className="CaseStudy-quote-container Grid">
          {isLoading && <div className="SkeletonRect SkeletonRect--16x9" />}
          {hasImage && (
            <div
              className={classNames(
                'CaseStudy-quote-container-content Grid-cell Grid-cell--center',
                {
                  'u-sm-width5of6 u-width1of3 is-round': isRound,
                },
              )}
            >
              <ResponsiveCmsImageWrapper
                imageData={image}
                url={image?.file.url}
                useImgTag
                isRound={isRound}
                altText={image?.description}
              />
            </div>
          )}
          {animation && (
            <div className="CaseStudy-quote-container-content">
              <Animator
                shouldPlay={shouldPlay}
                animation={animation}
                lottieOptions={ANIMATION_OPTIONS}
                alt={animation.description}
              />
            </div>
          )}
          {!isLoading && (
            <div
              className={classNames(
                'CaseStudy-quote-container-logo u-textCenter',
                {
                  'CaseStudy-quote-container-logo--grape':
                    colorVariation === 'Grape',
                  'CaseStudy-quote-container-logo--teal':
                    colorVariation === 'Teal',
                  'CaseStudy-quote-container-logo--midnight':
                    colorVariation === 'Midnight',
                },
              )}
            >
              <Icon name="quote" />
            </div>
          )}
        </div>

        {quote && (
          <blockquote className="h4 CaseStudy-quote-text u-md-width5of6 u-xx-width2of3">
            {quote}
          </blockquote>
        )}

        {nameContext && (
          <cite className="p CaseStudy-quote-citation u-md-width5of6 u-xx-width2of3">
            {nameContext}
          </cite>
        )}
      </div>
    </section>
  );
};

export const QuoteModule = (props: Props) => {
  const {
    animationThreshold = 0.8,
    id,
    image,
    quote,
    nameContext,
    isLoading,
    isRound = true,
    animation,
    shouldAnimateOnLeave,
    colorVariation,
    name,
  } = props;

  return (
    <InViewport targetThreshold={animationThreshold}>
      {({
        elementToObserveRef,
        isInViewport,
      }: {
        elementToObserveRef: any;
        isInViewport: boolean;
      }) => (
        <QuoteComponent
          id={id}
          image={image}
          quote={quote}
          nameContext={nameContext}
          isLoading={isLoading}
          isRound={isRound}
          animation={animation}
          shouldAnimateOnLeave={shouldAnimateOnLeave}
          colorVariation={colorVariation}
          inViewport={isInViewport}
          elementToObserveRef={elementToObserveRef}
          name={name}
        />
      )}
    </InViewport>
  );
};
