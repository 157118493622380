import React, { Component } from "react";
import Measure from "react-measure";
import { BLOCKS } from "@contentful/rich-text-types";

import { DataProp } from "views/modules/moduleConstants";
import { ComponentInfo } from "views/components/ComponentInfo/ComponentInfo";
import { RichText } from "views/components/RichText/RichText";
import { Button } from "views/components/Button/Button";

export const ZEPLIN_ID = "WHO01-01a";

const CONTENT_OVERRIDES = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: JSX.Element) => (
      <p className="h4 u-textWhite u-mT-0 u-mB-0">{children}</p>
    ),
  },
};

const CTA_OVERRIDES = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: JSX.Element) => (
      <p className="u-mT-0 u-mB-sm">{children}</p>
    ),
  },
};

export interface WhoWeAreProps {
  callToAction: string;
  content: DataProp;
  ctaContent: DataProp;
  id: string;
  isLoading?: boolean;
  showButton: boolean;
  title: string;
}

export class WhoWeAre extends Component<WhoWeAreProps> {
  state = {
    contentHeight: 435,
    ctaHeight: 244,
  };

  drawBackgroundShape() {
    const { contentHeight } = this.state;

    return (
      <svg
        className="WhoWeAre-content-background"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox={`0 0 901 ${contentHeight}`}
      >
        <path
          fill="#050058"
          d={`M 2 0 L 901 0 L 840 ${contentHeight} L 25 ${contentHeight -
            12} Z`}
        />
      </svg>
    );
  }

  drawForegroundShape() {
    const { ctaHeight } = this.state;

    return (
      <svg
        className="WhoWeAre-content-CTA-background"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        viewBox={`0 0 434 ${ctaHeight}`}
      >
        <defs>
          <linearGradient id="a" x1="-12.9%" y1="32.4%" y2="64.2%">
            <stop offset="0%" stopColor="#FFF" />
            <stop offset="100%" stopColor="#00E8DB" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" strokeWidth="4">
          <path
            fill="url(#a)"
            stroke="#FFF"
            d={`M 7 2 L 2 ${ctaHeight - 15} l 429 -16.8 V 11.3 L 7 2 Z`}
            transform="matrix(-1 0 0 1 433 0)"
          />

          <path
            stroke="#FF0068"
            d={`M 410 ${ctaHeight - 120.2} l 2 117.6 -146 -5.7`}
          />
          <path stroke="#050058" d={`M 410 ${ctaHeight - 123} l 1.7 105.3`} />
        </g>
      </svg>
    );
  }

  render() {
    const zeplinId = ZEPLIN_ID;

    const { ctaHeight } = this.state;

    const {
      content,
      callToAction,
      ctaContent,
      showButton,
      title,
      isLoading,
      id,
    } = this.props;

    if (!content && !isLoading) {
      return null;
    }

    return (
      <section className="WhoWeAre ModuleWrapper">
        <div className="Container">
          <ComponentInfo id={id} zeplinId={zeplinId} />

          {!isLoading && (
            <Measure
              bounds
              onResize={(contentRect) => {
                this.setState({ contentHeight: contentRect?.bounds?.height });
              }}
            >
              {({ measureRef: contentRef }) => (
                <div
                  ref={contentRef}
                  className="WhoWeAre-content"
                  style={{ marginBottom: ctaHeight - 40 }}
                >
                  {/* @NOTE: this is the background SVG */}
                  {this.drawBackgroundShape()}

                  <div className="WhoWeAre-content-wrapper">
                    <h3 className="h5 u-textSecondary u-mT-0 u-mB-xs">
                      {title}
                    </h3>
                    <RichText text={content} overrides={CONTENT_OVERRIDES} />
                  </div>

                  <Measure
                    bounds
                    onResize={(ctaRect) => {
                      this.setState({ ctaHeight: ctaRect?.bounds?.height });
                    }}
                  >
                    {({ measureRef: ctaRef }) => (
                      <div ref={ctaRef} className="WhoWeAre-content-CTA">
                        {/* @NOTE: this is the foreground SVG */}
                        {this.drawForegroundShape()}

                        <div className="WhoWeAre-content-CTA-wrapper">
                          <h3 className="h5 u-mT-0 u-mB-xs">{callToAction}</h3>
                          <RichText
                            text={ctaContent}
                            overrides={CTA_OVERRIDES}
                          />
                          {showButton && (
                            <Button
                              id="GetInTouch-button"
                              onClick="https://alphero.com/contact"
                              locationState={{ formType: "job" }}
                              className="Button Button--primary u-textNoWrap"
                              label="GET_IN_TOUCH"
                              linkType="external"
                              linkTarget="_blank"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Measure>
                </div>
              )}
            </Measure>
          )}
        </div>
      </section>
    );
  }
}
