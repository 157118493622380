import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Observer from '@researchgate/react-intersection-observer';
import Measure from 'react-measure';
import BrowserSizeProvider from 'views/enhancers/BrowserSizeProvider/index';

// See further down for documentation comments

export const SCROLL_DIRECTION = {
  UP: -1,
  DOWN: 1,
};

export function initialiseIntersectionPolyfills() {
  // Chrome on Android has a bug that breaks our use of IntersectionObservers. There's no easy way
  // for us to tell if the bug is occurring, so we have to user-agent sniff :(
  // If it looks like an affected browser, we fall back to using the polyfill since it works.
  // See bug report for details: https://bugs.chromium.org/p/chromium/issues/detail?id=923671
  // Once that has been fixed this `if` statement can be removed.
  const isBrokenChrome = window.devicePixelRatio > 1
    && navigator.userAgent.includes(' Chrome/')
    && navigator.userAgent.includes(' Android ');

  if (isBrokenChrome) {
    // eslint-disable-next-line dot-notation
    delete window['IntersectionObserver'];
    // eslint-disable-next-line dot-notation
    delete window['IntersectionObserverEntry'];
  }

  return import('intersection-observer');
}

class InViewport extends Component {
  previousBoundingClientRect = null;

  elementRef = React.createRef();

  threshold = 1;

  constructor(props) {
    super(props);

    this.state = {
      elementHeight: null,
      isInViewport: false,
      scrollDirection: null,
    };
  }

  onVisibilityChange = (observerEntry) => {
    const { previousBoundingClientRect } = this;
    const currentBoundingClientRect = observerEntry.boundingClientRect;

    let scrollDirection = null;

    if (previousBoundingClientRect) {
      scrollDirection = previousBoundingClientRect.y >= currentBoundingClientRect.y
        ? SCROLL_DIRECTION.DOWN
        : SCROLL_DIRECTION.UP;
    }

    this.previousBoundingClientRect = currentBoundingClientRect;

    const { onEnter, onExit } = this.props;
    const { isInViewport: previouslyInViewport } = this.state;

    const isInViewport = observerEntry.intersectionRatio >= this.threshold && observerEntry.isIntersecting;

    if (onEnter && !previouslyInViewport && isInViewport) {
      onEnter({
        scrollDirection,
      });
    }

    if (onExit && previouslyInViewport && !isInViewport) {
      onExit({
        scrollDirection,
      });
    }

    this.setState({
      isInViewport,
      scrollDirection,
    });
  };

  render() {
    const {
      targetThreshold,
      children,
      viewportHeight,
      viewportTopOffset,
      viewportVerticalAlignment,
    } = this.props;

    let { windowHeight } = this.props;

    // This detects if the polyfill is being used, which has quirks we need to adjust for, yay.
    if ('USE_MUTATION_OBSERVER' in IntersectionObserver.prototype) {
      const documentClientHeight = document.documentElement.clientHeight;

      // The polyfill uses the docuement clientHeight as the height of its "viewport".
      // See https://github.com/w3c/IntersectionObserver/issues/257
      windowHeight = documentClientHeight < windowHeight ? documentClientHeight : windowHeight;
    }

    const { elementHeight, isInViewport, scrollDirection } = this.state;

    const targetVisibleHeight = targetThreshold * elementHeight;
    let threshold = targetThreshold;
    const actualViewportHeight = viewportHeight || windowHeight;

    // If the element is too big to ever actually fit in the viewport we adjust the threshold so
    // that the observer will still fire (the new threshold is the maximum proportion of the element that will ever be visible)
    if (elementHeight != null && targetVisibleHeight >= actualViewportHeight) {
      // We remove 1px, otherwise some browsers (Safari iOS, Android Chrome sometimes) don't fire (presumably due to rounding somewhere deep in the browser)
      // The choice of 1px may need to be tweaked
      threshold = (actualViewportHeight - 1) / elementHeight;
    }

    this.threshold = threshold;

    let rootMargin;

    // If a custom viewport height has been provided we use the IntersectionObserver' `rootMargin`
    // option to resize the viewport appropriately
    if (viewportHeight) {
      let finalViewportHeight = viewportHeight;

      if (typeof viewportHeight === 'string' && viewportHeight.endsWith('%')) {
        const viewportHeightPercent = Number.parseFloat(viewportHeight.substring(0, viewportHeight.length - 1)) / 100;
        finalViewportHeight = viewportHeightPercent * windowHeight;
      }

      let topMargin;
      let bottomMargin;

      const totalMargin = windowHeight - finalViewportHeight;

      switch (viewportVerticalAlignment) {
        case 'top':
          topMargin = windowHeight * viewportTopOffset;
          bottomMargin = windowHeight - finalViewportHeight - topMargin;
          break;
        case 'bottom':
          bottomMargin = windowHeight * (1 - viewportTopOffset);
          topMargin = windowHeight - finalViewportHeight - bottomMargin;
          break;
        case 'middle':
        default:
          topMargin = totalMargin * viewportTopOffset;
          bottomMargin = totalMargin * (1 - viewportTopOffset);
          break;
      }

      rootMargin = `${-topMargin}px 0px ${-bottomMargin}px 0px`;
    }

    return (
      <Observer onChange={this.onVisibilityChange} threshold={threshold} rootMargin={rootMargin}>
        <Measure
          bounds
          onResize={(contentRect) => {
            this.setState({ elementHeight: contentRect.bounds.height });
          }}
        >
          {({ measureRef }) => children({ elementToObserveRef: measureRef, isInViewport, scrollDirection })}
        </Measure>
      </Observer>
    );
  }
}

InViewport.propTypes = {
  /**
   * A function that renders the children components (i.e. the render prop / render-invoked
   * function pattern)
   * Three params will be passed to the function:
   *  - elementToObserveRef: React.Ref - pass this to the `ref` param of the child element you
   * want to observe
   *  - isInViewport: boolean - whether the observed element is visible
   *  - scrollDirection: number (defined in the SCROLL_DIRECTION constant) or null if direction is unknown (before any intersection events have occurred).
   *    Assumed direction of vertical scrolling, based on the observed element's change in position.
   */
  children: PropTypes.func.isRequired,
  /** Function to call when the observed element enters the viewport */
  onEnter: PropTypes.func,
  /** Function to call when the observed element exits the viewport */
  onExit: PropTypes.func,
  /** Number between  0 and 1. Proportion of the observed element that should be in the viewport
   * for it to be considered visible */
  targetThreshold: PropTypes.number.isRequired,

  /** Height of the viewport we're checking the intersection against. Optional - by default
   * the standard viewport of the window will be used.
   * A number value is assumed to be the desired height in pixels
   * A string value ending with '%' is assumed to be the desired height as a percentage of the window viewport height
   * */
  viewportHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Number between 0 and 1.0 (inclusive). Use to vertically shift the custom viewport (specified
   * via `viewportHeight`) by a proportion of the browser's viewport height. e.g. 0.5 will place
   * the custom viewport half-way down the screen */
  viewportTopOffset: PropTypes.number,

  /**
   * How the custom viewport should be vertically aligned relative to the viewportTopOffset.
   * 'top' will cause the top of the custom viewport to sit at the viewportTopOffset (with the rest of the viewport below)
   * 'bottom will cause the bottom of the custom viewport to sit at the viewportTopOffset (with the rest of the viewport above)
   * 'middle will cause the middle of the custom viewport to sit at the viewportTopOffset (with half above, half below)
   */
  viewportVerticalAlignment: PropTypes.oneOf(['top', 'middle', 'bottom']),

  /** @private automatically provided by BrowserSizeProvider */
  windowHeight: PropTypes.number.isRequired,
};

InViewport.defaultProps = {
  onEnter: null,
  onExit: null,
  viewportHeight: null,
  viewportTopOffset: 0,
  viewportVerticalAlignment: 'middle',
};

/**
 * Enhancer component that provides information about whether an element is within the viewport.
 *
 * Uses IntersectionObserver internally.
 *
 * In addition to providing a boolean `isInViewport` prop, the `onEnter` and `onExit` callback props
 * can be used for more advanced use cases.
 *
 * See PropTypes JSDoc comments for prop documentation.
 *
 * Usage example:
 * ```
<InViewport targetThreshold={0.5}>
  {({ elementToObserveRef, isInViewport }) => (
    <div ref={elementToObserveRef}>
      Am I visible? {isInViewport ? 'yes' : 'no'}
    </div>
  )}
</InViewport>
```
 *
 * To treat an element as visible when any part of it is within the center 10px of the browser's
 * viewport:
 * ```
<InViewport targetThreshold={0.5} viewportHeight={10} viewportTopOffset={0.5} viewportVerticalAlignment="middle">
  {({ elementToObserveRef, isInViewport }) => (
    <div ref={elementToObserveRef}>
      Am I visible? {isInViewport ? 'yes' : 'no'}
    </div>
  )}
</InViewport>
```
 */
const InViewportWithSize = BrowserSizeProvider(InViewport);

// HoC version of this component, designed to match the API of https://github.com/roderickhsiao/react-in-viewport
function handleViewport(WrappedComponent, options) {
  return (props) => (
    <InViewportWithSize targetThreshold={options && options.threshold ? options.threshold : 0}>
      {({ elementToObserveRef, isInViewport, scrollDirection }) => (
        <WrappedComponent
          {...props}
          inViewport={isInViewport}
          scrollDirection={scrollDirection}
          elementToObserveRef={elementToObserveRef}
        />
      )}
    </InViewportWithSize>
  );
}

export { InViewportWithSize as InViewport, handleViewport };
