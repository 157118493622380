import React from 'react';

import { ImageProp } from 'views/modules/moduleConstants';

import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';

export const ZEPLIN_ID = 'PAN01-01a';

export interface ImagePanelModuleProps {
  id: string;
  description: string;
  images: ImageProp[];
  title: string;
  name: string;
}

export const ImagePanelModule = ({
  id,
  title,
  description,
  images,
  name,
}: ImagePanelModuleProps) => {

  const nameWithoutSpaces = name.replace(/\s/g, '');

  return (
    <section id={nameWithoutSpaces} className="CaseStudy-imagePanel ModuleWrapper">
      <div className="Container">
        <ComponentInfo id={id} zeplinId={ZEPLIN_ID} />
        <div className="Grid">
          <div className="Grid-cell u-md-width10of12 u-md-before1of12 u-md-after1of12">
            <h3 className="h4 u-md-textCenter u-mB-0">{title}</h3>
            <p className="u-md-textCenter">{description}</p>
          </div>
          <div className="Grid-cell CaseStudy-imagePanel-images">
            {images.map((image) => (
              <ResponsiveCmsImageWrapper
                imageData={image}
                url={image.file.url}
                key={image.file.url}
                altText={image.description}
                useImgTag
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
