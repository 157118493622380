import React from 'react';
import classNames from 'classnames';

import { ImageProp } from 'views/modules/moduleConstants';

import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import { ImageWrapper } from 'views/components/ImageWrapper/ImageWrapper';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';

export const ZEPLIN_ID = 'LDR01-01a';

type People = {
  id: string;
  image: ImageProp;
  name: string;
  role: string;
}

export interface PeopleProps {
  id: string;
  isLoading?: boolean;
  title: string;
  decoration?: ImageProp;
  peopleList?: People[];
}

export const People = (props: PeopleProps) => {
  const zeplinId = ZEPLIN_ID;

  const {
    id,
    isLoading,
    title,
    decoration,
    peopleList = [],
  } = props;

  if ((!peopleList || peopleList.length === 0) && !isLoading) {
    return null;
  }

  const titleClass = classNames('People-title', { 'has-decoration': decoration });

  return (
    <section className="People ModuleWrapper ">
      <div className="Container">
        <ComponentInfo id={id} zeplinId={zeplinId} />
        {!isLoading && (
          <>
            <h3 className={titleClass}>
              {title}
              {decoration && (
                <span className="People-title-decoration">
                  <ImageWrapper
                    url={decoration.file.url}
                    altText="" // Empty alt-text as these images are purely decorative
                    expectedHeight={168}
                    useImgTag
                  />
                </span>
              )}
            </h3>
            <ul className="People-list Grid u-pL-0 ">
              {peopleList.map((person) => {
                const {
                  id: personID,
                  image,
                  name,
                  role,
                } = person;
                const imageFile = image.file;

                return (
                  <li key={personID} className="Grid-cell u-sm-width1of2 u-md-width1of4">
                    <div className="People-list-item-image">
                      {imageFile && (
                        <ResponsiveCmsImageWrapper
                          url={imageFile.url}
                          altText={image.description}
                        />
                      )}
                    </div>
                    <div className="u-mB-lg">
                      <p className="People-list-item-name u-mB-xs">{name}</p>
                      <p className="People-list-item-role">{role}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    </section>
  );
};
