import React from 'react';
import classnames from 'classnames';

import { ImageProp } from 'views/modules/moduleConstants';

import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';

export interface StripModuleProps {
  id: string;
  image: ImageProp;
  imageMobile: ImageProp;
  imageTablet: ImageProp;
  isLightTheme?: boolean;
  text: string;
  textAlignment?: string;
  header: string;
  name: string;
}

export const StripModule = ({
  id,
  image,
  imageMobile,
  imageTablet,
  isLightTheme,
  text,
  textAlignment = 'LEFT',
  header,
  name,
}: StripModuleProps) => {
  const isRightAlignedText = textAlignment !== 'LEFT';
  const ZEPLIN_ID = !isRightAlignedText ? 'STR01-01a' : 'STR01-01b';
  const wrapperClasses = classnames('Grid Strip-wrapper', {
    'Strip-wrapper--rightText': isRightAlignedText,
    'Strip-wrapper--isLightTheme': isLightTheme,
  });
  const tabletImage = imageTablet || image;

  const nameWithoutSpaces = name.replace(/\s/g, '');

  return (
    <section id={nameWithoutSpaces} className="Strip ModuleWrapper">
      <div className="Container">
        <ComponentInfo id={id} zeplinId={ZEPLIN_ID} />
        <div className={wrapperClasses}>
          <div className="Grid-cell u-width1of1">
            <LargieSmalls
              mobile={(
                <ResponsiveCmsImageWrapper
                  imageData={imageMobile}
                  key="mobile"
                  useImgTag
                  url={imageMobile && imageMobile.file && imageMobile.file.url}
                  altText={imageMobile.description}
                />
              )}
              tablet={(
                <ResponsiveCmsImageWrapper
                  imageData={tabletImage}
                  key="tablet"
                  useImgTag
                  url={tabletImage && tabletImage.file && tabletImage.file.url}
                  altText={tabletImage.description}
                />
              )}
              laptop={(
                <ResponsiveCmsImageWrapper
                  imageData={image}
                  key="laptop"
                  useImgTag
                  url={image && image.file && image.file.url}
                  altText={image.description}
                />
              )}
            />
          </div>
          <div className="Grid-cell Strip-text">
            <h3 className="h4 u-mT-0 u-mB-0">{header}</h3>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
