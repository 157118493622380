import React from "react";
import { useSelector } from "react-redux";
import { BLOCKS } from "@contentful/rich-text-types";

import { IPost } from "views/pages/IdeasFactoryPost/IdeasFactoryPost";
import { ResponsiveCmsImageWrapper } from "views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper";
import { ImageProp } from "views/modules/moduleConstants";
import { Button } from "views/components/Button/Button";
import { formatDate } from "tools/utilities/cms";
import { RichText } from "views/components/RichText/RichText";
import { ComponentInfo } from "views/components/ComponentInfo/ComponentInfo";
import { Tags } from "views/components/IdeasFactory/Tags";
import TrackableLink from "views/components/TrackableLink/TrackableLink";

const RICH_TEXT_OVERRIDES = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_: any, children: any) => (
      <p className="HighlightModule-description">{children}</p>
    ),
  },
};

const LINK_VARIATIONS = {
  TALK: "Talk",
  CHECK_IT_OUT: "Check it out",
};

export interface IHighlightModule {
  title: string;
  post: {
    fields: IPost;
  };
  image: {
    fields: ImageProp;
  };
  imageMobile: {
    fields: ImageProp;
  };
  summary: string;
  contentType: string;
  id: string;
  linkTo: string;
}

interface IProps {
  content: IHighlightModule;
}

export const HighlightModule = ({ content }: IProps) => {
  const isViewportMobile = useSelector((state: any) => state.browser.is.mobile);
  const shouldGoToContactPage = content.linkTo === LINK_VARIATIONS.TALK;

  return (
    <div className="HighlightModule">
      <div className="SkeletonGrid SkeletonGrid--landing">
        <div className="Container">
          <div className="Grid SkeletonGrid-row">
            <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
            <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
            <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of3 u-xl-width1of4" />
            <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-xl-inlineBlock u-xl-width1of4" />
          </div>
          <div className="Grid SkeletonGrid-row">
            <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
            <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
            <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of3 u-xl-width1of4" />
            <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-xl-inlineBlock u-xl-width1of4" />
          </div>
        </div>
      </div>
      <div className="Container">
        <ComponentInfo zeplinId="FMOD01-01a" id={content.id} isBlogContent />
        <div className="Grid u-md-flex u-md-flexAlignItemsCenter">
          <div className="Grid-cell u-md-width1of2">
            {!shouldGoToContactPage && (
              <p className="HighlightModule-date">
                {formatDate(content.post.fields.date)}
              </p>
            )}
            <h3 className="HighlightModule-heading">
              {shouldGoToContactPage ? (
                content.title
              ) : (
                <TrackableLink url={`/${content.post.fields.url}`}>
                  {content.title}
                </TrackableLink>
              )}
            </h3>
            {isViewportMobile && (
              <ResponsiveCmsImageWrapper
                imageData={content.imageMobile}
                useImgTag
                url={content.imageMobile.fields.file.url}
                altText={content.imageMobile.fields.description}
              />
            )}
            <RichText text={content.summary} overrides={RICH_TEXT_OVERRIDES} />
            {!shouldGoToContactPage && (
              <Tags
                tags={content.post.fields.tagsNew || []}
                shouldScrollToTopOnFilter
              />
            )}
            {shouldGoToContactPage ? (
              <Button
                onClick="https://alphero.com/contact"
                className="Button Button--primary"
                label="LETS_TALK"
                locationState={{ formType: "ideas" }}
                linkType="external"
                linkTarget="_blank"
              />
            ) : (
              <Button
                onClick={`/${content.post.fields.url}`}
                className="Link Link--large u-textBold"
                label="CHECK_IT_OUT"
              />
            )}
          </div>
          {!isViewportMobile && (
            <div className="Grid-cell u-width1of2">
              <ResponsiveCmsImageWrapper
                imageData={content.image}
                useImgTag
                url={content.image.fields.file.url}
                altText={content.image.fields.description}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
