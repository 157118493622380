import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Animator } from 'views/components/Animator/Animator';
import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { InViewport } from 'views/enhancers/InViewport';
import { ImagePropPT, DataPropPT } from 'views/modules/moduleConstants';
import { TEXT_ALIGNMENT } from 'redux/modules/app/constants';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { Button } from 'views/components/Button/Button';
import { RichText } from '../../../RichText/RichText';

const ANIMATION_OPTIONS = {
  autoplay: false,
};

export const SplitComponent = class SplitComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldPlay: false,
      shouldPlayExit: false,
      playedOnce: false,
    };
  }

  runEntryAnimation = () => {
    const { shouldReset } = this.props;
    this.setState({
      shouldPlay: true,
      shouldPlayExit: false,
      playedOnce: true,
    }, () => {
      if (!shouldReset) {
        this.setState({
          shouldPlay: false,
        });
      }
    });
  }

  runExitAnimation = () => {
    this.setState({
      shouldPlayExit: true,
    });
  }

  render() {
    const {
      animation,
      animationMobile,
      animationThreshold,
      hasButton,
      id,
      exitAnimation,
      image,
      imageMobile,
      shouldAnimateOnLeave,
      shouldLoop,
      shouldReset,
      textAlignment,
      text,
      header,
      name,
    } = this.props;

    const {
      shouldPlay,
      shouldPlayExit,
      playedOnce,
    } = this.state;

    let zeplinID = '';
    let alignmentClasses = '';
    let body = null;

    const shouldPlayFirstAnimation = shouldAnimateOnLeave ? shouldPlayExit : shouldPlay;

    const animationToDisplay = animationMobile || animation;
    let animationJSX = null;
    if (animation) {
      animationJSX = (
        <LargieSmalls
          mobile={
            animationMobile && (
              <Animator
                key="mobile"
                shouldPlay={shouldPlayFirstAnimation}
                shouldLoop={shouldLoop}
                shouldPlayExit={shouldPlayExit}
                shouldReset={shouldReset}
                animation={animationToDisplay}
                exitAnimation={exitAnimation}
                lottieOptions={ANIMATION_OPTIONS}
                alt={animationToDisplay?.description}
              />
            )
}
          tablet={
            animation && (
              <Animator
                key="tablet"
                shouldPlay={shouldPlayFirstAnimation}
                shouldLoop={shouldLoop}
                shouldPlayExit={shouldPlayExit}
                shouldReset={shouldReset}
                animation={animation}
                exitAnimation={exitAnimation}
                lottieOptions={ANIMATION_OPTIONS}
                alt={animation.description}
              />
            )
}
        />
      );
    }

    const imageToDisplay = imageMobile || image;

    if (text || header) { // Variations with text
      switch (textAlignment) {
        case TEXT_ALIGNMENT.RIGHT:
          zeplinID = 'SPL01-01b';
          alignmentClasses = 'u-flex u-md-flexAlignItemsCenter u-sm-flexCol';
          body = (
            <>
              <div className="Grid-cell u-md-width1of2">
                {image && !animation
                  ? (
                    <LargieSmalls
                      mobile={(<ResponsiveCmsImageWrapper key="mobile" imageData={imageToDisplay} useImgTag url={imageToDisplay && imageToDisplay.file && imageToDisplay.file.url} altText={imageToDisplay.description} />)}
                      tablet={(<ResponsiveCmsImageWrapper key="tablet" imageData={image} useImgTag url={image && image.file && image.file.url} altText={image.description} />)}
                    />
                  )
                  : animationJSX}
              </div>
              <div className="Grid-cell u-md-width5of12 u-md-before1of12 u-sm-mT-sm">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <TextContent {...this.props} />
              </div>
            </>
          );
          break;

        case TEXT_ALIGNMENT.CENTRE:
          zeplinID = image ? 'SPL01-01c' : 'SPL01-01e';
          alignmentClasses = 'u-textCenter u-flexCol';
          body = (
            <>
              <div className="Grid-cell u-md-textCenter u-width1of1 u-lg-before1of12 u-lg-after1of12 u-lg-width10of12 u-md-mB-md">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <TextContent {...this.props} />
              </div>
              {(image || animationJSX) && (
              <div className="Grid-cell u-width1of1">
                {!animation
                  ? (
                    <LargieSmalls
                      mobile={(<ResponsiveCmsImageWrapper key="mobile" imageData={imageToDisplay} useImgTag url={imageToDisplay && imageToDisplay.file && imageToDisplay.file.url} altText={imageToDisplay.description} />)}
                      tablet={(<ResponsiveCmsImageWrapper key="tablet" imageData={image} useImgTag url={image && image.file && image.file.url} altText={image.description} />)}
                    />
                  )
                  : animationJSX}
              </div>
              )}
            </>
          );
          break;

        case TEXT_ALIGNMENT.BOTTOM:
          zeplinID = 'SPL01-01d';
          alignmentClasses = 'u-textCenter u-flexCol';
          body = (
            <>
              <div className="Grid-cell u-width1of1">
                {image && !animation
                  ? (
                    <LargieSmalls
                      mobile={(<ResponsiveCmsImageWrapper key="mobile" imageData={imageToDisplay} useImgTag url={imageToDisplay && imageToDisplay.file && imageToDisplay.file.url} altText={imageToDisplay.description} />)}
                      tablet={(<ResponsiveCmsImageWrapper key="tablet" imageData={image} useImgTag url={image && image.file && image.file.url} altText={image.description} />)}
                    />
                  )
                  : animationJSX}
              </div>
              <div className="Grid-cell u-md-textCenter u-width1of1 u-lg-before1of12 u-lg-after1of12 u-lg-width10of12 u-sm-mT-sm u-md-mT-md">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <TextContent {...this.props} />
              </div>
            </>
          );
          break;

        case TEXT_ALIGNMENT.LEFT:
        default:
          zeplinID = 'SPL01-01a';
          alignmentClasses = 'u-flex u-md-flexAlignItemsCenter u-sm-flexCol u-sm-flexColReverse';
          body = (
            <>
              <div className="Grid-cell u-md-width5of12 u-sm-mT-sm">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <TextContent {...this.props} />
              </div>
              <div className="Grid-cell u-md-width1of2 u-md-before1of12">
                {image && !animation
                  ? (
                    <LargieSmalls
                      mobile={(<ResponsiveCmsImageWrapper key="mobile" imageData={imageToDisplay} useImgTag url={imageToDisplay && imageToDisplay.file && imageToDisplay.file.url} altText={imageToDisplay.description} />)}
                      tablet={(<ResponsiveCmsImageWrapper key="tablet" imageData={image} useImgTag url={image && image.file && image.file.url} altText={image.description} />)}
                    />
                  )
                  : animationJSX}
              </div>
            </>
          );
          break;
      }
    } else { // Just displays an image
      zeplinID = 'SPL01-01f';
      body = (
        <div className="Grid-cell u-width1of1">
          {image && !animation
            ? (
              <LargieSmalls
                mobile={(<ResponsiveCmsImageWrapper key="mobile" imageData={imageToDisplay} isSquare url={imageToDisplay && imageToDisplay.file && imageToDisplay.file.url} altText={imageToDisplay.description} />)}
                tablet={(<ResponsiveCmsImageWrapper key="tablet" imageData={image} isSquare url={image && image.file && image.file.url} altText={image.description} />)}
              />
            )
            : animationJSX}
        </div>
      );
    }

    const nameWithoutSpaces = name.replace(/\s/g, '');

    return (
      <InViewport
        targetThreshold={animationThreshold}
        onEnter={!playedOnce || shouldReset ? this.runEntryAnimation : null}
        onExit={this.runExitAnimation}
      >
        {({ elementToObserveRef }) => (
          <section id={nameWithoutSpaces} ref={elementToObserveRef} className="CaseStudy-split ModuleWrapper">
            <div className="Container">
              <ComponentInfo id={id} zeplinId={zeplinID} />

              <div className={`Grid ${alignmentClasses}`}>
                {body}
                {hasButton && <Button onClick="/" className="Button Button--primary" label="RETURN_TO_HOME" />}
              </div>
            </div>
          </section>
        )}
      </InViewport>
    );
  }
};

const TextContent = (props) => {
  const {
    text, header, clientName,
  } = props;

  return (
    <>
      {clientName && <p className="CaseStudy-split-clientName h5 u-mT-0 u-mB-md">{clientName}</p>}
      <h4 className=" u-mT-0 u-mB-sm">{header}</h4>
      {text && <RichText text={text} />}
    </>
  );
};

const TextLayoutProps = {
  clientName: PropTypes.string,
  header: PropTypes.string.isRequired,
  text: DataPropPT,
};
const TextLayoutDefaultProps = {
  clientName: '',
  text: null,
};

SplitComponent.propTypes = {
  animation: ImagePropPT,
  animationMobile: ImagePropPT,
  animationThreshold: PropTypes.number,
  exitAnimation: ImagePropPT,
  hasButton: PropTypes.bool,
  id: PropTypes.string.isRequired,
  image: ImagePropPT,
  imageMobile: ImagePropPT,
  name: PropTypes.string.isRequired,
  shouldAnimateOnLeave: PropTypes.bool,
  shouldLoop: PropTypes.bool,
  shouldReset: PropTypes.bool,
  textAlignment: PropTypes.string, // LEFT, RIGHT, CENTRE,
  ...TextLayoutProps,
};

SplitComponent.defaultProps = {
  animation: null,
  animationMobile: null,
  animationThreshold: 0.9,
  exitAnimation: null,
  hasButton: false,
  image: null,
  imageMobile: null,
  shouldAnimateOnLeave: false,
  shouldLoop: false,
  shouldReset: false,
  textAlignment: 'LEFT',
};

TextContent.propTypes = TextLayoutProps;
TextContent.defaultProps = TextLayoutDefaultProps;

export const SplitModule = SplitComponent;
