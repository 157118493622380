// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { MODULE_NAMES, IS_DEVELOPMENT } from 'redux/modules/app/constants';

import { ModuleNotFound } from 'views/components/ModuleNotFound/ModuleNotFound';
import { GalleryModule } from 'views/components/CaseStudyDetail/modules/GalleryModule/GalleryModule';
import { SplitModule } from 'views/components/CaseStudyDetail/modules/SplitModule/SplitModule';
import { DiagonalModule } from 'views/components/CaseStudyDetail/modules/DiagonalModule/DiagonalModule';
import { QuoteModule } from 'views/components/CaseStudyDetail/modules/QuoteModule/QuoteModule';
import { SkewModule } from 'views/connectors/SkewModule';
import { PosterModule } from 'views/connectors/PosterModule';
import { Custom } from 'views/components/CaseStudyDetail/modules/Custom/Custom';
import { GridModule } from 'views/components/CaseStudyDetail/modules/GridModule/GridModule';
import { BackgroundModule } from 'views/components/CaseStudyDetail/modules/BackgroundModule/BackgroundModule';
import { RichTextModule } from 'views/components/CaseStudyDetail/modules/RichTextModule/RichTextModule';
import { DescriptionModule } from 'views/components/CaseStudyDetail/modules/DescriptionModule/DescriptionModuleConnected';
import { HeroBannerModule } from 'views/modules/HeroBanner/HeroBannerConnected';
import { WhoWeAre } from 'views/modules/WhoWeAre/WhoWeAre';
import { TwoColumnText } from 'views/modules/TwoColumnText/TwoColumnText';
import { People } from 'views/modules/People/People';
import { ImagePanelModule } from 'views/components/CaseStudyDetail/modules/ImagePanelModule/ImagePanelModule';
import { VideoPlayer } from 'views/modules/VideoPlayer/VideoPlayer';
import { StripModule } from 'views/modules/StripModule/StripModule';
import { ErrorBoundary } from 'views/components/ErrorBoundary/ErrorBoundary';

import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css'; // For ScrollAnimation

interface Module {
  contentType?: string;
  id?: string;
}

interface IProps {
  modules: Module[];
  hasButton?: boolean;
}

export const RenderModules = ({ modules, hasButton }: IProps) => modules.map((module: any) => {
  const { id: key, contentType } = module;

  let moduleToRender = null;

  switch (contentType) {
    case MODULE_NAMES.CASE_STUDY.BACKGROUND_MODULE:
      moduleToRender = <BackgroundModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.IMAGE_ONLY:
      moduleToRender = <GalleryModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.SPLIT_MODULE:
      moduleToRender = <SplitModule {...module} key={key} hasButton={hasButton} />;
      break;

    case MODULE_NAMES.CASE_STUDY.DIAGONAL_MODULE:
      moduleToRender = <DiagonalModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.QUOTE_MODULE:
      moduleToRender = <QuoteModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.SKEW:
      moduleToRender = <SkewModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.POSTER:
      moduleToRender = <PosterModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.GRID_MODULE:
      moduleToRender = <GridModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.RICH_TEXT:
      moduleToRender = <RichTextModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.OVERVIEW_MODULE:
      moduleToRender = <DescriptionModule {...module} key={key} />;
      break;

    case MODULE_NAMES.CASE_STUDY.IMAGE_PANEL_MODULE:
      moduleToRender = <ImagePanelModule {...module} key={key} />;
      break;

    case MODULE_NAMES.STRIP_MODULE:
      moduleToRender = <StripModule {...module} key={key} />;
      break;

    case MODULE_NAMES.HERO_BANNER:
      moduleToRender = <HeroBannerModule {...module} key={key} />;
      break;

    case MODULE_NAMES.WHO_WE_ARE:
      moduleToRender = <WhoWeAre {...module} key={key} />;
      break;

    case MODULE_NAMES.TWO_COLUMN_TEXT:
      moduleToRender = <TwoColumnText {...module} key={key} />;
      break;

    case MODULE_NAMES.PEOPLE:
      moduleToRender = <People {...module} key={key} />;
      break;

    case MODULE_NAMES.VIDEO_PLAYER:
      moduleToRender = <VideoPlayer {...module} key={key} />;
      break;

    // Custom case study modules
    case MODULE_NAMES.CUSTOM:
      moduleToRender = <Custom {...module} key={key} />;
      break;

    default:
      // Only show notFoundModule if we are in development
      if (!IS_DEVELOPMENT) {
        return null;
      }

      return <ModuleNotFound name={contentType} key={key} />;
  }

  return (
    <ErrorBoundary name={contentType}>
      <ScrollAnimation
        animateIn="animate__fadeIn"
        animateOnce
        duration={0.75}
      >
        {moduleToRender}
      </ScrollAnimation>
    </ErrorBoundary>
  );
});

export const ModulePropTypes = PropTypes.shape({
  contentType: PropTypes.string,
  id: PropTypes.string,
}).isRequired;

RenderModules.propTypes = {
  modules: PropTypes.arrayOf(ModulePropTypes),
};

RenderModules.defaultProps = {
  modules: [],
};
