// Exports all actions for the module
import { actionCreator } from 'redux-action-creator';
import types from './types';

export const getCaseStudy = actionCreator(types.GET_CASE_STUDY, 'urlSlug');
export const getHomeContent = actionCreator(types.GET_HOME_CONTENT);
export const getNotFound = actionCreator(types.GET_NOT_FOUND, 'id');
export const getOpenPositions = actionCreator(types.GET_OPEN_POSITIONS);
export const getGenericJobDescription = actionCreator(types.GET_GENERIC_JOB_DESCRIPTION);
export const getOffices = actionCreator(types.GET_OFFICES);
export const getRelatedCaseStudies = actionCreator(types.GET_RELATED_CASE_STUDIES, 'id');
export const getUsDetails = actionCreator(types.GET_US_DETAILS);
export const getUsAwards = actionCreator(types.GET_US_AWARDS);
export const getMeta = actionCreator(types.GET_META, 'url');
export const getWorkPage = actionCreator(types.GET_WORK_PAGE);
export const getServices = actionCreator(types.GET_SERVICES_PAGE);
export const getContentVersion = actionCreator(types.GET_CONTENT_VERSION);
export const getIdeasFactoryContent = actionCreator(types.GET_IDEAS_FACTORY_CONTENT);
export const getPost = actionCreator(types.GET_POST, 'postUrl');
export const getIdeasFactoryRelatedPosts = actionCreator(types.GET_IDEAS_FACTORY_RELATED_POSTS, 'postUrl');

export const getTsunamiReadyPrivacyPolicy = actionCreator(types.GET_TSUNAMI_READY_PRIVACY_POLICY);
