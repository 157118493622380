import React from 'react';
import Masonry from 'react-masonry-css';

import { IPost } from 'views/pages/IdeasFactoryPost/IdeasFactoryPost';

import { RenderModuleContent } from 'views/pages/IdeasFactory/IdeasFactory';

const MASONRY_BREAKPOINT_CONFIG = {
  default: 4,
  1279: 3,
  1023: 2,
  767: 1,
};

export interface IPostCollageModule {
  posts: IPost[];
  contentType: string;
}

export interface IProps {
  content: IPostCollageModule;
}

export const PostCollageModule = ({ content: { posts } }: IProps) => (
  <div className="IdeasFactoryModule">
    <div className="IdeasFactoryModule-content Container">
      <Masonry
        breakpointCols={MASONRY_BREAKPOINT_CONFIG}
        className="MasonryGrid"
        columnClassName="MasonryGrid-column"
      >
        {posts.map((post: any) => {
          return (
            <RenderModuleContent
              content={{
                ...post,
                id: post.sys.id,
                contentType: post.sys.contentType.sys.id,
              }}
              isLandscape={false}
              key={`module-${module.id}-${post.sys.id}`}
            />
          );
        })}
      </Masonry>
    </div>
  </div>
);
