import React from 'react';
import PropTypes from 'prop-types';

// @NOTE: ModuleNotFound will only appear in DEVELOPMENT mode

export const ModuleNotFound = ({ message, name }) => (
  <section className="ModuleWrapper">
    <div className="Container">
      <div className="CaseStudy-moduleNotFound">
        <p className="">
          {message}
          <br />
        </p>
        <span>{name}</span>
      </div>
    </div>
  </section>
);

ModuleNotFound.propTypes = {
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
};

ModuleNotFound.defaultProps = {
  message: 'Module not found OR is in draft mode',
};
