import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  ENVIRONMENT,
  IS_DEVELOPMENT,
  IS_PRODUCTION,
} from 'redux/modules/app/constants';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { version } from '../../../../package.json';
import { getFeatureFlags } from '../../../redux/modules/featureFlags/selectors';
import { setFeatureFlag } from '../../../redux/modules/featureFlags/actions';

const ENV_CLASSNAME = `SiteBanner--${ENVIRONMENT}`;
const SHOW_VERSION = !IS_DEVELOPMENT && !IS_PRODUCTION;

const LS_VISIBILITY_KEY = 'settings.toolbox.isVisible';

// @NOTE: This is currently set up to only show when the site is in dev or pre-prod modes.

interface Props {
  contentVersion?: string;
  getContentVersion: () => void;
  shouldShowMeta?: boolean;
  toggleMeta?: () => void;
}

export const SiteBanner = ({
  contentVersion, getContentVersion, shouldShowMeta, toggleMeta = () => {},
}: Props) => {
  const storedShowBannerPref = window.localStorage.getItem(LS_VISIBILITY_KEY);
  const showBanner = storedShowBannerPref === 'true' || storedShowBannerPref == null;

  const [isVisible, setIsVisible] = useState(showBanner);

  const dispatch = useDispatch();
  const allFeatureFlags = useSelector(getFeatureFlags);

  useEffect(() => {
    if (IS_PRODUCTION) {
      return;
    }

    if (!contentVersion) {
      getContentVersion();
    }
  }, [contentVersion, getContentVersion]);

  // Don't show the SiteBanner in production
  if (IS_PRODUCTION) {
    return null;
  }

  return (
    <aside className={classNames('SiteBanner', ENV_CLASSNAME, { 'is-hidden': !isVisible })}>
      <button
        type="button"
        className="SiteBanner-button"
        onClick={() => {
          setIsVisible(!isVisible);
          window.localStorage.setItem(LS_VISIBILITY_KEY, `${!isVisible}`);
        }}
      >
        <span className="SiteBanner-button-text u-textBold">
          {isVisible ? 'Hide' : 'Tools'}
        </span>
      </button>
      <div className="SiteBanner-content">
        <p className="small u-textBold u-mT-0 u-mB-0">
          <FormattedMessage id={IS_DEVELOPMENT ? 'DEV_ENV' : 'PRE_PROD_ENV'} />&nbsp;<FormattedMessage id="ENVIRONMENT" />
        </p>

        {SHOW_VERSION && (
          <>
            <p className="small u-mT-0 u-mB-0">
              <FormattedMessage id="VERSIONS" />
              :&nbsp;
              <span className="u-textBold">App {version} - </span>
              {contentVersion ? (
                <span className="u-textBold">Content {contentVersion}</span>
              ) : (
                <span className="u-textBold u-textWhite">Content deployment in progress</span>
              )}
            </p>
          </>
        )}

        <>
          {IS_DEVELOPMENT && (
            <a className="u-block" href={`${window.location.origin}${window.location.pathname}?preview=true`}>View draft content</a>
          )}
          <label className="u-mR-sm">
            Size:&nbsp;
            <LargieSmalls
              mobile={
                (<strong>Small</strong>)
              }
              tablet={
                (<strong>Medium</strong>)
              }
              laptop={
                (<strong>Large</strong>)
              }
              desktop={
                (<strong>X-Large</strong>)
              }
              wideScreen={
                (<strong>XX-Large</strong>)
              }
            />
          </label>
          <label className="u-mR-sm">
            Show meta&nbsp;
            <input value={`${shouldShowMeta}`} type="checkbox" onChange={toggleMeta} />
          </label>
          <label className="u-mR-sm">
            Show grid&nbsp;
            <input type="checkbox" onChange={() => document.querySelector('body')?.classList.toggle('u-debug-grid')} />
          </label>

          <p className="small u-textBold u-mT-0 u-mB-0">Features</p>
          {/* @TODO Make this generic so we can loop through the available feature flags?s */}

          <div className="u-flex u-flexCol">
            {Object.keys(allFeatureFlags).map((featureFlagName) => (
              <label key={featureFlagName} className="u-mR-sm">
                Show {featureFlagName} feature&nbsp;
                <input value={`${allFeatureFlags[featureFlagName]}`} type="checkbox" onChange={() => dispatch(setFeatureFlag(featureFlagName, !allFeatureFlags[featureFlagName]))} />
              </label>
            ))}
          </div>
        </>
      </div>
    </aside>
  );
};

// Can be used if the site banner is needed for displaying messages.
SiteBanner.propTypes = {
  contentVersion: PropTypes.string,
  getContentVersion: PropTypes.func.isRequired,
  shouldShowMeta: PropTypes.bool,
  toggleMeta: PropTypes.func,
};

SiteBanner.defaultProps = {
  contentVersion: '',
  shouldShowMeta: false,
  toggleMeta: () => {},
};
