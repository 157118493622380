import { makeRequest } from 'tools/utilities/ajax';

const {
  REACT_APP_SEND_EMAIL_ENDPOINT,
  REACT_APP_NEWSLETTER_API_URL,
  REACT_APP_JOB_LISTINGS_ENDPOINT,
} = process.env;

const SEND_HEADERS = {
  'Content-Type': 'multipart/form-data',
};

const send = (message) => makeRequest(REACT_APP_SEND_EMAIL_ENDPOINT, 'post', SEND_HEADERS, message);
const subscribe = (email) => makeRequest(REACT_APP_NEWSLETTER_API_URL, 'post', null, { email });
const getJobListings = () => makeRequest(REACT_APP_JOB_LISTINGS_ENDPOINT);

export default {
  send,
  subscribe,
  getJobListings,
};
