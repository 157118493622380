import { FeatureNames } from './constants';

export enum TYPES {
  SET_FEATURE_FLAG = '[FEATURE_FLAG]-SET',
}

export type FeatureFlagActions = ISetFeatureFlag;

interface ISetFeatureFlag {
  type: typeof TYPES.SET_FEATURE_FLAG;
  payload: {
    featureName: FeatureNames;
    flag: boolean;
  };
}

export function setFeatureFlag(featureName: FeatureNames, flag: boolean): ISetFeatureFlag {
  return {
    type: TYPES.SET_FEATURE_FLAG,
    payload: {
      featureName,
      flag,
    },
  };
}
