import GA4React from "ga-4-react";
import 'react-app-polyfill/ie11';
import 'core-js/es6/weak-set';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/ends-with';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/string/repeat';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/number';
import 'focus-visible';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en'; // locale-data for en

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de'; // Add locale data for de

import 'babel-polyfill';

import '@fontsource/lexend/700.css';
import '@fontsource/lexend/300.css';

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './styles/app.scss';
import TagManager from 'react-gtm-module';
import defaultIntl from './tools/i18n/en-nz.json';
import { unregister } from './registerServiceWorker';
import store, { history } from './redux';
import { ReactComponent as SVGs } from './all.svg';

import { IdeasFactory } from './views/pages/IdeasFactory/IdeasFactory';
import { IdeasFactoryPost } from './views/pages/IdeasFactoryPost/IdeasFactoryPost';

import NotFound from './views/connectors/NotFound';
import HasOverlay from './views/enhancers/HasOverlay';
import SiteBanner from './views/connectors/SiteBanner';
import Header from './views/connectors/Header';
import { initialiseIntersectionPolyfills } from './views/enhancers/InViewport';

// GA4 Analytics
const ga4react = new GA4React(
  process.env.REACT_APP_GA4_ID,
  );
  
  ga4react.initialize().then((ga4) => {
    ga4.pageview('path')
    ga4.gtag('event','pageview','path') // or your custom gtag event
  },(err) => {
    console.error(err)
  });


// initialise analytics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
  debug: false,
  titleCase: false,
});

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
});

// This should match the CSS prop $timing--routes
const ROUTE_ANIMATION_TIME = 600;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.unListen = history.listen(() => {
      this.scrollToTop();
    });
  }

  componentWillUnmount() {
    this.unListen();
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <SVGs />
        <Provider store={store}>
          <IntlProvider locale="en-NZ" messages={defaultIntl}>
            <>
              <HasOverlay
                render={(isMenuOpen) => (
                  <ConnectedRouter history={history} primary={false}>
                    <Route
                      render={({ location }) => (
                        <>
                          <Header />
                          <TransitionGroup className="Router">
                            <CSSTransition
                              key={location.pathname}
                              classNames={classNames({ 'Router-fade' : !location.pathname.includes('/services/') })}
                              timeout={ROUTE_ANIMATION_TIME}
                            >
                              <div
                                className={classNames('InterstitialWrapper', {
                                  'has-overlay': isMenuOpen,
                                })}
                                key={location.pathname}
                                aria-hidden={isMenuOpen}
                              >
                                <Switch location={location}>
                                  <Route exact path="/" component={IdeasFactory} />
                                  <Route exact path="/:postUrl" component={IdeasFactoryPost} />
                                  <Route component={NotFound} />
                                </Switch>
                              </div>
                            </CSSTransition>
                          </TransitionGroup>
                        </>
                      )}
                    />
                  </ConnectedRouter>
                )}
              />

              <SiteBanner />
            </>
          </IntlProvider>
        </Provider>
      </>
    );
  }
}

initialiseIntersectionPolyfills().then(() => {
  ReactDOM.render(
    <App />,
    // eslint-disable-next-line no-undef
    document.getElementById('root'),
  );
});

unregister();
