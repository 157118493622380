import React from 'react';

import { VideoPropType, ImageProp } from 'views/modules/moduleConstants';
import { VideoPlayer as VideoPlayerComponent } from 'views/connectors/VideoPlayer';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { YouTubeVideo } from 'views/components/YouTubeVideo/YouTubeVideo';

export const ZEPLIN_ID = 'VID01-01a';

export interface Props {
  id: string;
  video?: VideoPropType;
  videoCaptions?: VideoPropType;
  videoPosterImage?: ImageProp;
  youTubeVideoUrl?: string;
  name: string;
}

export const VideoPlayer = ({
  id, video, videoPosterImage, videoCaptions, youTubeVideoUrl, name,
}: Props) => {
  if (youTubeVideoUrl) {
    return (
      <section className="ModuleWrapper">
        <div className="Container">
          <ComponentInfo id={id} zeplinId={ZEPLIN_ID} />
          <div className="Grid">
            <div className="Grid-cell u-md-width10of12 u-md-before1of12 u-md-after1of12">
              <YouTubeVideo name={name} youTubeVideoUrl={youTubeVideoUrl} />
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (!video || !video.file) {
    return null;
  }

  const nameWithoutSpaces = name?.replace(/\s/g, '');

  return (
    <section id={nameWithoutSpaces} className="ModuleWrapper">
      <div className="Container">
        <ComponentInfo id={id} zeplinId={ZEPLIN_ID} />
        <div className="Grid">
          <div className="Grid-cell u-md-width10of12 u-md-before1of12 u-md-after1of12">
            <VideoPlayerComponent
              video={video}
              videoPosterImage={videoPosterImage}
              videoCaptions={videoCaptions}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
