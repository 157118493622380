// Exports all actions for the module
import { actionCreator } from 'redux-action-creator';
import types from './types';

export const toggleMenu = actionCreator(types.TOGGLE_MENU, 'label');
export const menuStateChange = actionCreator(
  types.MENU_STATE_CHANGE,
  'menuState',
);
export const locationChange = actionCreator(types.LOCATION_CHANGE, 'location');
export const send = actionCreator(types.SEND, 'message');
export const resetSentState = actionCreator(types.RESET_SENT_STATE);
export const toggleMeta = actionCreator(types.TOGGLE_META);
export const closeEmergencyBanner = actionCreator(types.CLOSE_EMERGENCY_BANNER);
export const subscribeToNewsLetter = actionCreator(
  types.SUBSCRIBE_TO_NEWSLETTER,
);
export const resetSubscribeState = actionCreator(
  types.RESET_NEWSLETTER_SUBSCRIPTION_STATE,
);
export const getJobListings = actionCreator(types.GET_JOB_LISTINGS);
