import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Animator, LOTTIE_CONTENT_TYPE } from 'views/components/Animator/Animator';

const OPTIONS = {
  autoplay: true,
  loop: true,
};

export const Loader = (props) => {
  const { shouldRender } = props;

  if (!shouldRender) {
    return null;
  }

  const { className, animationPath } = props;

  const loaderClasses = classnames('Loader', className);

  const animation = {
    file: {
      url: animationPath,
      contentType: LOTTIE_CONTENT_TYPE,
    },
  };

  return (
    <div className={loaderClasses}>
      <Animator animation={animation} lottieOptions={OPTIONS} shouldPlay alt="Loading…" />
    </div>
  );
};

Loader.propTypes = {
  animationPath: PropTypes.string,
  className: PropTypes.string,
  shouldRender: PropTypes.bool,
};

Loader.defaultProps = {
  animationPath: '/animations/btn-loader.json',
  className: '',
  shouldRender: false,
};
