// @ts-nocheck
import React from 'react';
import classnames from 'classnames';

import { formatDate } from 'tools/utilities/cms';
import { useHasPostBeenRead } from 'tools/hooks/useHasPostBeenRead';

import { Tags } from 'views/components/IdeasFactory/Tags';
import { IPost } from 'views/pages/IdeasFactoryPost/IdeasFactoryPost';
import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import TrackableLink from 'views/connectors/TrackableLink';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';

interface IProps {
  content: IPost;
  isLandscape: boolean;
  shouldScrollToTopOnFilter: boolean;
}

export const SnapshotPost = ({
  content,
  isLandscape,
  shouldScrollToTopOnFilter,
}: IProps) => {
  const hasBeenRead = useHasPostBeenRead(content.id);

  const classes = classnames('IdeasFactoryTile has-hover', {
    'is-read': hasBeenRead,
    'IdeasFactoryTile--landscape': isLandscape,
  });

  return (
    <div className={classes}>
      <ComponentInfo zeplinId="IDEAS01-01a - Opinion - Snapshot" id={content.id} isBlogContent />
      <div className="IdeasFactoryTile-content">
        <TrackableLink url={`/${content.url}`}>
          {content.coverImage?.fields?.file && (
          <ResponsiveCmsImageWrapper
            useImgTag
            url={content.coverImage.fields.file.url}
            altText={content.coverImage.fields.file.description}
          />
            )}
        </TrackableLink>
        <TrackableLink url={`/${content.url}`}>
          <div className="u-flex u-width1of1 u-flexJustifyBetween u-flexAlignItemsCenter IdeasFactoryTile-content-date-container">
            {content.date && <p className="IdeasFactoryTile-content-date small">{formatDate(content.date)}</p>}
            {hasBeenRead && <span aria-hidden className="IdeasFactoryTile-content-readIcon" />}
          </div>
          <p className="IdeasFactoryTile-content-title u-textPrimary">{content.title}</p>
        </TrackableLink>
      </div>
      <div className="IdeasFactoryTile-content-tags-container">
        {content.tagsNew && <Tags tags={content.tagsNew} shouldScrollToTopOnFilter={shouldScrollToTopOnFilter} isInArticle />}
      </div>
    </div>
  );
};
