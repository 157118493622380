import IcoMoonIcons from 'tools/utilities/icomoon-content';
import SVGs from 'tools/utilities/svg-content';

// Environments
const ENVIRONMENTS = {
  DEVELOPMENT: 'dev',
  PRE_PROD: 'pre-prod',
  PRODUCTION: 'prod',
};

export const {
  NODE_ENV,
  REACT_APP_ENVIRONMENT,
  // REACT_APP_FEATURE_BRANCH,
  // REACT_APP_COMMIT_HASH,
} = process.env;

export const REACT_APP_SHOW_EMERGENCY_BANNER = process.env.REACT_APP_SHOW_EMERGENCY_BANNER === 'true';
export const ENVIRONMENT = REACT_APP_ENVIRONMENT || NODE_ENV;

export const IS_DEVELOPMENT = ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT;
export const IS_PRE_PROD = ENVIRONMENT === ENVIRONMENTS.PRE_PROD;
export const IS_PRODUCTION = ENVIRONMENT === ENVIRONMENTS.PRODUCTION;

// export const FEATURE_BRANCH = REACT_APP_FEATURE_BRANCH;
// export const COMMIT_HASH = REACT_APP_COMMIT_HASH;

export const LOCATION_CHANGE_ACTION_TYPE = '@@router/LOCATION_CHANGE';

export const MAX_FILE_SIZE = 1e7;
export const MAX_FILES = 5;
export const ALLOWED_FILE_EXTENSIONS = ['pdf', 'doc', 'docx', 'zip', 'xlsx'];
export const MAX_TEXT_AREA_LENGTH = 500;

// Misc
export const ICONS = {
  IcoMoonIcons,
  SVGs,
};

export const MODULE_NAMES = {
  CASE_STUDY: {
    BACKGROUND_MODULE: 'moduleCaseStudyBackground', // BKG01-01a
    SPLIT_MODULE: 'moduleCaseStudySplit', // SPL01-01a SPL01-01b
    POSTER: 'moduleCaseStudyPoster', // POS01-01a POS01-01b
    QUOTE_MODULE: 'moduleCaseStudyQuote', // QTE01-01a QTE01-01b
    SKEW: 'moduleCaseStudySkew', // SKW01-01a SKW01-01b
    DIAGONAL_MODULE: 'moduleCaseStudyDiagonal', // DIA01-01a DIA01-01b
    GRID_MODULE: 'moduleCaseStudyGrid', // GRD01-01a
    OVERVIEW_MODULE: 'moduleCaseStudyOverview', // DES02-01a
    IMAGE_PANEL_MODULE: 'moduleCaseStudyImagePanel', // PAN01-01a

    // Might not be a module
    IMAGE_ONLY: 'moduleCaseStudyImage', // GAL01-01a
    RICH_TEXT: 'moduleCaseStudyRichText', // No template for this one. It comes from Energy Sector ("what next?" piece)
  },
  US: {
    SKILL: 'usModule',
  },
  HERO_BANNER: 'moduleHeroBanner',
  VIDEO_PLAYER: 'moduleVideoPlayer',
  STRIP_MODULE: 'moduleStrip', // STR01-01a

  WHO_WE_ARE: 'whoWeAre',
  TWO_COLUMN_TEXT: 'twoColumnText',
  PEOPLE: 'people',

  CUSTOM: 'moduleCaseStudyCustom',
};

export const GRADIENT_DIRECTION = {
  'vertical': '180deg',
  'horizontal': '90deg',
  'diagonal-left': '145deg',
  'diagonal-right': '245deg',
  'radial': 'circle',
  'none': '',
};

export const TILT_OPTIONS = {
  reverse: false, // reverse the tilt direction
  max: 10, // max tilt rotation (degrees)
  perspective: 1500, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.05, // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: 'cubic-bezier(.165,.840,.440,1)', // Easing on enter/exit.
};

export const ANIMATION_DEFAULTS = {
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: '/animations/home-page-v2.json',
  rendererSettings: {
    hideOnTransparent: true,
  },
};

export const EXIT_ANIMATION_DEFAULTS = {
  loop: false,
  autoplay: false,
};

// Duration of the transition used when fading in components on the page
export const LOAD_TRANSITION_DURATION = 800;
export const LOAD_TRANSITION_DURATION_SECONDS = LOAD_TRANSITION_DURATION / 1000;

export const WHAT_WE_DO_MENU_LINKS = [
  {
    to: '/services/digital-products',
    labelId: 'DIGITAL_PRODUCTS',
  },
  {
    to: '/services/strategy',
    labelId: 'STRATEGY',
  },
  {
    to: '/services/innovation',
    labelId: 'INNOVATION',
  },
  {
    to: '/ideas-factory',
    labelId: 'IDEAS_FACTORY',
  },
];

export const ABOUT_US_MENU_LINKS = [
  {
    to: '/work',
    labelId: 'THE_WORK',
  },
  {
    to: '/us',
    labelId: 'US',
  },
  // This 'Join Alphero' page is temporary until we have an in-built Alphero
  // site solution. I would normally refactor this to make it an <a> tag, but
  // because this will be removed anyway, this is the simplest solution using
  // the current architecture.
  //
  // When you remove this, please remove the `target` parameter as well.
  {
    to: { pathname: 'https://apply.workable.com/alphero-limited/' },
    labelId: 'Join Alphero',
    target: '_blank',
  },
  {
    to: '/talk',
    labelId: 'Contact',
  },
];

export const MENU_LINKS = [
  {
    to: '/work',
    labelId: 'WORK',
  },
  {
    to: '/ideas-factory',
    labelId: 'IDEAS_FACTORY',
  },
  {
    to: '/us',
    labelId: 'US',
  },
  {
    to: '/talk',
    labelId: 'TALK',
  },

  // Debug routes
  // {
  //   to: '/preview',
  //   labelId: 'PREVIEW',
  // },
  // {
  //   to: '/preview/modules',
  //   labelId: 'PREVIEW_MODULES',
  // },

  // Future routes
  // {
  //   to: '/imagine',
  //   labelId: 'IMAGINE',
  // },
];

export const SOCIAL_LINKS = [
  {
    iconName: 'linkedin',
    labelId: 'LINKEDIN',
    url: 'https://www.linkedin.com/company/alphero/',
  },
  {
    iconName: 'instagram',
    labelId: 'INSTAGRAM',
    url: 'https://www.instagram.com/alphero.team/',
  },
];

export const CYCLE_TEXT_INTERVAL_VALUE = 3000;
export const HERO_TITLE_LIST = ['INNOVATIVE', 'CREATIVE', 'TRANSFORMATIVE'];

export const OFFICES = [
  {
    name: 'Wellington',
    addressLineOne: 'Level 3, 7 Dixon Street,',
    addressLineTwo: ' Te Aro, Wellington 6011,',
    addressLineThree: 'New Zealand',
    mainContactName: 'Caroline Dewe',
    phone: '(+64) 021 682 609',
  },
  {
    name: 'Auckland',
    addressLineOne: 'Office G.3, 317 New North Road,',
    addressLineTwo: 'Kingsland, Auckland 1021,',
    addressLineThree: 'New Zealand',
    mainContactName: 'Tom Cotter',
    phone: '(+64) 027 258 2069',
  },
];

export const CONTACT_FORM_OPTIONS = [
  {
    title: "Let's talk about new work",
    showUploadInput: false,
    id: 'business',
  },
  {
    title: "Let's talk about the Ideas Factory",
    showUploadInput: false,
    id: 'ideas',
  },
  {
    title: 'I want a job',
    showUploadInput: true,
    id: 'job',
  }, {
    title: 'Something else...',
    showUploadInput: false,
    id: 'other',
  }];

export const TEXT_ALIGNMENT = {
  CENTRE: 'CENTRE',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  BOTTOM: 'BOTTOM',
};

export const MENU_STATE = {
  CLOSED: 'closed',
  OPENING: 'opening',
  OPEN: 'open',
  CLOSING: 'closing',
};
