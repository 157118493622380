import React from 'react';
import classNames from 'classnames';

import { ImageProp } from 'views/modules/moduleConstants';
import { Button } from 'views/components/Button/Button';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';

export const ZEPLIN_ID = 'BKG01-01a';

export enum SiteLinkType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum HeadingColor {
  FLAMINGO = 'Flamingo',
  WHITE = 'White',
}

export enum TextColor {
  MIDNIGHT = 'Midnight',
  WHITE = 'White',
}

export enum BackgroundColor {
  MIDNIGHT = 'Midnight',
  FLAMINGO = 'Flamingo',
  SKY_GRADIENT = 'Sky gradient',
  SUNSET_GRADIENT = 'Sunset gradient',
}

export enum OverlayPosition {
  TOP = 'TOP',
  CENTRE = 'CENTRE',
}

export interface BackgroundModuleProps {
  headingColor: HeadingColor;
  backgroundColor: BackgroundColor;
  heading?: string;
  id: string;
  mobileOverlay?: ImageProp;
  overlay?: ImageProp;
  overlayPosition?: OverlayPosition;
  siteLink?: string;
  siteLinkType: SiteLinkType;
  siteTitle?: string;
  text?: string;
  textColor: TextColor;
  title: string;
  name: string;
}

export const BackgroundModule = ({
  heading,
  id,
  title,
  text,
  headingColor,
  siteLink,
  backgroundColor,
  textColor = TextColor.MIDNIGHT,
  overlay,
  mobileOverlay,
  overlayPosition,
  siteTitle,
  siteLinkType = SiteLinkType.EXTERNAL,
  name,
}: BackgroundModuleProps) => {
  const whiteHeading = headingColor !== HeadingColor.FLAMINGO;
  const whiteText = textColor !== TextColor.MIDNIGHT;
  const flamingoBackground = backgroundColor === BackgroundColor.FLAMINGO;
  let backgroundColorClass = '';

  switch (backgroundColor) {
    case BackgroundColor.FLAMINGO:
      backgroundColorClass = 'CaseStudy-background-graphic--flamingo';
      break;
    case BackgroundColor.SKY_GRADIENT:
      backgroundColorClass = 'CaseStudy-background-graphic--sky';
      break;
    case BackgroundColor.SUNSET_GRADIENT:
      backgroundColorClass = 'CaseStudy-background-graphic--sunset';
      break;
    case BackgroundColor.MIDNIGHT:
    default:
      backgroundColorClass = 'CaseStudy-background-graphic--midnight';
      break;
  }

  let target;

  if (siteLinkType === SiteLinkType.INTERNAL) {
    target = '';
  } else {
    target = '_blank';
  }

  const overlayClasses = classNames('CaseStudy-background-overlay Grid-cell u-sm-width-1of1 u-md-before1of2 u-md-width5of12 u-lg-before1of2', {
    'CaseStudy-background-overlay--topAlignment': overlayPosition === 'TOP',
  });

  const nameWithoutSpaces = name.replace(/\s/g, '');

  return (
    <section id={nameWithoutSpaces} className="ModuleWrapper u-flex">
      <div className="Container">
        <ComponentInfo id={id} zeplinId={ZEPLIN_ID} />
        <div className="CaseStudy-background Grid">
          <div className={classNames('CaseStudy-background-shape Grid-cell u-width1of1', backgroundColorClass)}>
            <div className="CaseStudy-background-content u-md-width1of2">
              {heading && (
                <p className={classNames('h5 CaseStudy-background-heading u-mB-xs u-mT-xs', {
                  'CaseStudy-background-heading--white': whiteHeading,
                })}
                >{heading}
                </p>
              )}
              <p className={classNames('h4 CaseStudy-background-title u-mT-sm', {
                'CaseStudy-background-text--white': whiteText,
              })}
              > {title}
              </p>
              {text && (
                <p className={classNames('CaseStudy-background-text', {
                  'CaseStudy-background-text--white': whiteText,
                })}
                >
                  {text}
                </p>
              )}
              {siteLink && (
                <Button
                  linkType={siteLinkType}
                  className={classNames('Link CaseStudy-background-link', {
                    'CaseStudy-background-link--white': whiteText,
                    'CaseStudy-background-link--midnight': flamingoBackground,
                  })}
                  label={siteTitle}
                  onClick={siteLink}
                  linkTarget={target}
                />
              )}
            </div>
          </div>
          {(!!overlay || !!mobileOverlay) && (
            <div className={overlayClasses}>
              <LargieSmalls
                mobile={<ResponsiveCmsImageWrapper imageData={mobileOverlay} url={mobileOverlay?.file.url} useImgTag />}
                tablet={<ResponsiveCmsImageWrapper imageData={overlay} url={overlay?.file.url} isContain useImgTag />}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
