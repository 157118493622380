import React, { Component } from 'react';
import classNames from 'classnames';

import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';
import { ImageProp, AwardProp } from 'views/modules/moduleConstants';
import { Award } from 'views/components/CaseStudyDetail/common/Award/Award';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { SkewCaption, CAPTION_VERTICAL_POSITIONS } from 'views/components/SkewCaption/SkewCaption';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { Animator } from 'views/components/Animator/Animator';
import { handleViewport } from 'views/enhancers/InViewport';

/**
 * Position variations of the poster module.
 * These values relate directly to values sent from the CMS
 */
export enum PosterCaptionPosition {
  RIGHT = 'Right Caption',
  LEFT = 'Left Caption',
  NONE = 'No Caption',
}

const ANIMATION_OPTIONS = {
  loop: false,
  autoplay: false,
};

const IN_VIEWPORT_OPTIONS = {
  threshold: 0.8,
};

export interface PosterComponentProps {
  animation?: ImageProp;
  animationMobile?: ImageProp;
  awards?: AwardProp[];
  hasIcon?: boolean;
  id: string;
  image?: ImageProp;
  imageMobile?: ImageProp;
  inViewport: boolean;
  isViewportMobile: boolean;
  shouldAnimateOnLeave?: boolean;
  shouldLoop?: boolean;
  subtitle?: string;
  heading?: string;
  variation?: PosterCaptionPosition;
  name: string;
}

interface PosterComponentState {
  wasInViewport: boolean;
  offsetTop: number;
}

export const PosterComponent = class PosterComponent extends Component<PosterComponentProps, PosterComponentState> {
  constructor(props: PosterComponentProps) {
    super(props);

    this.state = {
      wasInViewport: false,
      offsetTop: 0,
    };
  }

  componentWillReceiveProps(nextProps: PosterComponentProps) {
    const { shouldAnimateOnLeave, inViewport } = nextProps;

    if (shouldAnimateOnLeave && inViewport) {
      this.setState({
        wasInViewport: true,
      });
    }
  }

  onHeightChange = (height: number) => {
    this.setState({
      offsetTop: height / 2,
    });
  }

  render() {
    const {
      awards = [],
      id,
      image,
      heading,
      subtitle,
      imageMobile,
      inViewport,
      hasIcon,
      animation,
      shouldAnimateOnLeave,
      variation,
      shouldLoop,
      animationMobile,
      isViewportMobile,
      name,
    } = this.props;

    const {
      wasInViewport,
      offsetTop,
    } = this.state;

    const hasImage = !!(image && image.file && image.file.url) && !!(imageMobile && imageMobile.file && imageMobile.file.url);
    const hasText = heading || subtitle;
    const hasAwards = awards.length > 0;
    const isLeft = variation === PosterCaptionPosition.LEFT;
    const isRight = variation === PosterCaptionPosition.RIGHT;
    let zeplinID = '';
    let variationClass = '';
    let captionVerticalPosition = CAPTION_VERTICAL_POSITIONS.MIDDLE;
    let hasCaption = hasText && !PosterCaptionPosition.NONE;

    const shouldPlay = (inViewport && !shouldAnimateOnLeave)
      || (!inViewport && wasInViewport && shouldAnimateOnLeave);

    switch (variation) {
      case PosterCaptionPosition.LEFT:
        zeplinID = 'POS01-01c';
        variationClass = 'CaseStudy-poster--leftCaption';
        captionVerticalPosition = CAPTION_VERTICAL_POSITIONS.MIDDLE;
        hasCaption = true;
        break;
      case PosterCaptionPosition.NONE:
        zeplinID = 'POS01-01b';
        hasCaption = false;
        break;
      default:
        zeplinID = 'POS01-01a';
        captionVerticalPosition = CAPTION_VERTICAL_POSITIONS.BOTTOM;
        hasCaption = true;
        break;
    }

    const componentClasses = classNames(
      'ModuleWrapper CaseStudy-poster', variationClass,
      {
        'CaseStudy-poster--hasText': hasText,
        'CaseStudy-poster--hasAwards': hasAwards,
      },
    );

    const posterClasses = classNames(
      'CaseStudy-poster-overlayContainer',
      'Grid-cell',
      'u-xl-width10of12',
      {
        'u-width11of12': isRight,
        'u-md-width10of12': isLeft,
        'u-md-before2of12': isLeft,
        'u-md-before1of12': !hasCaption,
      },
    );

    const captionClasses = classNames(
      'CaseStudy-poster-captionContainer',
      'Grid-cell',
      'u-sm-width11of12',
      'u-md-width5of12',
      { 'u-sm-before1of12 u-md-before7of12': isRight },
    );

    const animationToDisplay = animationMobile || animation;

    const nameWithoutSpaces = name.replace(/\s/g, '');

    return (
      <section
        id={nameWithoutSpaces}
        className={componentClasses}
        style={isLeft && isViewportMobile ? { paddingTop: offsetTop } : {}}
      >
        <div className="Container">
          <ComponentInfo id={id} zeplinId={zeplinID} />
          <div className="Grid u-posRelative">
            <div className={posterClasses}>
              {!animation && hasImage && (
                <div className="CaseStudy-poster-image">
                  <LargieSmalls
                    mobile={(
                      <ResponsiveCmsImageWrapper
                        imageData={imageMobile}
                        url={imageMobile?.file.url}
                        key={imageMobile?.file.url}
                        altText={imageMobile?.description}
                        useImgTag
                      />
)}
                    tablet={(
                      <ResponsiveCmsImageWrapper
                        imageData={image}
                        url={image?.file.url}
                        key={image?.file.url}
                        altText={image?.description}
                        useImgTag
                      />
)}
                  />
                </div>
              )}

              {animation && (
                <LargieSmalls
                  mobile={(
                    <Animator
                      key="mobile"
                      shouldPlay={shouldPlay}
                      shouldLoop={shouldLoop}
                      animation={animationToDisplay}
                      lottieOptions={ANIMATION_OPTIONS}
                      alt={animationToDisplay?.description}
                    />
                  )}
                  tablet={(
                    <Animator
                      key="tablet"
                      shouldPlay={shouldPlay}
                      shouldLoop={shouldLoop}
                      animation={animation}
                      lottieOptions={ANIMATION_OPTIONS}
                      alt={animation.description}
                    />
                  )}
                />
              )}
            </div>
            {hasCaption && (
              <div className={captionClasses}>
                <SkewCaption
                  className="CaseStudy-poster-caption"
                  title={heading}
                  text={subtitle}
                  position={captionVerticalPosition}
                  shouldFlipX={isRight}
                  icon={hasIcon ? 'quote' : null}
                  // isLeft={isLeft}
                  onHeightChange={this.onHeightChange}
                />
              </div>
            )}
          </div>
          {hasAwards && (
            <div className="Grid">
              <LargieSmalls
                mobile={(
                  <ul
                    className={classNames('Grid-cell CaseStudy-poster-awards u-xl-before1of12 u-pT-md', {
                      'u-md-width1of2 u-xl-width6of12': hasCaption,
                      'u-md-width3of4 u-xl-width5of6': !hasCaption || isLeft,
                      'u-md-before1of12': isLeft,
                    })}
                  >
                    {awards.map((award) => (
                      <li key={award.id} className="CaseStudy-poster-awards-item">
                        <Award award={award} />
                      </li>
                    ))}
                  </ul>
                )}
                tablet={(
                  <ul
                    className={classNames('Grid-cell CaseStudy-poster-awards u-pT-md', {
                      'u-md-width1of2 u-lg-width7of12 u-xl-width6of12': hasCaption,
                      'u-md-before1of12': !hasCaption,
                      'u-md-width3of4 u-xl-width5of6': !hasCaption || isLeft,
                      'u-md-before2of12': isLeft,
                    })}
                    style={isRight ? { marginTop: -offsetTop } : {}}
                  >
                    {awards.map((award) => (
                      <li key={award.id} className="CaseStudy-poster-awards-item">
                        <Award award={award} />
                      </li>
                    ))}
                  </ul>
                )}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
};

export const PosterModule = handleViewport(PosterComponent, IN_VIEWPORT_OPTIONS);
