import { createSelector } from 'reselect';

export const getComponentProps = (state, props) => props;
export const getIdFromPath = createSelector(
  [getComponentProps],
  (props) => {
    if (!props || !props.match) {
      return null;
    }

    return props.match.params.id;
  },
);

export const getCurrentLocation = (state) => state.app.currentLocation;
export const isLoading = (state) => state.app.isLoading;
export const isMenuOpen = (state) => state.app.isMenuOpen;
export const shouldShowMeta = (state) => state.app.shouldShowMeta;
export const getMenuState = (state) => state.app.menuState;

export const isSending = (state) => state.app.contact.isSending;
export const hasSent = (state) => {
  if (!state.app || !state.app.contact) {
    return false;
  }

  return state.app.contact.hasSent || false;
};

export const hasSentError = (state) => {
  if (!state.app || !state.app.contact) {
    return false;
  }

  return state.app.contact.error || false;
};

export const isViewportMobile = (state) => state.browser.is.mobile;
export const isViewportTablet = (state) => state.browser.is.tablet;

export const isViewportAboveMobile = (state) => state.browser.greaterThan.mobile;
export const isViewportAboveTablet = (state) => state.browser.greaterThan.tablet;

export const isEmergencyBannerOpen = (state) => state.app.isEmergencyBannerOpen;
export const hasSubscribedToNewsletter = (state) => state.app.hasSubscribedToNewsletter;
export const hasSubscriptionErrored = (state) => state.app.hasSubscriptionErrored;

export const getJobListings = (state) => state.app.jobListings;
