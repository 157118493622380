import React, { Component } from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS, BLOCKS, Document } from '@contentful/rich-text-types';
import { DataProp } from 'views/modules/moduleConstants';

interface UnderlineProps {
  text: string | Document;
}

/**
 * Contentful BLOCKS as they come are missing `hyperlinks` as a type.
 * This just adds it as one more. If more become necessary we'll just update this constant.
 */
export const FINAL_BLOCKS = {
  ...BLOCKS,
  HYPERLINK: 'hyperlink',
};

const Underline = ({ text }: UnderlineProps) => <span className="RichText-underline">{text}</span>;

const H3 = ({ children }: any) => (<h3 className="u-mB-0 u-mT-0">{children}</h3>);

const DEFAULT_OPTIONS = {
  renderMark: {
    [MARKS.UNDERLINE]: (text: string) => <Underline text={text} />,
  },
  renderNode: {
    [FINAL_BLOCKS.HEADING_3]: (_: any, children: any) => (
      <H3>{children}</H3>
    ),
  },
};

interface Overrides {
  renderMark?: any;
  renderNode?: any;
  renderText?: (text: string) => string[];
}

interface IProps {
  overrides?: Overrides;
  text?: string | DataProp;
}

interface IState {
  options: Overrides;
}

export class RichText extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { overrides } = props;

    // Override how elements are rendered on the page
    // useful if the defaults don't meet what's needed
    this.state = {
      options: {
        renderMark: {
          ...DEFAULT_OPTIONS.renderMark,
          ...(overrides || {}).renderMark,
        },
        renderNode: {
          ...DEFAULT_OPTIONS.renderNode,
          ...(overrides || {}).renderNode,
        },

        // @ts-ignore
        renderText: overrides?.renderText
          ? overrides.renderText
          : (text: any) => text.split('\n')
            .reduce(
            // @ts-ignore
              (children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [],
            ),
      },
    };
  }

  render() {
    const { text } = this.props;
    const { options } = this.state;

    // @ts-ignore
    const components = documentToReactComponents(text, options);

    return (
      <>
        {components}
      </>
    );
  }
}
