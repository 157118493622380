import React from 'react';

import { ImageProp } from 'views/modules/moduleConstants';

import { ResponsiveCmsImageWrapper } from 'views/components/ResponsiveCmsImageWrapper/ResponsiveCmsImageWrapper';

export interface IAuthor {
  image: {
    fields: ImageProp;
  };
  name: string;
  role: string
}

export interface IAuthorFormatted {
  image: ImageProp;
  name: string;
  role: string
}

interface IProps {
  author?: IAuthor;
}

interface IPropsFormatted {
  author?: IAuthorFormatted;
}

export const Author = ({ author }: IProps) => (
  <div className="Author">
    <div className="Author-portrait">
      {author?.image?.fields.file && (
        <ResponsiveCmsImageWrapper
          isRound
          url={author.image.fields.file.url}
          altText={author.image.fields.description}
        />
      )}
    </div>
    <address>
      <p className="Author-name u-textBold">{author?.name}</p>
      <p className="Author-role">{author?.role}</p>
    </address>
  </div>
);

export const AuthorFormattedContent = ({ author }: IPropsFormatted) => (
  <div className="Author">
    <div className="Author-portrait">
      {author?.image?.file && (
        <ResponsiveCmsImageWrapper
          isRound
          url={author.image.file.url}
          altText={author.image.description}
        />
      )}
    </div>
    <address>
      <p className="Author-name u-textBold">{author?.name}</p>
      <p className="Author-role">{author?.role}</p>
    </address>
  </div>
);
