import { connect } from 'react-redux';

import * as actionsApp from 'redux/modules/app/actions';
import * as actionsCMS from 'redux/modules/cms/actions';
import { shouldShowMeta } from 'redux/modules/app/selectors';
import * as selectorsCMS from 'redux/modules/cms/selectors';

import { SiteBanner } from 'views/components/SiteBanner/SiteBanner';

const mapStateToProps = (state) => ({
  contentVersion: selectorsCMS.getContentVersion(state),
  shouldShowMeta: shouldShowMeta(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContentVersion() {
    return dispatch(actionsCMS.getContentVersion());
  },
  toggleMeta() {
    return dispatch(actionsApp.toggleMeta());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteBanner);
