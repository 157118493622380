// @ts-nocheck
import React from 'react';

import TrackableLink from 'views/connectors/TrackableLink';
import { createActionEvent, ACTION_EVENT } from 'tools/analytics/constants';

export interface IOfficeProps {
  location: string;
  addressLineOne: string;
  addressLineTwo?: string;
  addressLineThree?: string;
  contactName: string;
  phoneNumber: string;
}

export const Office = ({
  addressLineOne,
  addressLineTwo,
  addressLineThree,
  contactName,
  location,
  phoneNumber,
}: IOfficeProps) => {
  // This could be done in the constructor, but it shouldn't really impact performance
  const analyticsEvent = createActionEvent(
    ACTION_EVENT.TELEPHONE_LINK,
    { name: location }, // Add Auckland or Wellington based on link clicked
  );

  return (
    <>
      <address>
        <p className="u-textBold u-mT-0 u-mB-0 u-textWhite">{location}</p>
        {addressLineOne}<br />
        {addressLineTwo}&nbsp;<br className="u-md-hidden" />
        {addressLineThree}
      </address>
      <TrackableLink className="Footer-office-contact" url={`tel: ${phoneNumber}`} target="_blank" type="external" event={analyticsEvent}>
        <strong>
          {contactName}
          &nbsp;
          {phoneNumber}
        </strong>
      </TrackableLink>
    </>
  );
};
