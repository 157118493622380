import { takeEvery, put, select } from 'redux-saga/effects';

import { formatEntries } from 'tools/utilities/cms';
import { IS_DEVELOPMENT } from 'redux/modules/app/constants';
import types from './types';
import { CONTENTFUL_SETTINGS } from './constants';
import { getFromCache } from './selectors';

const contentful = require('contentful');

let client = {};
let blogClient = {};

const {
  previewAccessToken,
  blogPreviewAccessToken,
  host,
  space,
  environment,
  accessToken,
  blogSpace,
  blogEnvironment,
  blogAccessToken,
} = CONTENTFUL_SETTINGS;

// Check query params for preview=true
const previewModeQuery = window.location.search.includes('preview=true');
// Check we have the preview access token and host
const isPreviewMode = !!(previewModeQuery && previewAccessToken && host);

// Only use the preview keys when present and in development env
if (IS_DEVELOPMENT && isPreviewMode) {
  client = contentful.createClient({
    space,
    accessToken: previewAccessToken,
    host,
    environment,
  });

  blogClient = contentful.createClient({
    space: blogSpace,
    host,
    accessToken: blogPreviewAccessToken,
    environment: blogEnvironment,
  });
} else {
  client = contentful.createClient({
    space,
    accessToken,
    environment,
  });

  blogClient = contentful.createClient({
    space: blogSpace,
    accessToken: blogAccessToken,
    environment: blogEnvironment,
  });
}

const BLOG_CONTENT_TYPES = [
  'ideasFactoryModules',
  'ideasFactoryPosts',
  'ideasFactoryRelatedPosts',
];

function* getContentFromCMS(contentType, actionType, filter, sort) {
  /*
   * Redux sets the action type to [module_name]_[action_name]
   * i.e.: For cms actions, the name would be CMS_[action_name]
   * for example GET_FEATURED_CASE_STUDIES
   * would be CMS_GET_FEATURED_CASE_STUDIES.
   * Hence the formatting below.
   */
  const actionTypeIdentifier = actionType.substr(actionType.indexOf('_') + 1);

  const cachedItem = yield select(getFromCache, contentType, filter);
  const finalClient = BLOG_CONTENT_TYPES.indexOf(contentType) !== -1
    ? blogClient
    : client;

  try {
    const response = cachedItem || (yield finalClient
      .getEntries({
        content_type: contentType,
        ...filter,
        ...sort,
        include: 4,
      })
      .then((success) => success));

    const formattedData = formatEntries(response);
    // setItem(storageKey, formattedData);

    /*
     * Redux sets the action type to [module_name]_[action_name]
     * i.e.: For cms actions, the name would be CMS_[action_name]
     * for example GET_FEATURED_CASE_STUDIES
     * would be CMS_GET_FEATURED_CASE_STUDIES.
     * Hence the formatting below.
     */
    yield put({
      type: types[`${actionTypeIdentifier}_SUCCESS`],
      payload: {
        data: formattedData,
        includes: response.includes,
      },
    });
  } catch (error) {
    yield put({ type: types[`${actionTypeIdentifier}_FAIL`], error });
  }
}

const getHomeContent = () => getContentFromCMS('homeContent', types.GET_HOME_CONTENT);

const getContentVersion = () => getContentFromCMS('deploymentInfo', types.GET_CONTENT_VERSION);

const getCaseStudy = (action) => {
  const { urlSlug } = action.payload;

  // Handles fetching a sector caseStudy from its unique sector endpoint
  switch (urlSlug) {
    case 'energy-sector':
      return getContentFromCMS('energySector', types.GET_CASE_STUDY);
    // case 'example-sector':
    //   return getContentFromCMS('exampleSector', types.GET_CASE_STUDY);
    default:
      return getContentFromCMS('caseStudies', types.GET_CASE_STUDY, { 'fields.url': urlSlug });
  }
};

const getRelatedCaseStudies = (action) => {
  const { id } = action.payload;
  return getContentFromCMS('relatedCaseStudies', types.GET_RELATED_CASE_STUDIES, {
    'fields.caseStudyUrl': id,
  });
};

const getOpenPositions = () => getContentFromCMS(
  'openPositions',
  types.GET_OPEN_POSITIONS, null, {
    order: 'fields.officeLocation.fields.title',
  },
);
const getGenericJobDescription = () => getContentFromCMS(
  'talkJobListings',
  types.GET_GENERIC_JOB_DESCRIPTION,
  null,
);
const getOffices = () => getContentFromCMS(
  'offices',
  types.GET_OFFICES,
  null,
  { order: 'fields.order' },
);
const getUsDetails = () => getContentFromCMS('usContent', types.GET_US_DETAILS);
const getUsAwards = () => getContentFromCMS('awards', types.GET_US_AWARDS);
const getNotFound = (action) => {
  const { id } = action.payload;
  return getContentFromCMS('moduleCaseStudySplit', types.GET_NOT_FOUND, { 'sys.id': id });
};

const getMeta = (action) => {
  const { url } = action.payload;

  // For the home page case we default to `/`.
  return getContentFromCMS('meta', types.GET_META, { 'fields.url': url || '/' });
};
const getWorkPage = () => getContentFromCMS('workPage', types.GET_WORK_PAGE);
const getServicesPage = () => getContentFromCMS('services', types.GET_SERVICES_PAGE);

/**
 * cms.formatContent generates maximum call stack exceeded for this request.
 * We'll look into removing all of the recursiveness in releases post FS07,
 * since the rest of the site currently assumes the data will have the structure
 * cms.formatContent defines.
 */
function* getIdeasFactoryModules() {
  const cachedItem = yield select(getFromCache, 'ideasFactoryModules', null);

  try {
    const response = cachedItem || (yield blogClient
      .getEntries({
        content_type: 'ideasFactoryModules',
        include: 4,
      })
      .then((success) => success));

    yield put({
      type: types.GET_IDEAS_FACTORY_CONTENT_SUCCESS,
      payload: {
        data: response.items ? {
          ...response.items[0].fields,
          ...response.items[0].sys,
        } : response,
        includes: response.includes,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_IDEAS_FACTORY_CONTENT_FAIL, error });
  }
}

function* getIdeasFactoryPosts() {
  const cachedItem = yield select(getFromCache, 'ideasFactoryPosts', null);

  try {
    const response = cachedItem || (yield blogClient
      .getEntries({
        content_type: 'ideasFactoryPosts',
        include: 4,
      })
      .then((success) => success));

    yield put({
      type: types.GET_IDEAS_FACTORY_POSTS_SUCCESS,
      payload: {
        data: response.items ? response.items : response,
        includes: response.includes,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_IDEAS_FACTORY_POSTS_FAIL, error });
  }
}

function* getIdeasFactoryRelatedPosts(action) {
  const { postUrl } = action.payload;

  let filter = {
    'fields.post.fields.url': postUrl,
    'fields.post.sys.contentType.sys.id': 'ideasFactoryPosts',
  };

  const cachedItem = yield select(getFromCache, 'ideasFactoryRelatedPosts', filter);

  if (!IS_DEVELOPMENT) {
    filter = {
      'fields.post.fields.url': postUrl,
      'fields.post.sys.contentType.sys.id': 'ideasFactoryPosts',
      'fields.post.fields.status': 'Approved by reviewer',
    };
  }

  try {
    const response = cachedItem || (yield blogClient
      .getEntries({
        content_type: 'ideasFactoryRelatedPosts',
        include: 4,
        ...filter,
      })
      .then((success) => success));

    yield put({
      type: types.GET_IDEAS_FACTORY_RELATED_POSTS_SUCCESS,
      payload: {
        data: response.items && response.items.length ? response.items : response,
        includes: response.includes,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_IDEAS_FACTORY_RELATED_POSTS_FAIL, error });
  }
}

const getPost = (action) => {
  const { postUrl } = action.payload;
  return getContentFromCMS('ideasFactoryPosts', types.GET_POST, { 'fields.url': postUrl });
};

const getTsunamiReadyPrivacyPolicy = () => getContentFromCMS(
  'tsunamiSitePrivacyPolicy',
  types.GET_TSUNAMI_READY_PRIVACY_POLICY,
);

export function* cmsSaga() {
  yield takeEvery(types.GET_CASE_STUDY, getCaseStudy);
  yield takeEvery(types.GET_HOME_CONTENT, getHomeContent);
  yield takeEvery(types.GET_OPEN_POSITIONS, getOpenPositions);
  yield takeEvery(types.GET_GENERIC_JOB_DESCRIPTION, getGenericJobDescription);
  yield takeEvery(types.GET_OFFICES, getOffices);
  yield takeEvery(types.GET_RELATED_CASE_STUDIES, getRelatedCaseStudies);
  yield takeEvery(types.GET_US_DETAILS, getUsDetails);
  yield takeEvery(types.GET_US_AWARDS, getUsAwards);
  yield takeEvery(types.GET_META, getMeta);
  yield takeEvery(types.GET_WORK_PAGE, getWorkPage);
  yield takeEvery(types.GET_SERVICES_PAGE, getServicesPage);
  yield takeEvery(types.GET_NOT_FOUND, getNotFound);
  yield takeEvery(types.GET_CONTENT_VERSION, getContentVersion);
  yield takeEvery(types.GET_IDEAS_FACTORY_CONTENT, getIdeasFactoryModules);
  yield takeEvery(types.GET_POST, getPost);
  yield takeEvery(types.GET_IDEAS_FACTORY_POSTS, getIdeasFactoryPosts);
  yield takeEvery(types.GET_IDEAS_FACTORY_RELATED_POSTS, getIdeasFactoryRelatedPosts);
  yield takeEvery(types.GET_TSUNAMI_READY_PRIVACY_POLICY, getTsunamiReadyPrivacyPolicy);
}

export default cmsSaga;
