import React from "react";
import classnames from "classnames";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { FormattedMessage } from "react-intl";

import { Icon } from "views/components/Icon/Icon";

interface IProps {
  isDark?: boolean;
  className?: string;
  url: string;
  title: string;
}

const HOST_URLS: { [keyof: string]: string } = {
  dev: "https://ideas-factory.alphero.com",
  "pre-prod": "https://ideas-factory.alphero.com",
  prod: "https://ideas-factory.alphero.com",
};

const HOST_URL: string = HOST_URLS[process.env.REACT_APP_ENVIRONMENT || "prod"];

export const SocialSharingButtons = ({
  isDark,
  className,
  url,
  title,
}: IProps) => (
  <ul
    className={classnames(className, "SocialLinks u-flexJustifyStart", {
      "SocialLinks--isDark": isDark,
    })}
  >
    <li className="SocialLinks-item">
      <LinkedinShareButton url={`${HOST_URL}/${url}`} title={title}>
        <span className="u-hiddenVisually">
          <FormattedMessage id="LINKEDIN" />
        </span>
        <Icon name="linkedin" />
      </LinkedinShareButton>
    </li>
    <li className="SocialLinks-item">
      <FacebookShareButton url={`${HOST_URL}/${url}`} hashtag="#ideasfactory">
        <span className="u-hiddenVisually">
          <FormattedMessage id="FACEBOOK" />
        </span>
        <Icon name="facebook" />
      </FacebookShareButton>
    </li>
    <li className="SocialLinks-item">
      <TwitterShareButton
        url={`${HOST_URL}/${url}`}
        title={title}
        hashtags={["ideasfactory"]}
      >
        <span className="u-hiddenVisually">
          <FormattedMessage id="TWITTER" />
        </span>
        <Icon name="twitter" />
      </TwitterShareButton>
    </li>
  </ul>
);
