import { connect } from 'react-redux';

import { VideoPlayer } from 'views/components/VideoPlayer/VideoPlayer';
import { getMenuState } from 'redux/modules/app/selectors';
import { MENU_STATE } from 'redux/modules/app/constants';

const mapStateToProps = (state) => {
  const isMenuOpen = getMenuState(state) !== MENU_STATE.CLOSED;

  return {
    shouldForcePause: isMenuOpen,
  };
};

const connected = connect(
  mapStateToProps,
  null,
)(VideoPlayer);

export { connected as VideoPlayer };
