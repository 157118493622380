import React from 'react';
import classnames from 'classnames';

import Slider from 'views/enhancers/Slider';
import { ACTION_EVENT } from 'tools/analytics/constants';
import { ImageProp } from 'views/modules/moduleConstants';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { ResponsiveCmsImageCreator } from 'views/enhancers/ResponsiveCmsImageCreator';

export const ZEPLIN_ID = 'GAL01-01a';

const SLIDER_OVERRIDES = {
  spaceBetween: 0,
  loop: true,
  rebuildOnUpdate: true,
};

export interface Props {
  classes?: string;
  images: ImageProp[];
}

export const GalleryModule = ({ images, classes }: Props) => {
  const isSwiper = images.length > 1;

  const singleImage = images[0];
  let body = <CaseStudyImageWrapper image={singleImage} />;

  if (isSwiper) {
    body = (
      <Slider sliderSettings={SLIDER_OVERRIDES} eventName={ACTION_EVENT.CASE_STUDY_CAROUSEL}>
        {images.map((image) => {
          if (!image) {
            return null;
          }

          return (
            <li key={image.title} className="Slider-slide">
              <CaseStudyImageWrapper image={image} />
            </li>
          );
        })}
      </Slider>
    );
  }

  const moduleClasses = classnames(
    'CaseStudy-image',
    'ModuleWrapper',
    { classes },
  );

  return (
    <section className={moduleClasses}>
      <div className="Container">
        <ComponentInfo zeplinId={ZEPLIN_ID} />
        {body}
      </div>
    </section>
  );
};

function CaseStudyImageWrapper({ image }: { image: ImageProp }) {
  return (
    <div className="CaseStudy-image-wrapper">
      <ResponsiveCmsImageCreator imageUrl={image.file.url}>
        {({ imageUrlWithSize, ref }: { imageUrlWithSize: string, ref: any }) => (
          <img ref={ref} src={imageUrlWithSize} alt={image.description || ''} />
        )}
      </ResponsiveCmsImageCreator>
    </div>
  );
}
