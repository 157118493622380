import React, { Component } from 'react';
import classNames from 'classnames';

import { ImageProp } from 'views/modules/moduleConstants';

import { ImageInfo } from 'views/components/ImageInfo/ImageInfo';

/**
 * This is an Image wrapper that will show a animated grey box until the image loads,
 * it will then fade in the image once it's loaded and the is-ready class has been added.
 * ImageWrapper should be wrapped in a element that has a width and a height if the `useImgTag`
 * option isn't being used.
 *
 * Note that if you're loading images from Contentful, you generally should use
 * ResponsiveCmsImageWrapper rather than using this directly
 *
 * @param {string} url The image url to be loaded in
 * @param {number} expectedHeight Height to be for the loading placeholder (intended to be used with
 * useImgTag)
 * @param {boolean} useImgTag If true, the image will be displayed using an HTML img tag. This is
 * useful if the image has a non-fixed/unknown aspect-ratio.
 * @param {boolean} altText Text that should be used by assistive technology in place of the image.
 * If this isn't provided the image is assumed to be decorative-only (i.e. it doesn't provide any
 * information)
 */

interface IProps {
  altText?: string;
  elementRef?: any;
  expectedHeight?: number;
  hasLoader?: boolean;
  imageData?: ImageProp;
  is16x9?: boolean;
  isContain?: boolean;
  isRound?: boolean;
  isSquare?: boolean;
  onError?: (url: string) => void;
  onReady?: (url: string) => void;
  url: string;
  useImgTag?: boolean;
  heroBanner?: boolean;
  description?: string;
}

interface IState {
  image?: HTMLImageElement;
  isReady: boolean;
  isError: boolean;
}

export class ImageWrapper extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      image: undefined,
      isReady: false,
      isError: false,
    };

    // @ts-ignore
    this.imageEl = React.createRef();
  }

  componentWillMount() {
    const { url } = this.props;
    this.setImage(url);
  }

  componentWillReceiveProps(nextProps: IProps) {
    // @ts-ignore
    if (this.isUnmounting) {
      return;
    }

    const { url } = this.props;

    if (url !== nextProps.url) {
      this.setImage(nextProps.url);
    }
  }

  componentWillUnmount() {
    // @ts-ignore
    this.isUnmounting = true;
  }

  setImage = (url: string) => {
    if (!url) {
      return;
    }

    const image = new Image();

    image.src = url;
    image.onload = this.handleLoad;
    image.onerror = this.handleError;

    this.setState({
      image,
    });
  };

  handleLoad = () => {
    // @ts-ignore
    if (this && !this.isUnmounting) {
      this.setState({ isReady: true });

      const { onReady, url } = this.props;
      if (onReady) {
        onReady(url);
      }
    }
  };

  handleError = (e: any) => {
    const { onError, url } = this.props;
    // eslint-disable-next-line no-console
    console.error('Image failed to load', url, e);

    if (this) {
      this.setState({
        isReady: true,
        isError: true,
      });
    }

    if (onError) {
      onError(url);
    }
  };

  render() {
    const { isError, isReady, image } = this.state;

    const {
      isRound,
      isSquare,
      isContain,
      is16x9,
      useImgTag,
      expectedHeight,
      altText,
      elementRef,
      hasLoader = true,
      imageData,
      heroBanner,
    } = this.props;

    const src = image ? image.src : null;

    const description = altText || imageData?.description;
    const size = imageData?.file?.details.size;
    const title = imageData?.file?.fileName;
    const imageExtension = imageData?.file?.contentType;
    const assetId = imageData?.id;

    const imageWrapperClasses = classNames('ImageWrapper', {
      'is-ready': isReady,
      'ImageWrapper--round': isRound,
      'ImageWrapper--square': isSquare,
      'ImageWrapper--16x9': is16x9,
      'ImageWrapper--contain': isContain,
      'ImageWrapper--hasLoader': hasLoader,
      'u-flex u-flexJustifyCenter': heroBanner,
    });

    const imageWrapperStyle = {
      minHeight: !isReady && expectedHeight != null ? expectedHeight : 'auto',
    };

    const imageClasses = classNames('ImageWrapper-img');

    const source = isReady && src;

    if (isError) {
      return null;
    }

    let imageElement = null;

    if (useImgTag) {
      // @ts-ignore
      imageElement = <img src={source || undefined} ref={this.imageEl} className={imageClasses} alt={description} />;
    } else {
      let imgStyles = {};
      if (source) {
        imgStyles = { backgroundImage: `url('${source}')` };
      }

      const accessibilityAttrs = altText
        ? {
          'role': 'img',
          'aria-label': altText,
        }
        : {};

      imageElement = <div className={imageClasses} style={imgStyles} {...accessibilityAttrs} />;
    }

    return (
      <div className={imageWrapperClasses} style={imageWrapperStyle} ref={elementRef}>
        {imageElement}
        <ImageInfo
          description={description}
          // fileName={fileName}
          imageExtension={imageExtension}
          size={size}
          title={title}
          assetId={assetId}
        />
      </div>
    );
  }
}
