import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import memoizeOne from 'memoize-one';

const WORDS = ['discombobulate', 'reticulating', 'splines', 'dogs', 'cats', 'rhubarb'];

class SkeletonText extends Component {
  generateSentence = memoizeOne((wordCount) => {
    let sentence = '';

    for (let i = 0; i < wordCount; i += 1) {
      const randomWord = WORDS[Math.floor(Math.random() * WORDS.length)];
      sentence += `${randomWord} `;
    }

    return sentence;
  });

  render() {
    const { className, wordCount, tagName: TagName } = this.props;

    let { text } = this.props;

    if (!text) {
      text = this.generateSentence(wordCount);
    }

    return <TagName className={`${className} SkeletonText`} data-text={text.trim()} aria-hidden />;
  }
}

SkeletonText.propTypes = {
  className: PropTypes.string,
  tagName: PropTypes.string,
  text: PropTypes.string,
  wordCount: PropTypes.number,
};

SkeletonText.defaultProps = {
  className: '',
  wordCount: 5,
  text: null,
  tagName: 'div',
};

export { SkeletonText };
