import { connect } from 'react-redux';

import { SkewModule } from 'views/components/CaseStudyDetail/modules/SkewModule/SkewModule';

const mapStateToProps = (state) => ({
  isViewportMobile: state.browser.is.mobile,
});

const ConnectedModule = connect(
  mapStateToProps,
  null,
)(SkewModule);

export { ConnectedModule as SkewModule };
