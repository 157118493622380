import React, { Component } from 'react';
import classNames from 'classnames';
import { v1 } from 'uuid';

import { Animator } from 'views/components/Animator/Animator';
import { ImageProp } from 'views/modules/moduleConstants';
import { SkewCaption, CAPTION_VERTICAL_POSITIONS } from 'views/components/SkewCaption/SkewCaption';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { handleViewport, SCROLL_DIRECTION } from 'views/enhancers/InViewport';
import { ResponsiveCmsImageCreator } from 'views/enhancers/ResponsiveCmsImageCreator';

/**
 * Possible position configurations/variations of the skew poster caption.
 * These values relate directly to values sent from the CMS
 */
export const SKEW_CAPTION_VARIATIONS = {
  RIGHT: 'Right Caption',
  BOTTOM: 'Bottom Caption',
  TOP: 'Top Left Caption',
};

const IN_VIEWPORT_OPTIONS = {
  threshold: 0.8,
};

const ANIMATION_OPTIONS = {
  loop: false,
  autoplay: false,
};

export interface SkewProps {
  animation?: ImageProp;
  animationMobile?: ImageProp;
  clientName?: string;
  elementToObserveRef: () => void;
  hasIcon?: boolean;
  id: string;
  image: ImageProp;
  imageMobile?: ImageProp;
  inViewport: boolean;
  isCenter: boolean;
  isViewportMobile: boolean;
  mobileOverlay?: ImageProp;
  overlay?: ImageProp;
  scrollDirection?: number;
  shouldAnimateOnLeave?: boolean;
  shouldReset?: boolean;
  text?: string;
  header: string;
  variation?: string;
  name: string;
}

export const SkewComponent = class SkewComponent extends Component<SkewProps> {
  state = {
    wasInViewport: false,
  };

  componentWillReceiveProps(nextProps: SkewProps) {
    const { shouldAnimateOnLeave, inViewport } = nextProps;

    if (shouldAnimateOnLeave && inViewport) {
      this.setState({
        wasInViewport: true,
      });
    }
  }

  render() {
    const {
      clientName,
      header,
      text,
      id,
      image,
      imageMobile,
      inViewport,
      isViewportMobile,
      overlay,
      mobileOverlay,
      variation,
      hasIcon,
      animation,
      animationMobile,
      scrollDirection,
      shouldAnimateOnLeave,
      shouldReset,
      isCenter,
      elementToObserveRef,
      name,
    } = this.props;

    const { wasInViewport } = this.state;
    const isLeft = !isCenter;
    const clipPathId = v1();

    let variationClass = '';
    let zeplinID = '';
    let captionVerticalPosition = CAPTION_VERTICAL_POSITIONS.TOP;

    const shouldPlay = (inViewport && !shouldAnimateOnLeave)
      || (!inViewport
        && wasInViewport
        && shouldAnimateOnLeave
        && scrollDirection === SCROLL_DIRECTION.DOWN);

    switch (variation) {
      case SKEW_CAPTION_VARIATIONS.RIGHT:
        variationClass = 'SkewPoster--rightCaption';
        zeplinID = 'SKW01-01c';
        captionVerticalPosition = CAPTION_VERTICAL_POSITIONS.MIDDLE;
        break;
      case SKEW_CAPTION_VARIATIONS.BOTTOM:
        variationClass = 'SkewPoster--bottomCaption';
        zeplinID = 'SKW01-01b';
        captionVerticalPosition = CAPTION_VERTICAL_POSITIONS.BOTTOM;
        break;
      case SKEW_CAPTION_VARIATIONS.TOP:
      default:
        variationClass = 'SkewPoster--topCaption';
        zeplinID = 'SKW01-01a';
        captionVerticalPosition = CAPTION_VERTICAL_POSITIONS.TOP;
        break;
    }

    const isBottomVariation = variation === SKEW_CAPTION_VARIATIONS.BOTTOM;
    const isTopVariation = variation === SKEW_CAPTION_VARIATIONS.TOP;
    const isRightVariation = variation === SKEW_CAPTION_VARIATIONS.RIGHT;

    const altText = image?.description;

    const accessibilityAttrs = altText
      ? {
        'aria-label': altText,
      }
      : {};

    // Just need the overlay (for tablet up), only some skewModules need to show the mobileOverlay
    const hasOverlay = !!overlay;
    const hasAnimation = !!animation;
    const captionElement = (
      <SkewCaption
        className={classNames('SkewPoster-caption Grid-cell u-width11of12', {
          'u-md-width5of12': !isRightVariation,
          'u-before1of12 u-md-before1of2 u-md-width6of12': isRightVariation,
        })}
        title={header}
        text={text}
        position={captionVerticalPosition}
        icon={hasIcon ? 'quote' : null}
        isLeft={isLeft}
        clientName={clientName}
        shouldFlipX={isRightVariation}
      />
    );

    const finalMobileOverlay = mobileOverlay || overlay;

    const onTopElement = (
      <div className="SkewPoster-overlayContainer">
        {captionElement}

        {hasAnimation && (
          <div className="SkewPoster-overlay">
            <LargieSmalls
              mobile={
                animationMobile && (
                  <Animator
                    key={animationMobile.id}
                    shouldPlay={shouldPlay}
                    animation={animationMobile}
                    lottieOptions={ANIMATION_OPTIONS}
                    shouldReset={shouldReset}
                    alt={animationMobile.description}
                  />
                )
              }
              tablet={
                hasAnimation && (
                  <Animator
                    shouldPlay={shouldPlay}
                    key={animation?.id}
                    animation={animation}
                    lottieOptions={ANIMATION_OPTIONS}
                    shouldReset={shouldReset}
                    alt={animation?.description}
                  />
                )
              }
            />
          </div>
        )}

        {/* Only show the Overlay Images if they're present and there's no animation */}
        {hasOverlay && !hasAnimation && (
          <div
            className={classNames('SkewPoster-overlay', {
              'u-md-width1of2 is-bottom': isTopVariation,
            })}
          >
            <LargieSmalls
              mobile={(
                <img
                  className="SkewPoster-overlay-image"
                  src={finalMobileOverlay?.file.url}
                  alt=""
                />
)}
              tablet={<img className="SkewPoster-overlay-image" src={overlay?.file.url} alt="" />}
            />
          </div>
        )}
      </div>
    );

    const nameWithoutSpaces = name.replace(/\s/g, '');

    return (
      <section id={nameWithoutSpaces} className="CaseStudy-skew ModuleWrapper u-flex" ref={elementToObserveRef}>
        <div className="Container">
          <ComponentInfo id={id} zeplinId={zeplinID} />
          <div
            className={classNames('SkewPoster', variationClass, 'Grid', 'Grid--noGutter', {
              'has-overlay': hasOverlay,
            })}
          >
            <div
              className={classNames(
                'SkewPoster-imageContainer',
                'Grid-cell',
                'u-width11of12',
                'u-md-width10of12',
                {
                  'u-before1of12 u-md-before2of12': !isRightVariation,
                  'u-before1of12 u-xl-width10of12': isBottomVariation,
                },
              )}
            >
              <svg
                className="SkewPoster-image"
                role="img"
                {...accessibilityAttrs}
                // Mobile aspect-ratio of 4:5
                viewBox={isViewportMobile ? '0 0 4 5' : undefined}
              >
                <defs>
                  <clipPath
                    className="SkewPoster-clip"
                    id={clipPathId}
                    clipPathUnits="objectBoundingBox"
                  >
                    {!isRightVariation ? (
                      <path
                        // Trapezoid corner coords, starting top-left moving clockwise
                        // The units of these coords are relative to the element the clip path is applied to.
                        // i.e. 0 0 is the top-left corner of the target element, 1 1 is the bottom right.
                        // See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/clipPathUnits
                        d="
                    M0 0.08
                    L1 0
                    L1 1
                    L0 0.92
                      Z
                    "
                      />
                    ) : (
                      <path
                        d="
                      M0 0
                      L1 0.08
                      L1 0.92
                      L0 1
                        Z
                      "
                      />
                    )}
                  </clipPath>
                </defs>
                <LargieSmalls
                  mobile={
                    imageMobile && (
                      <ResponsiveCmsImageCreator imageUrl={imageMobile.file.url}>
                        {({ imageUrlWithSize, ref }: any) => (
                          <image
                            xlinkHref={imageUrlWithSize}
                            ref={ref}
                            href={imageUrlWithSize}
                            clipPath={`url(#${clipPathId})`}
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMinYMin slice" // like background-position: 0 0 and background-size: cover in CSS
                          />
                        )}
                      </ResponsiveCmsImageCreator>
                    )
                  }
                  tablet={
                    image && image.file && (
                      <ResponsiveCmsImageCreator imageUrl={image.file.url}>
                        {({ imageUrlWithSize, ref }: any) => (
                          <image
                            xlinkHref={imageUrlWithSize}
                            ref={ref}
                            href={imageUrlWithSize}
                            clipPath={`url(#${clipPathId})`}
                            width="100%"
                            height="100%"
                            preserveAspectRatio="xMinYMin slice" // like background-position: 0 0 and background-size: cover in CSS
                          />
                        )}
                      </ResponsiveCmsImageCreator>
                    )
                  }
                />
              </svg>
            </div>
            {onTopElement}
          </div>
        </div>
      </section>
    );
  }
};

export const SkewModule = handleViewport(SkewComponent, IN_VIEWPORT_OPTIONS);
