import { async, createTypes } from 'redux-action-creator';

export default createTypes(
  [
    'ADD_POST_FILTER',
    'REMOVE_POST_FILTER',
    'LOCATION_CHANGE',
    'TOGGLE_MENU',
    'MENU_STATE_CHANGE',
    ...async('SEND'),
    'RESET_SENT_STATE',
    'TOGGLE_META',
    'CLOSE_EMERGENCY_BANNER',
    ...async('SUBSCRIBE_TO_NEWSLETTER'),
    'RESET_NEWSLETTER_SUBSCRIPTION_STATE',
    ...async('GET_JOB_LISTINGS'),
  ],
  'APP',
);
