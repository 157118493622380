import React from 'react';
import classNames from 'classnames';

import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { RichText } from 'views/components/RichText/RichText';
import { DataProp } from 'views/modules/moduleConstants';
import { TEXT_ALIGNMENT } from 'redux/modules/app/constants';

interface IProps {
  alignment: string;
  hasMargin?: boolean;
  id: string,
  text: DataProp;
}

export const RichTextModule = (props: IProps) => {
  const {
    id,
    text,
    alignment,
    hasMargin,
  } = props;

  const richTextClasses = classNames('Grid-cell', {
    'u-textCenter': alignment === TEXT_ALIGNMENT.CENTRE,
    'u-textRight': alignment === TEXT_ALIGNMENT.RIGHT,
    'u-textLeft': alignment === TEXT_ALIGNMENT.LEFT,
  });

  return (
    <section className={classNames('CaseStudy-richText', { ModuleWrapper: hasMargin })}>
      <div className="Container">
        <ComponentInfo id={id} zeplinId="Energy sector - Title before last animation" />
        <div className="Grid">
          <div className={richTextClasses}>
            <RichText text={text} />
          </div>
        </div>
      </div>
    </section>
  );
};