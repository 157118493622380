import { connect } from 'react-redux';

import { PosterModule } from 'views/components/CaseStudyDetail/modules/PosterModule/PosterModule';

const mapStateToProps = (state) => ({
  isViewportMobile: state.browser.is.mobile,
});

const ConnectedModule = connect(
  mapStateToProps,
  null,
)(PosterModule);

export { ConnectedModule as PosterModule };
