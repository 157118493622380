import typesApp from 'redux/modules/app/types';

const GA_CATEGORIES = {
  PAGE: 'page',
  ACTION: 'action',
  DEFAULT: 'default',
};

export const ACTION_TO_GA_EVENT = {
  [typesApp.SEND]: {
    category: GA_CATEGORIES.ACTION,
    payloadAttributes: [{}],
  },
};

export const DEFAULT_GA_EVENT_CONFIG = {
  category: GA_CATEGORIES.DEFAULT,
  payloadAttributes: [],
};

// Action is a string according to the docs and the type definitions
export const createActionEvent = (label, action) => {
  let actionEvent = action || label;

  // Convert the action to a string since Google Analytics only takes a string
  if (typeof actionEvent === 'object') {
    actionEvent = JSON.stringify(actionEvent);
  }

  return {
    category: GA_CATEGORIES.ACTION,
    action: actionEvent,
    label,
  };
};

export const ACTION_EVENT = {
  LOGO_CLICK: 'Click - Logo',
  MENU_CLICK: 'Click - Menu', // Open or close
  HERO_CASE_STUDY: 'Click - Hero case study', // Which case study
  NAVIGATION_WORK: 'Click - Navigation work',
  NAVIGATION_US: 'Click - Navigation us',
  NAVIGATION_TALK: 'Click - Navigation talk',
  SEE_ALL_WORK: 'Click - See all work',
  SOCIAL_LINKS: 'Click - Social links', // Which one was clicked - FB, Twitter, or Instagram
  LETS_TALK: 'Click - Lets talk',
  SUBMIT_CLICK: 'Click - Submit', // Capture Let's talk state regardless of validation
  SUBMIT_CLICK_VALID: 'Click - Submit - Is Valid', // Is valid
  WORK_WITH_US: 'Click - Work with us',
  TELEPHONE_LINK: 'Click - Telephone link', // Auckland or Wellington
  LETS_TALK_SELECT_LIST: 'Click - Lets Talk, How can we help you, Select list',
  CAROUSEL: 'Click - carousel', // Generic carousel fallback if a specific analytics label isn't provided
  VIDEO_PLAY: 'Video - play',
  VIDEO_WATCHING: 'Video - watching',
  VIDEO_WATCHED: 'Video - watched',

  // All work page
  WORK_CASE_STUDY_CAROUSEL: 'Click - Work - Carousel',

  // Case Study specific
  CASE_STUDY: 'Click - Case study', // Which case study
  CASE_STUDY_CAROUSEL: 'Click - Case study - Carousel',
  CASE_STUDY_NEXT: 'Click - Next case study', // From - to

  // Us specific
  US_CAROUSEL: 'Click - Us - Carousel',

  // Ideas Factory subscribe action
  IDEAS_FACTORY_SUBSCRIBE: 'Ideas Factory - New subscription',
};
