import React from 'react';

interface Props {
  name?: string;
  youTubeVideoUrl: string;
}

export const YouTubeVideo = (props: Props) => {
  const {
    name,
    youTubeVideoUrl,
  } = props;

  if (!youTubeVideoUrl) {
    return null;
  }

  let youTubeEmbedUrl = youTubeVideoUrl;

  // https://www.youtube.com/watch?v=l1cKFUDbZbA
  if (youTubeEmbedUrl.includes('v=')) {
    const url = new URL(youTubeEmbedUrl);
    const youTubeVideoId = url.searchParams.get('v');

    if (youTubeVideoId) {
      // Create our own embed url so we can pass it to the iFrame
      youTubeEmbedUrl = `https://www.youtube.com/embed/${youTubeVideoId}`;
    } else {
      console.warn('Incorrect youTubeVideoId', props);
      return null;
    }
  }

  return (
    <div className="YouTubeVideo">
      <iframe
        className="YouTubeVideo-iframe"
        title={name}
        src={youTubeEmbedUrl}
        frameBorder={0}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};
