import propTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectorsApp from 'redux/modules/app/selectors';

const HasOverlay = (props) => {
  const { isMenuOpen, render, shouldShowMeta } = props;
  return render(isMenuOpen, shouldShowMeta);
};

HasOverlay.propTypes = {
  isMenuOpen: propTypes.bool.isRequired,
  render: propTypes.func.isRequired,
  shouldShowMeta: propTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMenuOpen: selectorsApp.isMenuOpen(state),
  shouldShowMeta: selectorsApp.shouldShowMeta(state),
});

export default connect(
  mapStateToProps,
  null,
)(HasOverlay);
