// @ts-nocheck
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import { ImageProp } from 'views/modules/moduleConstants';

import { Animator } from 'views/components/Animator/Animator';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import TrackableLink from 'views/connectors/TrackableLink';
import { RichText } from 'views/components/RichText/RichText';

const RICH_TEXT_OVERRIDES = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_: any, children: any) => (
      <p className="LinkPost-description">{children}</p>
    ),
  },
};

export interface ILinkPost {
  animation: {
    fields: ImageProp;
  };
  heading: string;
  id: string;
  description: string;
  url: string;
  contentType: string;
}

interface IProps {
  content: ILinkPost;
}

export const LinkPost = ({ content }: IProps) => (
  <TrackableLink url={`/${content.url}`}>
    <div className="LinkPost">
      <ComponentInfo zeplinId="N/A" id={content.id} isBlogContent />
      <Animator animation={content.animation.fields} shouldLoop alt={content.animation.fields.description} />
      <p className="LinkPost-title">{content.heading}</p>
      <RichText text={content.description} overrides={RICH_TEXT_OVERRIDES} />
    </div>
  </TrackableLink>
);
