import React from 'react';
import { useSelector } from 'react-redux';
import Masonry from 'react-masonry-css';
import { BLOCKS } from '@contentful/rich-text-types';
import classnames from 'classnames';

import { RichText } from 'views/components/RichText/RichText';
import { RenderModuleContent } from 'views/pages/IdeasFactory/IdeasFactory';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';

const FEATURED_MODULE_VARIATIONS = {
  TAG: 'Tag',
  COLLECTION_PORTRAIT: 'Collection - portrait',
  COLLECTION_LANDSCAPE: 'Collection - landscape',
  COLLECTION_MIXED: 'Collection - mixed',
  CATEGORIES: 'Categories',
};

const FEATURED_MODULE_VARIATION_ZEPLIN_ID = {
  [FEATURED_MODULE_VARIATIONS.TAG]: 'FMOD02-01a',
  [FEATURED_MODULE_VARIATIONS.COLLECTION_PORTRAIT]: 'FMOD04-01a',
  [FEATURED_MODULE_VARIATIONS.COLLECTION_LANDSCAPE]: 'FMOD03-01a',
  [FEATURED_MODULE_VARIATIONS.COLLECTION_MIXED]: 'FMOD05-01a',
  [FEATURED_MODULE_VARIATIONS.CATEGORIES]: 'FMOD06-01a',
};

const MASONRY_LAYOUT: any = {
  [FEATURED_MODULE_VARIATIONS.TAG]: {
    default: 3, 1023: 2, 767: 1,
  },
  [FEATURED_MODULE_VARIATIONS.COLLECTION_PORTRAIT]: {
    default: 4, 1279: 3, 1023: 2, 767: 1,
  },
  [FEATURED_MODULE_VARIATIONS.COLLECTION_LANDSCAPE]: {
    default: 2, 1279: 1,
  },
  [FEATURED_MODULE_VARIATIONS.COLLECTION_MIXED]: {
    default: 2, 1279: 3, 1023: 2, 767: 1,
  },
  [FEATURED_MODULE_VARIATIONS.CATEGORIES]: {
    default: 1, 1023: 2, 767: 1,
  },
};

const isLandscapePost = (type: string, index: number, isViewportAboveLaptop: boolean, isViewportMobile: boolean) => {
  switch (type) {
    case FEATURED_MODULE_VARIATIONS.COLLECTION_LANDSCAPE:
      return !isViewportMobile;
    case FEATURED_MODULE_VARIATIONS.COLLECTION_MIXED:
      return index > 1 && isViewportAboveLaptop;
    default:
      return false;
  }
};

export interface IFeaturedModule {
  categoryHeadings?: string[];
  categorySectionOne?: any[];
  categorySectionTwo?: any[];
  categorySectionThree?: any[];
  categorySectionFour?: any[];
  contentType: string;
  description?: string;
  heading?: string;
  posts: any;
  type: string;
  id: string;
}

interface IProps {
  content: IFeaturedModule;
}

export const FeaturedModule = ({ content }: IProps) => {
  const {
    categoryHeadings,
    categorySectionOne,
    categorySectionTwo,
    categorySectionThree,
    categorySectionFour,
    description,
    heading,
    posts,
    type,
    id,
  } = content;
  const isViewportMobile = useSelector((state: any) => state.browser.is.mobile);
  const isViewportLaptop = useSelector((state: any) => state.browser.is.laptop);
  const isViewportAboveLaptop = useSelector((state: any) => state.browser.greaterThan.laptop);

  const categorySections = [
    categorySectionOne,
    categorySectionTwo,
    categorySectionThree,
    categorySectionFour,
  ];

  const isVariationTag = type === FEATURED_MODULE_VARIATIONS.TAG;
  const isVariationCollectionMixed = type === FEATURED_MODULE_VARIATIONS.COLLECTION_MIXED;
  const isVariationCategories = type === FEATURED_MODULE_VARIATIONS.CATEGORIES;
  const isVariationLandscape = type === FEATURED_MODULE_VARIATIONS.COLLECTION_LANDSCAPE;

  const zeplinId = FEATURED_MODULE_VARIATION_ZEPLIN_ID[type];

  const wrapperClasses = classnames('FeaturedModule', {
    'FeaturedModule--onlyPosts': !heading && !description && !categoryHeadings,
    'FeaturedModule--categories': isVariationCategories,
  });
  const textClasses = classnames('Grid-cell', {
    'u-width1of1 u-xl-width1of4': isVariationTag,
  });
  const postsClasses = classnames('Grid-cell', {
    'u-width1of1 u-xl-width3of4 u-xl-mT-0': isVariationTag,
    'u-width1of2': isVariationCollectionMixed && isViewportAboveLaptop,
    'u-width10of12 u-before1of12': isVariationLandscape && isViewportLaptop,
  });

  const renderedPosts = posts?.map((post: any, index: number) => {
    const isLandscape = isLandscapePost(type, index, isViewportAboveLaptop, isViewportMobile);
    const shouldRenderPostInMasonry = type !== FEATURED_MODULE_VARIATIONS.COLLECTION_MIXED || index < 2 || !isViewportAboveLaptop;

    return shouldRenderPostInMasonry && (
      <RenderModuleContent
        content={{
          ...post,
          contentType: post.sys.contentType.id,
        }}
        isLandscape={isLandscape}
        key={`content-${post.sys.id}`}
        shouldScrollToTopOnFilter
      />
    );
  });

  return (
    <div className={wrapperClasses}>
      <div className="FeaturedModule-content Container">
        <ComponentInfo zeplinId={zeplinId} id={id} isBlogContent />
        <div className="Grid">

          {type === FEATURED_MODULE_VARIATIONS.CATEGORIES ? (
            <>
              {categoryHeadings?.map((categoryHeading, index) => (
                <div key={categoryHeading} className="Grid-cell u-width1of1 u-lg-width1of2 u-xl-width1of4">
                  <h3 className="FeaturedModule-content-heading">{categoryHeading}</h3>
                  <hr className="FancyRule FancyRule--drawn u-mB-md" aria-hidden />
                  <Masonry
                    breakpointCols={MASONRY_LAYOUT[FEATURED_MODULE_VARIATIONS.CATEGORIES]}
                    className="MasonryGrid"
                    columnClassName="MasonryGrid-column"
                  >
                    {categorySections[index]?.map((post: any) => (
                      <RenderModuleContent
                        content={post}
                        isLandscape={false}
                        key={`content-${post.id}`}
                        shouldScrollToTopOnFilter
                      />
                    ))}
                  </Masonry>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className={textClasses}>
                {heading && (
                  <>
                    <h3 className="FeaturedModule-content-heading">{heading}</h3>
                    <hr className="FancyRule FancyRule--drawn u-mB-0" aria-hidden />
                  </>
                )}
                {description && (
                <RichText
                  text={description}
                  overrides={{
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
                        <p className="FeaturedModule-content-description">{children}</p>
                      ),
                    },
                  }}
                />
                )}
              </div>
              <div className={postsClasses}>
                <Masonry
                  breakpointCols={MASONRY_LAYOUT[type]}
                  className="MasonryGrid"
                  columnClassName="MasonryGrid-column"
                >
                  {renderedPosts}
                </Masonry>
              </div>
              {(type === FEATURED_MODULE_VARIATIONS.COLLECTION_MIXED && isViewportAboveLaptop) && (
              <div className="Grid-cell u-width1of2 u-mT-md">
                {posts?.map((post: any, index: number) => index > 1 && (
                  <RenderModuleContent
                    content={post}
                    isLandscape
                    key={`content-${post.id}`}
                    shouldScrollToTopOnFilter
                  />
                ))}
              </div>
              )}
            </>
          )}

        </div>
      </div>
    </div>
  );
};
