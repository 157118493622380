import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { createActionEvent } from "tools/analytics/constants";

export default class TrackableLink extends Component {
  handleLinkClick = () => {
    const { event, url, label } = this.props;

    let actionEvent = event;
    if (!event) {
      // Create event if it's undefined based on url and label
      actionEvent = createActionEvent(label, url);
    }

    // Push event to react analytics
    ReactGA.event(actionEvent);
  };

  render() {
    const {
      children,
      className,
      url,
      type,
      label,
      isDisabled,
      id,
      rel,
      target,
      locationState,
    } = this.props;

    const isExternalLink = target === "_blank" && type === "external";

    console.log({ label, url, isExternalLink });

    return isExternalLink ? (
      <a
        href={url}
        onClick={this.handleLinkClick}
        className={className}
        type={type}
        disabled={isDisabled}
        label={label}
        id={id}
        aria-label={label}
        rel={rel}
        target={target}
      >
        {children}
      </a>
    ) : (
      <Link
        to={{
          pathname: url,
          state: locationState,
        }}
        onClick={this.handleLinkClick}
        className={className}
        type={type}
        disabled={isDisabled}
        label={label}
        id={id}
        aria-label={label}
        rel={rel}
        target={target}
      >
        {children}
      </Link>
    );
  }
}

TrackableLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  event: PropTypes.shape({
    // Props are based on the react-ga EventArgs interface
    action: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    // Optional event props
    label: PropTypes.string,
    nonInteraction: PropTypes.bool,
    transport: PropTypes.string,
    value: PropTypes.number,
  }),
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  locationState: PropTypes.object,
  rel: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
};

TrackableLink.defaultProps = {
  children: null,
  event: null,
  className: "",
  type: "internal",
  isDisabled: false,
  label: "",
  locationState: null,
  id: null,
  target: null,
  rel: null,
};
