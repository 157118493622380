import { connect } from 'react-redux';

import * as actionsApp from 'redux/modules/app/actions';
import { isMenuOpen } from 'redux/modules/app/selectors';

import { Header } from 'views/components/Header/Header';

const mapStateToProps = (state) => ({
  isMenuOpen: isMenuOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenu(analyticsData) {
    return dispatch(actionsApp.toggleMenu(analyticsData));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
