import { connect } from 'react-redux';

import { isViewportMobile } from 'redux/modules/app/selectors';

import { DescriptionModule } from './DescriptionModule';

const mapStateToProps = (state) => ({
  isViewportMobile: isViewportMobile(state),
});

const DescriptionModuleConnected = connect(
  mapStateToProps,
  null,
)(DescriptionModule);

export { DescriptionModuleConnected as DescriptionModule };
