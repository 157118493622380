import types from './types';
import {
  REACT_APP_SHOW_EMERGENCY_BANNER,
  LOCATION_CHANGE_ACTION_TYPE,
  MENU_STATE,
} from './constants';

const initialState = {
  currentLocation: '',
  features: [],
  ideasFactoryPostFilters: [],
  isLoading: false,
  isMenuOpen: false,
  // more specific menu open/closed state, controlled by the menu component
  menuState: MENU_STATE.CLOSED,
  contact: {
    hasSent: false,
    isSending: false,
    error: false,
  },
  shouldShowMeta: false,
  isEmergencyBannerOpen: REACT_APP_SHOW_EMERGENCY_BANNER,
  hasSubscribedToNewsletter: false,
  hasSubscriptionErrored: false,
  jobListings: {
    isLoading: false,
    data: [],
  },
};

const reducerApp = (state = initialState, action) => {
  switch (action.type) {
    case types.LOCATION_CHANGE:
    case LOCATION_CHANGE_ACTION_TYPE:
      return {
        ...state,
        currentLocation: action.payload.location,
        // If the browser forward and back buttons are used then close the menu
        isMenuOpen: false,
      };
    case types.TOGGLE_MENU:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    case types.MENU_STATE_CHANGE:
      return {
        ...state,
        menuState: action.payload.menuState,
      };
    case types.TOGGLE_META:
      return {
        ...state,
        shouldShowMeta: !state.shouldShowMeta,
      };
    case types.SEND:
      return {
        ...state,
        contact: {
          ...state.contact,
          isSending: true,
        },
      };
    case types.SEND_SUCCESS:
      return {
        ...state,
        contact: {
          ...state.contact,
          isSending: false,
          hasSent: true,
        },
      };
    case types.SEND_FAIL:
      return {
        ...state,
        contact: {
          ...state.contact,
          isSending: false,
          hasSent: false,
          error: true,
        },
      };
    case types.RESET_SENT_STATE:
      return {
        ...state,
        contact: {
          isSending: false,
          hasSent: false,
          error: false,
        },
      };
    case types.ADD_POST_FILTER:
      return {
        ...state,
        ideasFactoryPostFilters: [
          ...state.ideasFactoryPostFilters,
          action.payload.tag,
        ],
      };
    case types.REMOVE_POST_FILTER:
      return {
        ...state,
        ideasFactoryPostFilters: state.ideasFactoryPostFilters.filter(
          (filter) => filter !== action.payload.tag,
        ),
      };
    case types.CLOSE_EMERGENCY_BANNER:
      return {
        ...state,
        isEmergencyBannerOpen: false,
      };
    case types.SUBSCRIBE_TO_NEWSLETTER:
      return {
        ...state,
        isLoading: true,
        hasSubscribedToNewsletter: false,
        hasSubscriptionErrored: false,
      };
    case types.SUBSCRIBE_TO_NEWSLETTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasSubscribedToNewsletter: true,
        hasSubscriptionErrored: false,
      };
    case types.SUBSCRIBE_TO_NEWSLETTER_FAIL:
      return {
        ...state,
        isLoading: false,
        hasSubscribedToNewsletter: false,
        hasSubscriptionErrored: true,
      };
    case types.RESET_NEWSLETTER_SUBSCRIPTION_STATE:
      return {
        ...state,
        hasSubscribedToNewsLetter: false,
        hasSubscriptionErrored: false,
      };
    case types.GET_JOB_LISTINGS:
      return {
        ...state,
        jobListings: {
          ...state.jobListings,
          isLoading: true,
        },
      };
    case types.GET_JOB_LISTINGS_SUCCESS:
      return {
        ...state,
        jobListings: {
          ...state.jobListings,
          isLoading: false,
          data: [...action.payload],
        },
      };
    default:
      return state;
  }
};

export default reducerApp;
