import React from 'react';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';

export interface IQuotePost {
  id: string;
  quote: string;
  attribution: string;
  contentType: string;
}

interface IProps {
  content: IQuotePost;
}

export const QuotePost = ({ content }: IProps) => (
  <div className="QuotePost">
    <ComponentInfo zeplinId="IDEAS01-01a - Quote" id={content.id} isBlogContent />
    <span className="QuotePost-cornerDecoration QuotePost-cornerDecoration--topLeftCorner" />
    <span className="QuotePost-cornerDecoration QuotePost-cornerDecoration--topRightCorner" />
    <span className="QuotePost-cornerDecoration QuotePost-cornerDecoration--bottomRightCorner" />
    <span className="QuotePost-cornerDecoration QuotePost-cornerDecoration--bottomLeftCorner" />
    <span className="QuotePost-icon" />
    <p className="QuotePost-text u-textPrimary">{content.quote}</p>
    <p className="QuotePost-author">{content.attribution}</p>
  </div>
);
