/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { ImageProp, VideoPropType } from 'views/modules/moduleConstants';
import { trackEvent } from 'tools/analytics/track-event';
import { ACTION_EVENT } from 'tools/analytics/constants';
import { VideoControls } from './Controls/Controls';

const FULLSCREEN_CHANGE_EVENTS = [
  'mozfullscreenchange',
  'webkitfullscreenchange',
  'msfullscreenchange',
  'fullscreenchange',
];

const WATCHING_EVENT_THRESHOLD = 5; // video progress (in seconds) until the "watching" event fires

interface IProps {
  shouldForcePause?: boolean;
  video: VideoPropType;
  videoCaptions?: VideoPropType;
  videoPosterImage?: ImageProp;
}

export class VideoPlayer extends Component<IProps> {
  videoEl: any = null; // ideally use HTMLMediaElement as the type (any for now to get things compiling)

  wrapperElRef = React.createRef<HTMLDivElement>();

  isPlaying = false;

  hideControlsTimeout: number | undefined = undefined;

  playEventSent = false;

  watchingEventSent = false;

  ignoreMouseMove = false;

  wasPlayingBeforeForcedPause = false;

  state = {
    isPlaying: false,
    isFullscreen: false,
    volume: 0,
    currentTime: 0,
    totalTime: 0,
    captionsEnabled: true,
    userActive: false,
  };

  componentDidMount() {
    FULLSCREEN_CHANGE_EVENTS.forEach((eventName) => {
      window.addEventListener(eventName, this.handleFullscreenChange);
    });
  }

  componentDidUpdate(previousProps: IProps) {
    const { shouldForcePause } = this.props;
    const { isPlaying } = this.state;

    // Toggle the play state of the video if the "shouldForcePause" prop has changed. This is used to pause the video when the menu opens
    if (previousProps.shouldForcePause && !shouldForcePause && this.wasPlayingBeforeForcedPause) {
      this.videoEl?.play();
    } else if (!previousProps.shouldForcePause && shouldForcePause) {
      this.wasPlayingBeforeForcedPause = isPlaying;
      this.videoEl?.pause();
    }
  }

  componentWillUnmount() {
    if (this.hideControlsTimeout) {
      window.clearTimeout(this.hideControlsTimeout);
    }

    FULLSCREEN_CHANGE_EVENTS.forEach((eventName) => {
      window.removeEventListener(eventName, this.handleFullscreenChange);
    });
  }

  handleFullscreenChange = () => {
    const { isFullscreen } = this.state;

    this.setState({
      isFullscreen: !isFullscreen,
    });
  };

  videoElRefHandler = (videoEl: HTMLVideoElement) => {
    if (videoEl) {
      // React doesn't support this attribute in JSX, so we have to set it this way
      videoEl.setAttribute('disablepictureinpicture', 'true');
      this.videoEl = videoEl;

      this.connectVideoEventListeners(this.videoEl);
    }
  };

  handleTouchStart = () => {
    this.ignoreMouseMove = true;
  };

  handleMouseMove = () => {
    if (!this.ignoreMouseMove) {
      this.handleUserActivity();
    }
  };

  handleClick = () => {
    this.ignoreMouseMove = false;
    this.handleUserActivity();
  };

  handleUserActivity = () => {
    this.setState({
      userActive: true,
    });

    if (this.hideControlsTimeout) {
      window.clearTimeout(this.hideControlsTimeout);
    }

    this.hideControlsTimeout = window.setTimeout(() => {
      this.setState({
        userActive: false,
      });
    }, 2000);
  };

  toggleCaptions = () => {
    const { textTracks } = this.videoEl;

    if (!textTracks || textTracks.length <= 0) {
      return;
    }

    let targetTrack;

    for (let i = 0; i < textTracks.length; i += 1) {
      if (textTracks[i].kind === 'subtitles') {
        targetTrack = textTracks[i];
        break;
      }
    }

    if (!targetTrack) {
      return;
    }

    targetTrack.mode = targetTrack.mode === 'showing' ? 'hidden' : 'showing';
  };

  togglePlaying = () => {
    if (this.videoEl.paused) {
      this.videoEl.play();
    } else {
      this.videoEl.pause();
    }
  };

  toggleMute = () => {
    if (this.videoEl.volume > 0 && !this.videoEl.muted) {
      this.videoEl.volume = 0;
      this.videoEl.muted = true;
    } else {
      this.videoEl.volume = 1;
      this.videoEl.muted = false;
    }
  };

  setCurrentTime = (newTime: number) => {
    this.videoEl.currentTime = Math.max(Math.min(newTime, this.videoEl.duration), 0);
  };

  toggleFullscreen = () => {
    const element = this.wrapperElRef.current;
    const requestFullscreen = element?.requestFullscreen
      // @ts-ignore
      || element?.mozRequestFullScreen
      // @ts-ignore
      || element?.webkitRequestFullscreen
      // @ts-ignore
      || element?.msRequestFullscreen;

    const exitFullscreen = document.exitFullscreen
      // @ts-ignore
      || document.mozCancelFullScreen
      // @ts-ignore
      || document.webkitExitFullscreen
      // @ts-ignore
      || document.msExitFullscreen;

    const fullscreenElement = document.fullscreenElement
      // @ts-ignore
      || document.mozFullscreenElement
      // @ts-ignore
      || document.webkitFullscreenElement
      // @ts-ignore
      || document.msFullscreenElement;

    if (!fullscreenElement && requestFullscreen) {
      requestFullscreen.apply(element);
    } else if (exitFullscreen) {
      exitFullscreen.apply(document);
    } else if (this.videoEl.webkitSupportsFullscreen) {
      // For iOS we have to make the video element itself fullscreen
      this.videoEl.webkitEnterFullscreen();
    }
  };

  play = async () => {
    const { videoEl } = this;
    if (!videoEl) {
      return;
    }

    try {
      const isPaused = videoEl.paused;
      if (isPaused) {
        videoEl.play();
      } else {
        videoEl.pause();
      }

      this.isPlaying = !isPaused;
    } catch (error) {
      console.log('error', error);
      this.isPlaying = false;
    }
  };

  connectVideoEventListeners(videoElement: HTMLVideoElement) {
    const { video } = this.props;

    videoElement.addEventListener('play', () => {
      this.setState({ isPlaying: true });
      this.handleUserActivity();

      if (!this.playEventSent) {
        trackEvent({ label: ACTION_EVENT.VIDEO_PLAY, event: { video: video.title } });
        this.playEventSent = true;
      }
    });

    videoElement.addEventListener('pause', () => {
      this.setState({ isPlaying: false });
    });

    videoElement.addEventListener('timeupdate', () => {
      this.setState({ currentTime: videoElement.currentTime });

      if (!this.watchingEventSent && videoElement.currentTime > WATCHING_EVENT_THRESHOLD) {
        // @ts-ignore
        trackEvent({ label: ACTION_EVENT.VIDEO_WATCHING, event: { video: video.title } });
        this.watchingEventSent = true;
      }
    });

    videoElement.addEventListener('seeking', () => {
      this.setState({ currentTime: videoElement.currentTime });
    });

    videoElement.addEventListener('ended', () => {
      trackEvent({ label: ACTION_EVENT.VIDEO_WATCHED, event: { video: video.title } });
      this.playEventSent = false;
      this.watchingEventSent = false;
    });

    videoElement.addEventListener('volumechange', () => {
      const volume = videoElement.muted ? 0 : videoElement.volume;
      this.setState({ volume });
    });

    videoElement.addEventListener('loadedmetadata', () => {
      this.setState({
        volume: videoElement.volume,
        totalTime: videoElement.duration,
      });
    });

    // Guarding against IE exploding when there are no text tracks
    if (!videoElement.textTracks) {
      return;
    }

    videoElement.textTracks.addEventListener('change', () => {
      const { textTracks } = videoElement;

      for (let i = 0; i < textTracks.length; i += 1) {
        if (textTracks[i].mode === 'showing') {
          this.setState({
            captionsEnabled: true,
          });
          return;
        }
      }

      this.setState({
        captionsEnabled: false,
      });
    });
  }

  render() {
    const { video, videoPosterImage, videoCaptions } = this.props;
    if (!video) {
      return null;
    }

    const {
      isPlaying,
      isFullscreen,
      volume,
      currentTime,
      totalTime,
      captionsEnabled,
      userActive,
    } = this.state;

    const {
      file: { url, contentType },
    } = video;

    let videoPoster;
    if (videoPosterImage && videoPosterImage.file && videoPosterImage.file.url) {
      videoPoster = videoPosterImage.file.url;
    }

    const controlsVisible = userActive || !isPlaying;

    return (
      <div
        className={classnames('VideoPlayer', {
          'VideoPlayer--controlsVisible': controlsVisible,
        })}
        onTouchStart={this.handleTouchStart}
        onMouseMove={this.handleMouseMove}
        onClick={this.handleClick}
        role="none"
        ref={this.wrapperElRef}
      >
        <video
          className="VideoPlayer-player"
          // @ts-ignore This is for hiding the casting button, React doesn't understand disableRemotePlayback
          disableremoteplayback="true"
          ref={this.videoElRefHandler}
          poster={videoPoster}
          crossOrigin="true"
          playsInline
          controls={false}
        >
          <source src={url} type={contentType} />
          {videoCaptions && (
            <track
              kind="subtitles"
              src={videoCaptions.file.url}
              srcLang="en"
              label="English"
              default
            />
          )}
          <p>
            <FormattedMessage id="VIDEO_UNSUPPORTED" />
          </p>
        </video>
        <VideoControls
          className="VideoPlayer-overlay"
          currentTime={currentTime}
          hasCaptions={videoCaptions != null}
          isCaptionsEnabled={captionsEnabled}
          isFullscreen={isFullscreen}
          isVisible={controlsVisible}
          isPlaying={isPlaying}
          onSetCurrentTime={this.setCurrentTime}
          onToggleFullscreen={this.toggleFullscreen}
          onSetMute={this.toggleMute}
          onTogglePlaying={this.togglePlaying}
          onToggleCaptions={this.toggleCaptions}
          totalTime={totalTime}
          volume={volume}
        />
      </div>
    );
  }
}
