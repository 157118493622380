import { async, createTypes } from 'redux-action-creator';

export default createTypes(
  [
    ...async('GET_CASE_STUDY'),
    ...async('GET_HOME_CONTENT'),
    ...async('GET_NOT_FOUND'),
    ...async('GET_OPEN_POSITIONS'),
    ...async('GET_GENERIC_JOB_DESCRIPTION'),
    ...async('GET_OFFICES'),
    ...async('GET_RELATED_CASE_STUDIES'),
    ...async('GET_US_DETAILS'),
    ...async('GET_US_AWARDS'),
    ...async('GET_META'),
    ...async('GET_WORK_PAGE'),
    ...async('GET_SERVICES_PAGE'),
    ...async('GET_CONTENT_VERSION'),
    ...async('GET_IDEAS_FACTORY_CONTENT'),
    ...async('GET_POST'),
    ...async('GET_IDEAS_FACTORY_POSTS'),
    ...async('GET_IDEAS_FACTORY_RELATED_POSTS'),
    ...async('GET_TSUNAMI_READY_PRIVACY_POLICY'),
  ],
  'CMS',
);
