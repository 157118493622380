import { fork, all } from 'redux-saga/effects';
import { appSaga } from './app/sagas';
import { cmsSaga } from './cms/sagas';

export default function* root() {
  yield all([
    fork(cmsSaga),
    fork(appSaga),
  ]);
}
