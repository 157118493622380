import { FeatureFlagActions, TYPES } from './actions';
import { FEATURE_FLAGS } from './constants';

const initialState = FEATURE_FLAGS;

export const featureFlagsReducer = (state = initialState, action: FeatureFlagActions) => {
  switch (action.type) {
    case TYPES.SET_FEATURE_FLAG:
      return {
        ...state,
        [action.payload.featureName]: action.payload.flag,
      };
    default:
      return {
        ...state,
      };
  }
};
