import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-web';
import classNames from 'classnames';

import { ANIMATION_DEFAULTS, EXIT_ANIMATION_DEFAULTS } from 'redux/modules/app/constants';
import { ImagePropPT } from 'views/modules/moduleConstants';
import { handleViewport } from 'views/enhancers/InViewport';
import { prefersReducedMotion } from 'tools/utilities/prefersReducedMotion';

const IN_VIEWPORT_OPTIONS = {
  threshold: 0,
};

export const LOTTIE_CONTENT_TYPE = 'application/json';
export const AnimatorComponent = class AnimatorComponent extends Component {
  animationItem = null;

  constructor(props) {
    super(props);

    this.state = {
      hideFirstAnimation: false,
      wasInViewport: false,
      entryAnimationPlayed: false,
      isLoading: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { inViewport, shouldReset } = nextProps;

    if (inViewport) {
      this.setState({
        wasInViewport: true,
      });
    }

    const { wasInViewport, hideFirstAnimation } = this.state;

    if (wasInViewport && !inViewport && shouldReset) {
      const { animationItem } = this;

      if (hideFirstAnimation) {
        this.setState({
          hideFirstAnimation: false,
        });
      }
      if (animationItem) {
        animationItem.goToAndStop(0);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { animationItem, exitAnimationItem } = this;
    const { shouldPlay, shouldPlayExit } = this.props;
    const { entryAnimationPlayed } = this.state;

    if (prevProps.shouldPlay !== shouldPlay) {
      if (shouldPlay && animationItem) {
        animationItem.play();
      }
    }

    if (prevProps.shouldPlayExit !== shouldPlayExit) {
      if (shouldPlayExit && exitAnimationItem && entryAnimationPlayed) {
        exitAnimationItem.play();
      }
    }

    if (shouldPlayExit && entryAnimationPlayed && exitAnimationItem) {
      exitAnimationItem.play();
    }
  }

  componentWillUnmount() {
    const { animationItem, exitAnimationItem } = this;

    if (animationItem) {
      animationItem.destroy();
    }

    if (exitAnimationItem) {
      exitAnimationItem.destroy();
    }
  }

  initLottie = (el) => {
    if (!el) {
      return;
    }

    const {
      animation, exitAnimation, lottieOptions, shouldLoop,
    } = this.props;
    const { animationItem } = this;

    if (animationItem) {
      return;
    }

    const anim = Lottie.loadAnimation({
      ...ANIMATION_DEFAULTS,
      loop: shouldLoop,
      container: el,
      path: animation.file.url,
      ...lottieOptions,
    });

    // This makes the animationItem render at its start, even if it's not playing.
    anim.gotoFrame(1);

    if (exitAnimation) {
      // When the animation has finished
      anim.addEventListener('complete', () => {
        this.setState({
          hideFirstAnimation: true,
          entryAnimationPlayed: true,
        });
      });
    }

    this.animationItem = anim;
  };

  initExitLottie = (el) => {
    if (!el) {
      return;
    }

    const { exitAnimation, exitLottieOptions } = this.props;
    const { exitAnimationItem } = this;

    if (exitAnimationItem) {
      return;
    }

    const exitAnim = Lottie.loadAnimation({
      ...EXIT_ANIMATION_DEFAULTS,
      ...exitLottieOptions,
      container: el,
      path: exitAnimation.file.url,
    });

    exitAnim.gotoFrame(1);

    this.exitAnimationItem = exitAnim;
  };

  videoEl = (el, reduceMotion) => {
    const { shouldPlay, animation } = this.props;
    if (!el || !el.play) {
      // The element not having a `play` function is a legit possibility - Windows 10 N Edition
      // doesn't come with media support in Edge, so video tags don't exist. Super-duper edge-casey
      return;
    }

    if (shouldPlay && animation && !reduceMotion) {
      const playPromise = el.play();

      // Not all browsers return a promise
      if (playPromise) {
        playPromise
          .then(() => {
            // See corresponding CSS file for why this is needed
            el.classList.add('Animator-video--triggered');
          })
          .catch(() => {
            // The user (or browser) has all autoplay disabled.
            // Show the controls so the user can start the video if they want to
            // eslint-disable-next-line no-param-reassign
            el.controls = true;
          });
      }
    }
  };

  handleWaiting = () => {
    this.setState({
      isLoading: true,
    });
  }

  handlePlaying = () => {
    this.setState({
      isLoading: false,
    });
  }

  accessibilityProps = () => {
    const { alt } = this.props;

    return alt ? {
      'role': 'img',
      'aria-label': alt,
    } : { 'aria-hidden': 'true' };
  }

  render() {
    const { animation, exitAnimation, shouldLoop } = this.props;
    const { isLoading } = this.state;
    const reduceMotion = prefersReducedMotion();

    if (!animation || !animation.file) {
      return null;
    }

    const { hideFirstAnimation } = this.state;
    let body = null;

    const shouldHideEntryAnimation = exitAnimation && hideFirstAnimation;

    switch (animation.file.contentType) {
      case LOTTIE_CONTENT_TYPE:
        body = (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div className="Animator-container" {...this.accessibilityProps()}>
            <div
              className={classNames('Animator-lottie', { 'is-hidden': shouldHideEntryAnimation })}
              ref={(el) => this.initLottie(el)}
            />

            {exitAnimation && (
              <div
                className={classNames('Animator-exitLottie', {
                  'is-showing': shouldHideEntryAnimation,
                })}
                ref={(el) => this.initExitLottie(el)}
              />
            )}
          </div>
        );
        break;
      // Assume that it is a video
      default:
        body = (
          <>
            {isLoading && <hr className="FancyRule FancyRule--video" aria-hidden />}
            <video
              muted
              loop={shouldLoop}
              preload="auto"
              playsInline
              ref={(el) => this.videoEl(el, reduceMotion)}
              className="Animator-video"
              controls={reduceMotion}
              onWaiting={this.handleWaiting}
              onWaitingCapture={this.handleWaiting}
              onCanPlayThrough={this.handlePlaying}
              onPlaying={this.handlePlaying}
              disableremoteplayback="true"
            >
              <source src={animation.file.url} type={animation.file.contentType} />
            </video>
          </>
        );
        break;
    }

    return (
      <div className={classNames('Animator', { 'Animator--hasExitLottie': exitAnimation })}>
        {body}
      </div>
    );
  }
};

AnimatorComponent.propTypes = {
  alt: PropTypes.string.isRequired,
  animation: ImagePropPT,
  exitAnimation: ImagePropPT,
  exitLottieOptions: PropTypes.shape({}),
  inViewport: PropTypes.bool.isRequired,
  lottieOptions: PropTypes.shape({
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
  }),
  shouldLoop: PropTypes.bool,
  shouldPlay: PropTypes.bool,
  shouldPlayExit: PropTypes.bool,
  shouldReset: PropTypes.bool,
};

AnimatorComponent.defaultProps = {
  shouldPlay: false,
  shouldPlayExit: false,
  animation: null,
  exitAnimation: null,
  lottieOptions: {},
  exitLottieOptions: {},
  shouldLoop: false,
  shouldReset: false,
};

export const Animator = handleViewport(AnimatorComponent, IN_VIEWPORT_OPTIONS);
