import { useEffect, useState } from 'react';

export const useHasPostBeenRead = (postId) => {
  const [hasBeenRead, setHasBeenRead] = useState(false);

  useEffect(() => {
    const isRead = !!window.localStorage.getItem(`${postId}`);

    setHasBeenRead(isRead);
  }, [postId]);

  return hasBeenRead;
};
