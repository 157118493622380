import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IS_DEVELOPMENT } from 'redux/modules/app/constants';
import { ModuleNotFound } from 'views/components/ModuleNotFound/ModuleNotFound';

/*
  CustomModules are created for unique cases.

  = Creating a custom module =
  1. Create the new module and give it an appropriate name, e.g. LinesToGrid
  2. Find the content type 'Module - Case Study - Custom' in the CMS.
  3. Add the file name (LinesToGrid) to the 'Custom Module Name' list in the CMS
      settings > validations
  4. Create a new CMS content entry with an appropriate name
      e.g. Energy Sector - Custom Module - LinesToGrid
  5. Select the desired 'Custom Module Name' from the list
  6. Add the new Custom Module to the CaseStudy
  6. Done! You're new custom module should appear in that case study
*/

export class Custom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      module: null,
    };
  }

  async componentDidMount() {
    const { customModuleName } = this.props;

    try {
      // Dynamically import the desired custom component
      const module = await import(`./${customModuleName}`);

      this.setState({
        module: module[customModuleName], // The modules need to use a named export
      });
    } catch (error) {
      console.error('error', error);

      if (IS_DEVELOPMENT) {
        this.setState({
          module:  () => <ModuleNotFound name={`Custom Module Not Found: ${customModuleName}`} />,
        });
      }
    }
  }

  render() {
    const { module: Module } = this.state;

    if (Module) {
      return <Module />;
    }

    return null;
  }
}

Custom.propTypes = {
  customModuleName: PropTypes.string.isRequired,
};
