// These are CaseStudy interfaces. They can be imported and used in
// your component.propTypes definitions
import PropTypes from 'prop-types';

interface File {
  details: {
    size: number;
    image: {
      width: number;
      height: number;
    };
  };
  fileName: string;
  url: string;
  contentType: string;
}

export const FilePT = PropTypes.shape({
  contentType: PropTypes.string.isRequired,
  details: PropTypes.shape({
    image: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    size: PropTypes.number.isRequired,
  }),
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export interface ImageProp {
  description?: string;
  file: File;
  id?: string;
  title?: string;
}

export const ImagePropPT = PropTypes.shape({
  description: PropTypes.string,
  file: FilePT,
  id: PropTypes.string,
  title: PropTypes.string,
});

export type AnimationProp = ImageProp;

interface VideoFileDetails {
  size: number; // File size in bytes - 10498677
}
interface VideoFile {
  contentType: string; // video/mp4
  details: VideoFileDetails;
  fileName: string; // SampleVideo_1280x720_10mb.mp4
  url: string; // //videos.ctfassets.net/3x3jig8tn8ag/9gyO8bR1F8...
}

const VideoFileDetailsPT = PropTypes.shape({
  size: PropTypes.number, // File size in bytes - 10498677
});

const VideoFilePT = PropTypes.shape({
  contentType: PropTypes.string, // video/mp4
  details: VideoFileDetailsPT,
  fileName: PropTypes.string, // SampleVideo_1280x720_10mb.mp4
  url: PropTypes.string, // //videos.ctfassets.net/3x3jig8tn8ag/9gyO8bR1F8...
});

export interface VideoPropType {
  description: string; // This is for testing purposes only.
  file: VideoFile;
  id: string;
  title: string; // Sample Video
}

export const VideoPropTypePT = PropTypes.shape({
  description: PropTypes.string, // This is for testing purposes only.
  file: VideoFilePT,
  id: PropTypes.string,
  title: PropTypes.string,
});

export interface AwardProp {
  category?: string; // "Interactive/Application"
  contentType: string; // "awards"
  customType?: string;
  icon: ImageProp;
  id: string;
  modules?: any;
  name: string; // "2degrees - Best Award Gold 2018"
  outcome?: string; // "Gold"
  project: string; // "2 degrees app"
  type: string; // "Best Award"
  video?: VideoPropType;
  year: string; // "2018"
}

export const AwardPropPT = PropTypes.shape({
  category: PropTypes.string, // "Interactive/Application"
  contentType: PropTypes.string.isRequired, // "awards"
  customType: PropTypes.string,
  icon: ImagePropPT,
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  modules: PropTypes.any,
  name: PropTypes.string.isRequired, // "2degrees - Best Award Gold 2018"
  outcome: PropTypes.string, // "Gold"
  project: PropTypes.string.isRequired, // "2 degrees app"
  type: PropTypes.string.isRequired, // "Best Award"
  video: VideoPropTypePT,
  year: PropTypes.string.isRequired, // "2018"
});

export interface CaseStudyProp {
  contentType?: string;
  featuredCaseStudyImage?: ImageProp;
  foregroundAnimation?: AnimationProp;
  foregroundImage?: ImageProp;
  gallery?: ImageProp[];
  id: string;
  isLightTheme?: boolean;
  isLightThemeHome?: boolean;
  modules?: any;
  relatedCaseStudyText?: string;
  relatedCaseStudyImage?: ImageProp;
  subtitle?: string;
  tileImage?: ImageProp;
  tileShape?: ImageProp;
  title?: string;
  url: string;
  yearEnd?: number;
  yearStart?: number;
  spotlightCaseStudyImage?: ImageProp;
  spotlightCaseStudyImageMobile?: ImageProp;
  spotlightBanner?: string;
  spotlightCaseStudyText?: string;
  metaTitle?: string;
  metaDescription?: string;

  /* Sector only (currently Energy Sector is the only one, but there might be others) */
  secondImage?: ImageProp;
}

export interface DataProp {
  content: any;
}

export const DataPropPT = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.any,
});
