import React, { Component } from 'react';
import _ from 'lodash';

const DEFAULT_DEBOUNCE = 100;

function BrowserSizeProvider(
  WrappedComponent,
  shouldDebounce = true,
  debounceTime = DEFAULT_DEBOUNCE,
) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        windowWidth: 0,
        windowHeight: 0,
      };
    }

    componentDidMount() {
      this.updateSizes();

      if (shouldDebounce) {
        this.resizeHandler = _.debounce(() => {
          this.updateSizes();
        }, debounceTime);
      } else {
        this.resizeHandler = this.updateSizes();
      }

      window.addEventListener('resize', this.resizeHandler);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resizeHandler);
    }

    updateSizes() {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }

    render() {
      const { windowHeight, windowWidth } = this.state;

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WrappedComponent {...this.props} windowWidth={windowWidth} windowHeight={windowHeight} />
      );
    }
  };
}

export default BrowserSizeProvider;
