import React from 'react';

import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { ImageWrapper } from 'views/components/ImageWrapper/ImageWrapper';
import { ImageProp } from 'views/modules/moduleConstants';

export interface Props {
  id: string;
  subtitle?: string;
  title: string;
  icons: ImageProp[];
}

export const GridModule = (props: Props) => {
  const {
    id, title, subtitle = '', icons,
  } = props;

  const iconCount = icons.length;

  let zeplinId = 'GRD01-01a';
  let iconItemClasses = 'CaseStudy-grid-item u-sm-width5of12';
  let iconsContainerClasses = 'CaseStudy-grid-items Grid-cell u-md-width4of12 u-md-before2of12 CaseStudy-grid-items--grid';

  if (iconCount < 4) {
    zeplinId = 'GRD01-01b';
    iconItemClasses = 'CaseStudy-grid-item Grid-cell u-sm-width5of12 u-md-width4of12';
    iconsContainerClasses = 'CaseStudy-grid-items Grid u-md-width6of12 u-md-before1of12 CaseStudy-grid-items--row';
  }

  return (
    <section className="CaseStudy-grid CaseStudy-grid--iconsRight ModuleWrapper">
      <div className="Container CaseStudy-grid-container">
        <ComponentInfo id={id} zeplinId={zeplinId} />
        <div className="CaseStudy-grid-content Grid Grid--alignMiddle">
          <div className="Grid-cell u-md-width5of12">
            <p className="h4 u-mT-0">{title}</p>
            <p>{subtitle}</p>
          </div>
          <ul className={iconsContainerClasses}>
            {icons.map((icon) => (
              <li key={icon.id} className={iconItemClasses}>
                <div className="CaseStudy-grid-icon">
                  {/* empty alt text as the description is provided below anyway */}
                  <ImageWrapper url={icon.file.url} isSquare altText="" />
                </div>
                <p className="CaseStudy-grid-description">{icon.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
