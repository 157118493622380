import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'views/components/Icon/Icon';
import { formatDuration } from 'tools/utilities/formatDuration';

const supportsPointers = window.PointerEvent != null;

export class VideoControls extends Component {
  wasPlaying = false;

  isPointerDown = false;

  progressBarRef = React.createRef();

  handleAreaClick = () => {
    const { onTogglePlaying, isVisible } = this.props;

    if (isVisible) {
      onTogglePlaying();
    }
  };

  handleProgressKeyDown = (keyEvent) => {
    const { currentTime, onSetCurrentTime } = this.props;

    switch (keyEvent.key) {
      case 'ArrowUp':
        onSetCurrentTime(currentTime + 10);
        keyEvent.preventDefault();
        break;
      case 'ArrowDown':
        onSetCurrentTime(currentTime - 10);
        keyEvent.preventDefault();
        break;
      default:
        break;
    }
  };

  handleProgressMouseDown = (event) => {
    if (this.isPointerDown) {
      return;
    }

    const { isPlaying, onTogglePlaying } = this.props;

    this.isPointerDown = true;

    this.wasPlaying = isPlaying;

    if (this.wasPlaying) {
      onTogglePlaying();
    }

    if (supportsPointers) {
      this.progressBarRef.current.setPointerCapture(event.pointerId);
    } else {
      window.addEventListener('mouseup', this.handleProgressMouseUp);
      window.addEventListener('mousemove', this.handleProgressMouseMove);
    }
  };

  handleProgressMouseMove = (event) => {
    if (!this.isPointerDown) {
      return;
    }

    const clickX = event.clientX || event.changedTouches[0].clientX;
    const clickPercent = this.clickPositionToProgressPercent(clickX);

    const { totalTime, onSetCurrentTime } = this.props;

    onSetCurrentTime(totalTime * clickPercent);
  };

  handleProgressMouseUp = (event) => {
    if (!this.isPointerDown) {
      return;
    }

    this.isPointerDown = false;

    window.removeEventListener('mouseup', this.handleProgressMouseUp);
    window.removeEventListener('mousemove', this.handleProgressMouseMove);

    const clickX = event.clientX || event.changedTouches[0].clientX;
    const clickPercent = this.clickPositionToProgressPercent(clickX);

    const {
      totalTime, onSetCurrentTime, onTogglePlaying, isPlaying,
    } = this.props;

    if (this.wasPlaying && !isPlaying) {
      onTogglePlaying();
    }

    onSetCurrentTime(totalTime * clickPercent);
  };

  clickPositionToProgressPercent(clickX) {
    const barRect = this.progressBarRef.current.getBoundingClientRect();

    const relativeClickX = clickX - barRect.left;
    const clickPercent = Math.max(0, Math.min(1, relativeClickX / barRect.width));
    return clickPercent;
  }

  render() {
    const {
      className,
      currentTime,
      hasCaptions,
      isCaptionsEnabled,
      isFullscreen,
      isPlaying,
      isVisible,
      onToggleFullscreen,
      onToggleCaptions,
      onSetMute,
      onTogglePlaying,
      totalTime,
      volume,
    } = this.props;

    const progressPercent = (currentTime / totalTime) * 100;

    const volumeButton = (extraButtonClasses = '') => {
      const classes = classnames('VideoControls-button', extraButtonClasses);

      return (
        <button className={classes} type="button" onClick={onSetMute}>
          <Icon name={volume > 0 ? 'volume' : 'mute'} className="VideoControls-buttonIcon" />
          <FormattedMessage id={volume > 0 ? 'MUTE' : 'UNMUTE'}>
            {(txt) => <span className="u-hiddenVisually">{txt}</span>}
          </FormattedMessage>
        </button>
      );
    };

    const fullscreenButton = (extraButtonClasses = '') => {
      const classes = classnames('VideoControls-button', extraButtonClasses);

      return (
        <button className={classes} type="button" onClick={onToggleFullscreen}>
          <Icon name={isFullscreen ? 'collapse' : 'expand'} className="VideoControls-buttonIcon" />
          <FormattedMessage id={isFullscreen ? 'EXIT_FULLSCREEN' : 'ENTER_FULLSCREEN'}>
            {(txt) => <span className="u-hiddenVisually">{txt}</span>}
          </FormattedMessage>
        </button>
      );
    };

    return (
      <div
        className={classnames(
          'VideoControls',
          {
            'VideoControls--hidden': !isVisible,
          },
          className,
        )}
      >
        <div className="VideoControls-topControls">
          {fullscreenButton('VideoControls-button--pill')}
          {volumeButton('VideoControls-button--pill')}
        </div>
        <div className="VideoControls-clickHandler" onClick={this.handleAreaClick} role="none" />
        <div className="VideoControls-bar">
          <button className="VideoControls-button" type="button" onClick={onTogglePlaying}>
            <Icon name={isPlaying ? 'pause' : 'play-small'} className="VideoControls-buttonIcon" />
            <FormattedMessage id={isPlaying ? 'PAUSE' : 'PLAY'}>
              {(txt) => <span className="u-hiddenVisually">{txt}</span>}
            </FormattedMessage>
          </button>
          <div className="VideoControls-time">
            <span>{formatDuration(currentTime)}</span>/<span>{formatDuration(totalTime)}</span>
          </div>

          <div
            className="VideoProgressControl"
            ref={this.progressBarRef}
            // Use pointer events in browsers that support them
            onPointerDown={supportsPointers ? this.handleProgressMouseDown : null}
            onPointerMove={supportsPointers ? this.handleProgressMouseMove : null}
            onPointerUp={supportsPointers ? this.handleProgressMouseUp : null}
            // pointer events not supported, add mouse-based events instead
            onMouseDown={!supportsPointers ? this.handleProgressMouseDown : null}
            onTouchStart={!supportsPointers ? this.handleProgressMouseDown : null}
            onTouchMove={!supportsPointers ? this.handleProgressMouseMove : null}
            onTouchEnd={!supportsPointers ? this.handleProgressMouseUp : null}
            role="progressbar"
            aria-label="Video progress"
            aria-valuenow={progressPercent}
            aria-valuemin="0"
            aria-valuemax="100"
            tabIndex="0"
            onKeyDown={this.handleProgressKeyDown}
          >
            <div
              className="VideoProgressControl-progress"
              style={{
                width: `${progressPercent}%`,
              }}
            />
          </div>

          {volumeButton('u-sm-hidden')}

          {hasCaptions && (
            <button className="VideoControls-button" type="button" onClick={onToggleCaptions}>
              <Icon
                name={isCaptionsEnabled ? 'subtitles' : 'nosubtitles'}
                className="VideoControls-buttonIcon"
              />
              <FormattedMessage id={isCaptionsEnabled ? 'DISABLE_CAPTIONS' : 'ENABLE_CAPTIONS'}>
                {(txt) => <span className="u-hiddenVisually">{txt}</span>}
              </FormattedMessage>
            </button>
          )}

          {fullscreenButton('u-sm-hidden')}
        </div>
      </div>
    );
  }
}

VideoControls.propTypes = {
  className: PropTypes.string,
  currentTime: PropTypes.number.isRequired,
  hasCaptions: PropTypes.bool.isRequired,
  isCaptionsEnabled: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSetCurrentTime: PropTypes.func.isRequired,
  onSetMute: PropTypes.func.isRequired,
  onToggleCaptions: PropTypes.func.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  onTogglePlaying: PropTypes.func.isRequired,
  totalTime: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
};

VideoControls.defaultProps = {
  className: '',
};
