import React from 'react';
import { PropTypes } from 'prop-types';
import { ImageWrapper } from 'views/components/ImageWrapper/ImageWrapper';
import { ResponsiveCmsImageCreator } from 'views/enhancers/ResponsiveCmsImageCreator';

/**
 * Thin wrapper around ImageWrapper that modifies the provided image URL so that an appropriately
 * sized image is requested from Contentful. Accepts all the same props as ImageWrapper.
 */
export const ResponsiveCmsImageWrapper = (props) => {
  const { url } = props;

  return (
    <ResponsiveCmsImageCreator imageUrl={url}>
      {({ imageUrlWithSize, ref }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ImageWrapper {...props} url={imageUrlWithSize} elementRef={ref} />
      )}
    </ResponsiveCmsImageCreator>
  );
};

ResponsiveCmsImageWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  ...ImageWrapper.propTypes,
};
