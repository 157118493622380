import React from 'react';

import { ResponsiveCmsImageCreator } from 'views/enhancers/ResponsiveCmsImageCreator';
import { BlurUpImageLoader } from 'views/components/BlurUpImageLoader/BlurUpImageLoader';
import { LargieSmalls } from 'views/enhancers/LargieSmalls';
import { ImageProp } from 'views/modules/moduleConstants';

const smallClipPath = (
  <path
    d="
    M0 0
    L1 0.11
    L1 0.89
    L0 1
      Z
    "
  />
);

const largeClipPath = (
  <path
    d="
    M0 0
    L1 0.25
    L1 0.75
    L0 1
      Z
    "
  />
);

interface IProps {
  largeImage?: ImageProp;
  smallImage?: ImageProp;
  onPreviewReady?: () => void;
}

export const HeroBannerBackground = (props: IProps) => {
  const { largeImage, smallImage } = props;

  const largeImageUrl = largeImage?.file.url;
  const smallImageUrl = smallImage?.file.url;

  const mobileAspectRatio = smallImage && smallImage.file
    ? (smallImage.file.details.image.width / smallImage.file.details.image.height) : 1;

  return (
    <LargieSmalls
      mobile={(
        <ResponsiveCmsImageCreator imageUrl={smallImageUrl}>
          {({ imageUrlWithSize, ref }: any) => (
            <BlurUpImageLoader
              elementRef={ref}
              aspectRatio={mobileAspectRatio}
              fullImage={imageUrlWithSize}
              previewImage={`${smallImageUrl}?w=10`}
              clipPathContent={smallClipPath}
              preserveAspectRatio="xMidYMid slice"
              blurSize={0.15}
              {...props}
            />
          )}
        </ResponsiveCmsImageCreator>
        )}
      tablet={(
        <ResponsiveCmsImageCreator imageUrl={largeImageUrl}>
          {({ imageUrlWithSize, ref }: any) => (
            <BlurUpImageLoader
              elementRef={ref}
              fullImage={imageUrlWithSize}
              previewImage={`${largeImageUrl}?w=10`}
              clipPathContent={largeClipPath}
              {...props}
            />
          )}
        </ResponsiveCmsImageCreator>
      )}
    />
  );
};
