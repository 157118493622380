import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useHistory } from 'react-router';

import types from 'redux/modules/app/types';
import { Icon } from 'views/components/Icon/Icon';

/**
 * Workaround for issue with `formatContent` in utilities/cms
 * Have a look at cms/sagas.js for clarification.
 */
export interface ITag {
  fields?: {
    name: string;
  };
  name?: string;
}

interface IProps {
  hasRemoveIcon?: boolean;
  isInArticle?: boolean;
  shouldScrollToTopOnFilter?: boolean;
  tags?: ITag[];
}

export const Tags = ({
  hasRemoveIcon = false,
  isInArticle,
  tags,
  shouldScrollToTopOnFilter = false,
}: IProps) => {
  const currentPostFilters = useSelector(
    (state: any) => state.app.ideasFactoryPostFilters,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (
    event: any,
    tag: ITag,
    isRemovable: boolean,
    isSelected: boolean,
  ) => {
    event.preventDefault(); // Block the route change from the parent <TrackableLink />

    let action;

    if (isRemovable) {
      // Remove the filter
      action = types.REMOVE_POST_FILTER;
    } else {
      // If the filter has already been added do nothing
      action = isSelected ? null : types.ADD_POST_FILTER;
    }

    if (action) {
      dispatch({ type: action, payload: { tag } });
    }

    // Sometimes the tag is quite far down the page when selected,
    // this helps it feel less janky when the featured modules get removed
    if (action === types.ADD_POST_FILTER && shouldScrollToTopOnFilter) {
      window.scrollTo(0, 0);
    }

    if (isInArticle) {
      // Take the user back to the Ideas Factory landing page
      history.push('/');
    }
  };

  const tagsClass = classnames('Tags', {
    'u-mB-0': hasRemoveIcon,
    'u-mB-sm': !hasRemoveIcon,
  });

  return (
    <ul className={tagsClass}>
      {tags &&
        tags.map((tag: ITag) => {
          /**
           * cms.formatContent workaround
           */
          const tagName = tag.fields ? tag.fields.name : tag.name;

          const isSelected = currentPostFilters.some(
            (filter: ITag) => filter.fields && filter.fields.name === tagName,
          );

          const tagsItemClass = classnames('Tags-item u-mB-0', {
            'is-removable': hasRemoveIcon,
          });

          const tagItemLabelClass = classnames('Tag-item-label', {
            'is-removable u-textNoWrap': hasRemoveIcon,
            'u-mR-sm': !hasRemoveIcon,
          });

          return (
            <li className={tagsItemClass} key={tagName}>
              <button
                className="u-flex u-flexAlignItemsCenter u-textNoWrap u-mT-0 u-mB-0"
                type="button"
                onClick={(event) =>
                  handleClick(event, tag, hasRemoveIcon, isSelected)
                }
              >
                <span className={tagItemLabelClass}>#{tagName}</span>
                {hasRemoveIcon && (
                  <Icon name="close-grey" className="Tag-item-label-close" />
                )}
              </button>
            </li>
          );
        })}
    </ul>
  );
};
