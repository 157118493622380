import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const renderFunctionOrComponent = (component) => {
  if (typeof component === 'function') {
    return component();
  }
  return component;
};

/**
 * LargieSmalls is used to render a component at those specific breakpoints.
 * It works top down, for example, if you provide mobile and tablet props,
 * then the mobile component will render at mobile and the tablet component will render
 * at tablet and above.
 *
 * LargieSmalls works exactly the same as SCSS media queries.
 * mobile < tablet < laptop < desktop < widescreen
 *
 * @param {Element || Function} mobile - renders at mobile breakpoint
 * @param {Element || Function} tablet - renders at tablet breakpoint
 * @param {Element || Function} laptop - renders at laptop breakpoint
 * @param {Element || Function} desktop - renders at desktop breakpoint
 * @param {Element || Function} wideScreen - renders at wideScreen breakpoint
 */
export const LargieSmallsPresenter = (props) => {
  const {
    isViewportMobile,
    isViewportTablet,
    isViewportDesktop,
    isViewportWidescreen,
    isViewportLaptop,
    mobile,
    tablet,
    laptop,
    wideScreen,
    desktop,
  } = props;

  // Mobile - BREAKPOINTS.MOBILE - w <= 767
  if (mobile && isViewportMobile) {
    return renderFunctionOrComponent(mobile);
  }

  // Tablet - BREAKPOINTS.TABLET - 768 >= w < 1024
  if (tablet && isViewportTablet) {
    return renderFunctionOrComponent(tablet);
  }
  // Fallback to mobile if it exists since it's Mobile - Tablet
  if (!tablet && isViewportTablet && mobile) {
    return renderFunctionOrComponent(mobile);
  }

  // Laptop - BREAKPOINTS.LAPTOP - 1025 >= w < 1279
  if (laptop && isViewportLaptop) {
    return renderFunctionOrComponent(laptop);
  }
  // Mobile - Tablet - Laptop
  if (!laptop && isViewportLaptop && (tablet || mobile)) {
    return renderFunctionOrComponent(tablet) || renderFunctionOrComponent(mobile);
  }

  // Desktop - BREAKPOINTS.DESKTOP - 1280 >= w < 1599
  if (desktop && isViewportDesktop) {
    return renderFunctionOrComponent(desktop);
  }
  // Mobile - Tablet - Laptop - Desktop
  if (!desktop && isViewportDesktop && (laptop || tablet || mobile)) {
    return renderFunctionOrComponent(laptop) || renderFunctionOrComponent(tablet) || renderFunctionOrComponent(mobile);
  }

  // Widescreen - BREAKPOINTS.WIDESCREEN - w >= 1600
  if (wideScreen && isViewportWidescreen) {
    return renderFunctionOrComponent(wideScreen);
  }

  // Fallback
  return renderFunctionOrComponent(wideScreen)
    || renderFunctionOrComponent(desktop)
    || renderFunctionOrComponent(laptop)
    || renderFunctionOrComponent(tablet)
    || renderFunctionOrComponent(mobile);
};

LargieSmallsPresenter.propTypes = {
  desktop: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isViewportDesktop: PropTypes.bool, // Props from connectors
  isViewportLaptop: PropTypes.bool, // Props from connectors
  isViewportMobile: PropTypes.bool, // Props from connectors
  isViewportTablet: PropTypes.bool, // Props from connectors
  isViewportWidescreen: PropTypes.bool, // Props from connectors
  laptop: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  mobile: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  tablet: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  wideScreen: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

LargieSmallsPresenter.defaultProps = {
  mobile: null,
  tablet: null,
  wideScreen: null,
  laptop: null,
  desktop: null,
};

const mapStateToProps = (state) => ({
  isViewportMobile: state.browser.is.mobile,
  isViewportTablet: state.browser.is.tablet,
  isViewportLaptop: state.browser.is.laptop,
  isViewportDesktop: state.browser.is.desktop,
  isViewportWidescreen: state.browser.is.widescreen,
});

export const LargieSmalls = connect(
  mapStateToProps,
  null,
)(LargieSmallsPresenter);
