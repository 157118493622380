import { createSelector } from 'reselect';

export const isLoading = (state) => state.cms.isLoading;

export const getCaseStudy = (state) => {
  const { cms } = state;

  if (!cms?.caseStudy) {
    return null;
  }

  return cms.caseStudy;
};

export const getCaseStudyID = createSelector(
  [getCaseStudy], (caseStudy) => (caseStudy && caseStudy.data ? caseStudy.data.id : null),
);

export const getHomeContent = (state) => {
  const { cms } = state;

  if (!cms?.homeContent) {
    return null;
  }

  return cms.homeContent;
};

export const getOpenPositions = (state) => {
  if (!state.cms?.openPositions) {
    return null;
  }

  return state.cms.openPositions;
};

export const getGenericJobDescription = (state) => {
  if (!state.cms?.genericJobDescription?.data || !state.cms.genericJobDescription.data[0]) {
    return null;
  }

  return state.cms.genericJobDescription;
};

export const getOffices = (state) => state.cms?.offices?.data ?? null;

export const getRelatedCaseStudies = (state) => {
  if (
    !state.cms?.relatedCaseStudies?.data
    || !Object.keys(state.cms.relatedCaseStudies.data).length
  ) {
    return null;
  }

  return state.cms.relatedCaseStudies.data;
};

export const getUsDetails = (state) => {
  if (!state.cms?.usDetails) {
    return null;
  }
  return state.cms.usDetails;
};

export const getUsAwards = (state) => {
  if (!state.cms?.awards?.data) {
    return [];
  }

  return state.cms.awards.data;
};

export const getMeta = (state) => {
  if (!state.cms?.meta?.data) {
    return {};
  }

  return state.cms.meta.data;
};

export const getWorkPage = (state) => {
  if (!state.cms?.workPage) {
    return {};
  }

  return state.cms.workPage;
};

export const getServices = (state) => {
  if (!state.cms?.services) {
    return {};
  }

  return state.cms.services.data;
};

export const getNotFound = (state) => {
  if (!state.cms?.notFound) {
    return {};
  }

  return state.cms.notFound;
};

export const getFromCache = (state, contentType, filter) => {
  // Ignore if no filter is provided - the cache isn't set up to handle requests that return
  // multiple values, which is what having no filter indicates. This is robust enough for the
  // current Alphero site, but may not  be suitable for other use cases.
  if (!filter || Object.keys(filter).length <= 0
    || !state.cms?.includesCache?.Entry) {
    return null;
  }

  const cache = state.cms.includesCache.Entry;

  const cachedEntry = cache.find((entry) => {
    if (entry.sys.contentType.sys.id !== contentType) {
      return false;
    }

    const filterKeys = Object.keys(filter);
    const filtersMatch = filterKeys.every((key) => {
      const entryKeyValue = key.split('.').reduce((a, b) => a[b], entry);
      return entryKeyValue === filter[key];
    });

    return filtersMatch;
  });

  return cachedEntry ? {
    items: [cachedEntry],
    includes: state.cms.includesCache,
  } : null;
};

export const getContentVersion = (state) => state.cms.contentVersion;

export const getIdeasFactoryContent = (state) => {
  if (!state.cms.ideasFactoryModules) {
    return null;
  }

  return state.cms.ideasFactoryModules;
};

export const isLoadingModules = (state) => state.cms.isLoadingPosts;

export const getPost = (state) => {
  if (!state.cms.post) {
    return null;
  }

  return state.cms.post;
};

export const isLoadingPost = (state) => state.cms.isLoadingPost;

export const getRelatedPosts = (state) => {
  if (!state.cms.ideasFactoryRelatedPosts?.length) {
    return [];
  }

  return state.cms.ideasFactoryRelatedPosts[0].fields.relatedPosts;
};

export const getError = (state) => state.cms.error;

export const getTsunamiReadyPrivacyPolicy = (state) => {
  if (!state.cms.tsunamiReadyPrivacyPolicy || !state.cms.tsunamiReadyPrivacyPolicy.length) {
    return null;
  }

  return state.cms.tsunamiReadyPrivacyPolicy[0];
};
