import React from 'react';

import { ImageProp, VideoPropType } from 'views/modules/moduleConstants';
import { VideoPlayer } from 'views/components/VideoPlayer/VideoPlayer';
import { YouTubeVideo } from 'views/components/YouTubeVideo/YouTubeVideo';

interface Props {
  moduleData: {
    id: string;
    name?: string;
    video?: VideoPropType;
    videoPosterImage?: ImageProp;
    videoCaptions?: VideoPropType;
    youtubeVideoUrl?: string;
  };
}

/**
 * VideoWithCaptions can take either a video file from
 * contentful (with a poster image and captions file)
 * OR
 * it can take a YouTube video url (either embedded taken from the iFrame or
 * via the browser URL link)
 * and renders a video component
 *
 * @param props moduleData passed in by a RichText Block
 */
export const VideoWithCaptions = (props: Props) => {
  const {
    moduleData: {
      name,
      video,
      videoPosterImage,
      videoCaptions,
      youtubeVideoUrl,
    },
  } = props;

  if (youtubeVideoUrl) {
    return <YouTubeVideo name={name} youTubeVideoUrl={youtubeVideoUrl} />;
  }

  if (video) {
    return (
      <VideoPlayer video={video} videoPosterImage={videoPosterImage} videoCaptions={videoCaptions} />
    );
  }

  return null;
};
