import { connect } from 'react-redux';

import * as actionsCMS from 'redux/modules/cms/actions';
import { getNotFound } from 'redux/modules/cms/selectors';

import NotFound from 'views/pages/NotFound/NotFound';

const mapStateToProps = (state) => ({
  page: getNotFound(state),
});

const mapDispatchToProps = (dispatch) => ({
  getNotFound(id) {
    return dispatch(actionsCMS.getNotFound({ id }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFound);
