export const CONTENTFUL_SETTINGS = {
  space: process.env.REACT_APP_CONTENTFUL_SPACE,

  // @TODO Add this to the build scripts so the accessToken is changed based on environment builds

  // Content Delivery API - Prod
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  // Content Preview API - Draft
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,

  // Preview token and host - only available in dev - see client/.env file
  previewAccessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  blogPreviewAccessToken: process.env.REACT_APP_CONTENTFUL_BLOG_PREVIEW_ACCESS_TOKEN,
  host: process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST,

  // Blog will live in a separate space
  blogAccessToken: process.env.REACT_APP_CONTENTFUL_BLOG_ACCESS_TOKEN,
  blogSpace: process.env.REACT_APP_CONTENTFUL_BLOG_SPACE,
  blogEnvironment: process.env.REACT_APP_CONTENTFUL_BLOG_ENVIRONMENT,
};

export const IDEAS_FACTORY_MODULE_TYPES = {
  POST_COLLAGE: 'ideasFactoryModulePostCollage',
  COLLECTION: 'ideasFactoryModuleCollection',
  CTA: 'ideasFactoryModuleCta',
  HIGHLIGHT: 'ideasFactoryModuleHighlight',
  MASONRY: 'ideasFactoryModuleMasonry',
  QUOTE: 'ideasFactoryModuleQuote',
};

export const IDEAS_FACTORY_CONTENT_TYPES = {
  IDEAS_FACTORY_POSTS: 'ideasFactoryPosts',
  IDEAS_FACTORY_CALL_TO_ACTION: 'ideasFactoryCallToAction',
  IDEAS_FACTORY_QUOTE: 'ideasFactoryQuote',
  IDEAS_FACTORY_LINK: 'ideasFactoryTileLink',
};

export const IDEAS_FACTORY_POST_TYPES = {
  OPINION: 'Opinion',
  SNAPSHOT: 'Snapshot',
  EXPERIMENT: 'Experiment',
  ARTICLE: 'Article',
};
