import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Footer } from 'views/components/Footer/Footer';

export const DefaultLayout = (props) => {
  const { isLoading, children } = props;

  const classnames = classNames('Main', {
    'is-loading': isLoading,
  });

  return (
    <>
      <main className={classnames} data-loaded={!isLoading} aria-hidden={isLoading}>
        {children}
      </main>
      {!isLoading && <Footer />}
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isLoading: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  isLoading: false,
  children: null,
};
