import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

function roundSize(size) {
  // Rounds the image size up to the next 100px, to ensure things are cache-able
  return Math.ceil(size / 100) * 100;
}

/**
 * Enhancer component that can be used to generate Contentful image URLs that are tailored to fit
 * the dimensions of the image element on the page.
 *
 * This should be used to avoid loading unnecessarily large images.
 *
 * Note that in most cases you probably want to use `components/ResponsiveCmsImageWrapper`, which
 * combines this enhancer with the ImageWrapper component in a friendlier interface.
 *
 * Usage example:
 * ```
<ResponsiveCmsImageCreator imageUrl="//images.ctfassets.net/myimage.png">
    {({ imageUrlWithSize, ref }) => (
      <img ref={ref} src={imageUrlWithSize} alt="" />
    )}
</ResponsiveCmsImageCreator>
```
 *
 * Breakdown of what this does:
 * - Creates an instance of this component, providing it an image URL
 * - Tells this component that it should call the provided function to generate its children
 *
 * This component will automatically call the function and pass in two parameters:
 *  - `imageUrlWithSize` - the image URL, modified to include a param that tells Contentful what size the image should be resized to
 *  - `ref` - You should pass this on as the `ref` prop to whatever element the image is going to be displayed in. We use this to know which element to measure.
 */
export class ResponsiveCmsImageCreator extends Component {
  elementToMeasure = null;

  constructor(props) {
    super(props);

    this.state = {
      elementWidth: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  updateMeasuredWidth = () => {
    if (!this.elementToMeasure) {
      return;
    }

    const { elementWidth } = this.state;

    const newWidth = this.elementToMeasure.getBoundingClientRect().width;

    if (newWidth > elementWidth) {
      this.setState({
        elementWidth: newWidth,
      });
    }
  };

  // eslint-disable-next-line react/sort-comp
  resizeHandler = _.debounce(this.updateMeasuredWidth, 100);

  elementRef = (el) => {
    if (el) {
      this.elementToMeasure = el;
      this.updateMeasuredWidth();
    }
  };

  render() {
    const { imageUrl, children } = this.props;
    const { elementWidth } = this.state;

    const pixelRatio = window.devicePixelRatio || 1;
    const imageWidth = roundSize(pixelRatio * elementWidth);

    let finalUrl = '';

    if (imageWidth > 4000) {
      // Contentful throws an error if we go bigger than this
      finalUrl = imageUrl;
    } else if (imageWidth <= 0) {
      // We haven't measured the element yet, so pass in an empty URL to avoid loading anything
      finalUrl = '';
    } else {
      // Otherwise request the measured size
      finalUrl = `${imageUrl}?w=${imageWidth}`;
    }

    return <>{children({ imageUrlWithSize: finalUrl, ref: this.elementRef })}</>;
  }
}

ResponsiveCmsImageCreator.propTypes = {
  children: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
