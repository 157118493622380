import React, { useEffect, ReactNode } from "react";
import { useSelector, useDispatch } from "react-redux";
import Masonry from "react-masonry-css";
import { MARKS, BLOCKS, Block, Inline } from "@contentful/rich-text-types";
import { FormattedMessage } from "react-intl";

import {
  getPost,
  isLoadingPost,
  getRelatedPosts,
} from "redux/modules/cms/selectors";
import { ACTION_EVENT, createActionEvent } from "tools/analytics/constants";

import AccessibleLoadNotifier from "views/components/AccessibleLoadNotifier/AccessibleLoadNotifier";
import { DefaultLayout } from "views/layouts/DefaultLayout/DefaultLayout";
import { RichText, FINAL_BLOCKS } from "views/components/RichText/RichText";
import { Meta } from "views/components/Meta/Meta";
import { formatDate, formatContent } from "tools/utilities/cms";
import { VideoPlayer } from "views/connectors/VideoPlayer";
import {
  IAuthor,
  AuthorFormattedContent,
  IAuthorFormatted,
} from "views/components/IdeasFactory/Author";
import { Tags, ITag } from "views/components/IdeasFactory/Tags";
import { Icon } from "views/components/Icon/Icon";
import { SocialSharingButtons } from "views/components/SocialSharingButtons/SocialSharingButtons";
import types from "redux/modules/cms/types";
import { Button } from "views/components/Button/Button";
import { RenderModuleContent } from "views/pages/IdeasFactory/IdeasFactory";
import { VideoWithCaptions } from "views/components/RichText/EmbeddedEntries/VideoWithCaptions/VideoWithCaptions";

/**
 * `formatContent` method (in utilities/cms) could not keep up with the recursiveness
 * for the content in Ideas Factory.
 * This is a quick fix to keep things working until we can refactor the app
 * to use Contentful's API structure in a more reliable way.
 */
export interface IPostWrapper {
  fields: IPost;
  contentType: {
    sys: {
      id: string;
    };
  };
  sys: {
    id: string;
  };
}

export interface IPost {
  id: string;
  title: string;
  date: string;
  keyPoint1: string;
  keyPoint2: string;
  keyPoint3: string;
  author: {
    fields: IAuthor;
  };
  /**
   * We leave the field named this way to avoid Contentful from crashing
   * on field name updates prior to release.
   * Long story short, if removing a field that already has values for a certain
   * content model in Contentful, at times it happens that the field can no longer
   * be edited or just doesn't work as expected (i.e.: happend with Approval status).
   * @TODO: rename the field to just `tags` once we are done with Ideas Factory.
   */
  tagsNew?: ITag[];
  content: any;
  readDuration?: number;
  relatedPosts?: IPostWrapper[];
  url: string;
  contentType: string;
  type: string;
  coverImage?: {
    file: {
      url: string;
      description?: string;
    };
  };
  metaTitle?: string;
  metaDescription?: string;
}

interface IIdeasFactoryPostProps {
  match: {
    params: {
      postUrl: string;
    };
  };
}

const CAPTION_LIMITERS = {
  start: "[CAPTION]",
  end: "[/CAPTION]",
};

/**
 * For Rich Text fields coming from Contentful's API, the nodes
 * that make up the content can either be an object or an array.
 * If it's an array, then the index 1 has the content.
 * Otherwise, the object itself can be returned as a React Node.
 * @param node Contentful's react node flavour
 *
 */
const resolveNode = (node: any) => (Array.isArray(node) ? node : [node]);

// This is the type for the Override but there's too much stuff to change for me at the moment
// import { Options } from '@contentful/rich-text-react-renderer';
const RICH_TEXT_OVERRIDES = {
  renderNode: {
    [FINAL_BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const {
        description,
        file: { url, contentType },
      } = node.data.target.fields;

      if (contentType.indexOf("image/") !== -1) {
        return (
          <figure className="Article-layout-post-image">
            <img
              className="Article-layout-post-image-content"
              src={url}
              alt={description}
            />
          </figure>
        );
      }

      return <VideoPlayer video={node.data.target.fields} />;
    },
    /**
     * For Ideas Factory, if there's a link to a youtube embed we
     * display the video as part of the post.
     * Otherwise, links are rendered as they normally would.
     */
    [FINAL_BLOCKS.HYPERLINK]: (node: any, children: any) => {
      const { uri } = node.data;

      return <a href={uri}>{children}</a>;
    },
    /**
     * This is to handle image captions.
     * The first if checks the case where there's a link inside the caption.
     *  - For that Contentful returns several nodes as part of the paragraph. Hence the parsing below.
     *    The code assumes that the first and last children will be the ones that will have the `[CAPTION]` and
     *    [/CAPTION] respectivelt.
     * The second if checks and replaces [CAPTION] for the case when the caption is just a paragraph.
     *  - Removes [CAPTION][/CAPTION] from the paragraph text (`value` attribute in the payload).
     */
    [FINAL_BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      if (children.length > 1) {
        const firstChildren = children[0];
        const lastChildren = children[children.length - 1];

        if (!Array.isArray(firstChildren) || !Array.isArray(lastChildren)) {
          return <p>{children}</p>;
        }

        const startIndex = firstChildren[1].indexOf(CAPTION_LIMITERS.start);
        const endIndex = lastChildren[1].indexOf(CAPTION_LIMITERS.end);

        if (startIndex !== -1 && endIndex !== -1) {
          firstChildren[1] = firstChildren[1].substr(
            startIndex + CAPTION_LIMITERS.start.length
          ); // Removes caption from first children
          lastChildren[1] = lastChildren[1].replace(CAPTION_LIMITERS.end, ""); // Removes caption from last children

          /**
           * Returns the formatted children, together with the ones that go in between (if any)
           */
          return (
            <p className="fineprint u-textMedium u-textGrey u-mT-0">
              {[
                firstChildren,
                children.splice(1, children.length - 2),
                lastChildren,
              ]}
            </p>
          );
        }
      }

      /**
       * This is the case for a paragraph with only text inside.
       * If the original content is wrapped in [CAPTION][/CAPTION] the logic
       * below replace those tags and returns the caption in an em.
       */
      const { value } = node.content[0];
      const startIndex = value.indexOf(CAPTION_LIMITERS.start);
      const endIndex = value.indexOf(CAPTION_LIMITERS.end);

      if (startIndex !== -1 && endIndex !== -1) {
        const captionStartIndex = startIndex + CAPTION_LIMITERS.start.length;
        const captionEndIndex = endIndex - CAPTION_LIMITERS.end.length + 1;

        return (
          <p className="fineprint u-textMedium u-textGrey u-mT-0">
            {value.substr(captionStartIndex, captionEndIndex)}
          </p>
        );
      }

      return <p>{children}</p>;
    },
    /**
     * Blockquotes need to be styled in a specific way.
     * This takes care of updating the structure we get from Contentful so we have control
     * over how it's displayed.
     */
    [FINAL_BLOCKS.QUOTE]: (_: any, children: any) => (
      <blockquote className="Article-layout-post-quote">
        <span className="Article-layout-post-quote-icon" />
        <div className="Article-layout-post-quote-content">
          {children.splice(0, children.length - 1)}
        </div>
        <div className="Article-layout-post-quote-person">
          {children[children.length - 1]}
        </div>
      </blockquote>
    ),
    [FINAL_BLOCKS.HEADING_2]: (_: any, children: any) => (
      <h2 className="h4">{resolveNode(children)}</h2>
    ),
    [FINAL_BLOCKS.HEADING_3]: (_: any, children: any) => (
      <h3 className="h5">{resolveNode(children)}</h3>
    ),
    [FINAL_BLOCKS.HEADING_4]: (_: any, children: any) => (
      <p className="u-textBold">{resolveNode(children)}</p>
    ),
    [FINAL_BLOCKS.HEADING_5]: (_: any, children: any) => (
      <p className="u-textBold">{resolveNode(children)}</p>
    ),
    [FINAL_BLOCKS.HEADING_6]: (_: any, children: any) => (
      <p className="u-textBold">{resolveNode(children)}</p>
    ),
    // To handle rendering a video with captions - "ideasFactoryPostsVideoWithCaptions"
    [BLOCKS.EMBEDDED_ENTRY]: (
      node: Block | Inline,
      children: ReactNode
    ): ReactNode => {
      const { target } = node.data;

      if (!target) {
        return null;
      }

      const moduleData = formatContent(node.data.target);

      switch (moduleData.contentType) {
        case "ideasFactoryPostsVideoWithCaptions": {
          return <VideoWithCaptions key={module.id} moduleData={moduleData} />;
        }
        default:
          console.warn("Cannot handle embedded entry ", node, children);
          return null;
      }
    },
  },
  renderMark: {
    [MARKS.UNDERLINE]: (node: any) => resolveNode(node),
  },
};

export const IdeasFactoryPost = (props: IIdeasFactoryPostProps) => {
  const {
    match: {
      params: { postUrl },
    },
  } = props;

  const isViewportMobile = useSelector((state: any) => state.browser.is.mobile);
  const isViewportTablet = useSelector((state: any) => state.browser.is.tablet);
  const isViewportLaptop = useSelector((state: any) => state.browser.is.laptop);
  const isViewportBelowLaptop = useSelector(
    (state: any) => state.browser.lessThan.laptop
  );
  const isViewportAboveLaptop = useSelector(
    (state: any) => state.browser.greaterThan.laptop
  );
  const post: IPost = useSelector(getPost);
  const relatedPosts: IPostWrapper[] = useSelector(getRelatedPosts);
  const isLoading: boolean = useSelector(isLoadingPost);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: types.GET_POST, payload: { postUrl } });
    dispatch({
      type: types.GET_IDEAS_FACTORY_RELATED_POSTS,
      payload: { postUrl },
    });

    return () => {};
  }, [dispatch, postUrl]);

  useEffect(() => {
    if (post) {
      window.localStorage.setItem(`${post.id}`, "true");
    }
  }, [post]);

  if (isLoading) {
    return (
      <article className="Article">
        <div className="Container">
          <p>Loading...</p>
        </div>
      </article>
    );
  }

  if (!post) {
    return null;
  }

  const renderTagsAndSocialButtons = () => (
    <>
      {post.tagsNew && post.tagsNew.length && (
        <div className="Grid-cell u-md-width1of2 u-xl-width1of1">
          {/* BEGIN Tags */}
          <div className="Article-layout-tags">
            <p className="u-mT-0 u-mB-xs">Tags</p>
            {post.tagsNew && <Tags isInArticle tags={post.tagsNew} />}
          </div>
          {/* END Tags */}
        </div>
      )}

      <div className="Grid-cell u-md-width1of2 u-xl-width1of1">
        {/* BEGIN Share buttons */}
        <div className="Article-layout-share">
          <p className="u-mT-0 u-mB-xs">Share via</p>
          <SocialSharingButtons url={postUrl} title={post.title} />
        </div>
        {/* END Share buttons */}
      </div>
    </>
  );

  const renderBackToTopButton = () => (
    <div className="Article-layout-backToTop">
      <button
        className="Article-layout-backToTop-button"
        type="button"
        onClick={() => window.scrollTo(0, 0)}
      >
        <span>Back to top</span>
        <Icon name="arrow-up" />
      </button>
    </div>
  );

  return (
    <AccessibleLoadNotifier isLoading={isLoading}>
      <Meta
        title={post.metaTitle}
        description={post.metaDescription}
        imageUrl={
          post.coverImage && post.coverImage.file.url.length
            ? "https:" + post.coverImage.file.url
            : "https://images.ctfassets.net/2uhdbo8liywk/3WeVqNVC4fMK3LSXeLEaMp/5908336978b8e268d0511339ee275d90/LinkedIn_Post.png"
        }
      />
      <DefaultLayout>
        <article className="Article">
          {/* BEGIN Article Header */}
          <header className="Article-hero">
            <div className="Container">
              <div className="Grid">
                <div className="Grid-cell u-xl-before1of4 u-xl-width2of4">
                  <Button
                    onClick="/"
                    className="Link u-textBold Link--back u-mB-xl"
                    label="BACK_TO_IDEAS_FACTORY"
                  />
                  <p className="u-mT-0 u-mB-sm u-textWhite">
                    <time dateTime={post.date}>{formatDate(post.date)}</time>
                    &nbsp;&bull;&nbsp;
                    <span>{post.readDuration || "5"} min read</span>
                  </p>
                  <h1 className="h3 u-mT-0 u-mB-md">{post.title}</h1>
                  {/* `formatContent` issue. Read above for more information. */}
                  {post.author && (
                    <AuthorFormattedContent
                      author={(post.author as any) as IAuthorFormatted}
                    />
                  )}
                </div>
              </div>
            </div>
          </header>
          {/* END Article Header */}

          <section className="Article-layout">
            <div className="SkeletonGrid">
              <div className="Container">
                <div className="Grid SkeletonGrid-row">
                  <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of4" />
                  <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of4" />
                  <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of4" />
                  <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of4" />
                </div>
                <div className="Grid SkeletonGrid-row">
                  <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of4" />
                  <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of4" />
                  <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of4" />
                  <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of4" />
                </div>
              </div>
            </div>

            <div className="Container">
              <div className="Grid">
                <div className="Grid-cell u-lg-width3of4">
                  <div className="Grid">
                    <div className="Grid-cell u-xl-width1of3">
                      <div className="Grid">
                        {isViewportAboveLaptop && renderTagsAndSocialButtons()}
                      </div>
                    </div>

                    <div className="Grid-cell u-xl-width2of3">
                      {/* BEGIN Article Content */}
                      <div className="Article-layout-post">
                        <ul className="u-mT-0">
                          <li className="u-textBold">{post.keyPoint1}</li>
                          <li className="u-textBold">{post.keyPoint2}</li>
                          <li className="u-textBold">{post.keyPoint3}</li>
                        </ul>
                        <RichText
                          overrides={RICH_TEXT_OVERRIDES}
                          text={post.content}
                        />
                      </div>
                      {isViewportAboveLaptop && renderBackToTopButton()}
                      {/* END Article Content */}
                    </div>
                  </div>
                </div>

                {/* BEGIN Related Articles */}
                <div className="Grid-cell u-lg-width1of4">
                  {isViewportBelowLaptop && renderTagsAndSocialButtons()}
                  <div className="Article-layout-cta u-mB-lg">
                    <span className="Article-layout-cta-icon" aria-hidden />
                    <p className="u-mB-0">
                      <FormattedMessage id="INTERESTED_IN_LEARNING_MORE" />
                    </p>
                    <Button
                      onClick="https://alphero.com/contact"
                      className="Link u-textBold"
                      label="LETS_TALK"
                      locationState={{ formType: "ideas" }}
                      actionEvent={createActionEvent(ACTION_EVENT.LETS_TALK)}
                      linkType="external"
                      linkTarget="_blank"
                    />
                  </div>
                  {relatedPosts.length > 0 && (
                    <div className="Article-layout-relatedPosts">
                      <div className="Article-layout-relatedPosts-heading">
                        <p className="More u-textBold u-mT-0 u-textPrimary">
                          <FormattedMessage id="LOOKING_FOR_MORE" />
                        </p>
                        {isViewportTablet && (
                          <Button
                            onClick="/"
                            className="Link u-textBold"
                            label="SEE_ALL"
                          />
                        )}
                      </div>
                      <Masonry
                        breakpointCols={{ default: 1, 1023: 2, 767: 1 }}
                        className="MasonryGrid"
                        columnClassName="MasonryGrid-column"
                      >
                        {relatedPosts.map((relatedPost: IPostWrapper) => (
                          <RenderModuleContent
                            key={relatedPost.sys.id}
                            content={{
                              ...relatedPost,
                              contentType: relatedPost.contentType?.sys?.id,
                            }}
                            isLandscape={false}
                          />
                        ))}
                      </Masonry>
                      {(isViewportMobile ||
                        isViewportLaptop ||
                        isViewportAboveLaptop) && (
                        <Button
                          onClick="/"
                          className="Link u-textBold u-sm-mB-md"
                          label="SEE_ALL"
                        />
                      )}
                    </div>
                  )}
                  {isViewportBelowLaptop && renderBackToTopButton()}
                </div>
                {/* END Related Articles */}
              </div>

              {isViewportLaptop && (
                <>
                  {renderTagsAndSocialButtons()}
                  {renderBackToTopButton()}
                </>
              )}
            </div>
          </section>
        </article>
      </DefaultLayout>
    </AccessibleLoadNotifier>
  );
};
