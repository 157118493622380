import React from "react";
// @ts-ignore no types for MetaTags
import MetaTags from "react-meta-tags";

const BASE_TITLE = "Alphero - Go Beyond Better";

interface Props {
  description?: string;
  imageUrl?: string | null;
  title?: string;
}

export const Meta = (props: Props) => {
  const { title, description, imageUrl } = props;

  const fullTitle = title || BASE_TITLE;

  return (
    <MetaTags>
      <meta
        property="og:image"
        content="https://images.ctfassets.net/2uhdbo8liywk/3WeVqNVC4fMK3LSXeLEaMp/5908336978b8e268d0511339ee275d90/LinkedIn_Post.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="description_of_site" />
      <meta
        property="og:image:secure_url"
        content="https://images.ctfassets.net/2uhdbo8liywk/3WeVqNVC4fMK3LSXeLEaMp/5908336978b8e268d0511339ee275d90/LinkedIn_Post.png"
      />
      {imageUrl ? (
        <>
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:secure_url" content={imageUrl} />
        </>
      ) : (
        <>
          <meta
            property="og:image"
            content="https://images.ctfassets.net/2uhdbo8liywk/3WeVqNVC4fMK3LSXeLEaMp/5908336978b8e268d0511339ee275d90/LinkedIn_Post.png"
          />
          <meta
            property="og:image:secure_url"
            content="https://images.ctfassets.net/2uhdbo8liywk/3WeVqNVC4fMK3LSXeLEaMp/5908336978b8e268d0511339ee275d90/LinkedIn_Post.png"
          />
        </>
      )}
      <title>{fullTitle}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={fullTitle} />
      <meta name="twitter:title" content={fullTitle} />
      {description && (
        <meta
          name="twitter:description"
          content=" Offering tour packages for individuals or groups."
        />
      )}
    </MetaTags>
  );
};
