import React, { Component } from 'react';

/**
 * Provides accessible information about the page load status.
 * This should be wrapped around all pages so that screen-readers can tell the user when the page
 *  has loaded For pages that load instantly (i.e. do not fetch CMS data), pass in
 * `isLoading={false}`. Otherwise pass in the loading state, e.g `isLoading={hasDataLoaded}`.
 */

interface IProps {
  children?: any;
  isLoading: boolean;
}

export default class AccessibleLoadNotifier extends Component<IProps> {
  focusResetterEl: any = React.createRef();

  componentDidMount() {
    const { isLoading } = this.props;

    // If the page is in a loaded state to start with, immediately say so
    if (this.focusResetterEl.current && isLoading === false) {
      this.focusResetterEl.current.focus();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { isLoading } = this.props;

    if (
      this.focusResetterEl.current
      && prevProps.isLoading != null
      && isLoading !== prevProps.isLoading
    ) {
      this.focusResetterEl.current.focus();
    }
  }

  render() {
    const { isLoading, children } = this.props;
    return (
      <>
        {isLoading ? (
          <p
            className="u-hiddenVisually"
            key="loadingmsg"
            role="status"
            aria-live="polite"
            aria-atomic="true"
          >
            Page loading
          </p>
        ) : (
          <p className="u-hiddenVisually" ref={this.focusResetterEl} tabIndex={-1} aria-hidden>
            Page loaded
          </p>
        )}

        {children}
      </>
    );
  }
}
