// NOTE: This is a generated file, nothing you do here matters
// The source is the icomoon file located at 'public/assets/icons/selection.json'
// The script that generates this file is located at tools/icomoon-generator.js
// To update this file, selection.json must be changed and
// the script run 'yarn run generate-icons'
import React from 'react';

export default {
  'quote': <span className="icon icon-quote" aria-hidden="true" />,
  'attach': <span className="icon icon-attach" aria-hidden="true" />,
  'clear': <span className="icon icon-clear" aria-hidden="true" />,
  'error': <span className="icon icon-error" aria-hidden="true" />,
  'chevron': <span className="icon icon-chevron" aria-hidden="true" />,
  'check': <span className="icon icon-check" aria-hidden="true" />,
  'arrow-forwards': <span className="icon icon-arrow-forwards" aria-hidden="true" />,
  'arrow-up': <span className="icon icon-arrow-up" aria-hidden="true" />,
  'arrow-back': <span className="icon icon-arrow-back" aria-hidden="true" />,
  'close': <span className="icon icon-close" aria-hidden="true" />,
  'instagram': <span className="icon icon-instagram" aria-hidden="true" />,
  'linkedin': <span className="icon icon-linkedin" aria-hidden="true" />,
  'facebook': <span className="icon icon-facebook" aria-hidden="true" />,
  'twitter': <span className="icon icon-twitter" aria-hidden="true" />,
};
