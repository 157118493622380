import React from 'react';
import { connect } from 'react-redux';

import { IS_PRODUCTION } from 'redux/modules/app/constants';
import * as selectorsApp from 'redux/modules/app/selectors';
import { getCaseStudyID } from 'redux/modules/cms/selectors';
import { CONTENTFUL_SETTINGS } from 'redux/modules/cms/constants';

interface IProps {
  caseStudyID?: string;
  id?: string;
  shouldShowMeta?: boolean;
  zeplinId: string;
  isBlogContent?: boolean;
}

const Info = ({
  caseStudyID, id, shouldShowMeta, zeplinId, isBlogContent,
}: IProps) => {
  if (IS_PRODUCTION || !shouldShowMeta) {
    return null;
  }

  const getCMSUrl = () => {
    const space = isBlogContent ? CONTENTFUL_SETTINGS.blogSpace : CONTENTFUL_SETTINGS.space;
    let cmsUrl = `https://app.contentful.com/spaces/${space}/environments/dev/entries/${id}`;

    if (caseStudyID) {
      cmsUrl = `${cmsUrl}?previousEntries=${caseStudyID}`;
    }

    return cmsUrl;
  };

  return (
    <div className="ComponentInfo" title={zeplinId}>
      <span className="ComponentInfo-id">{zeplinId}</span>
      {id && (
        <a target="_blank" rel="noopener noreferrer" className="ComponentInfo-link" href={getCMSUrl()}>CMS Link</a>
      )}
      <hr className="ComponentInfo-border" />
    </div>
  );
};

// Selector
const mapStateToProps = (state: any) => ({
  caseStudyID: getCaseStudyID(state),
  shouldShowMeta: selectorsApp.shouldShowMeta(state),
});

const ComponentInfo = connect(mapStateToProps, null)(Info);

export {
  ComponentInfo,
};
