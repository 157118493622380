import { trackEvent, trackError, trackPageView } from 'tools/analytics';

import typesApp from 'redux/modules/app/types';
import { LOCATION_CHANGE_ACTION_TYPE } from 'redux/modules/app/constants';

const ACTION_IGNORE_LIST = [
  'APP_TOGGLE_MENU',
  // Don't include APP_SEND as we're already recording it in LetsTalk component
  // We are still tracking APP_SEND_SUCCESS and APP_SEND_FAILURE
  'APP_SEND',
];

const SAGA_EFFECTS_TYPES = {
  CALL: 'CALL',
  FORK: 'FORK',
  PUT: 'PUT',
  TAKE: 'TAKE',
};

export const sagaMonitor = {
  triggeredEffects: {},

  effectApplies(effect) {
    const {
      CALL, FORK, PUT, TAKE,
    } = SAGA_EFFECTS_TYPES;

    return (
      Object.prototype.hasOwnProperty.call(effect, CALL)
      || Object.prototype.hasOwnProperty.call(effect, FORK)
      || Object.prototype.hasOwnProperty.call(effect, PUT)
      || Object.prototype.hasOwnProperty.call(effect, TAKE)
    );
  },

  /*
   * The different effect types have different structure.
   * So this is the best that we could get there.
   * TODO: Check if we'd actually like to have all of them contemplated.
   */
  getAction(effect) {
    const {
      CALL, FORK, PUT, TAKE,
    } = SAGA_EFFECTS_TYPES;

    if (effect[CALL]) {
      return effect[CALL].fn.name;
    }

    if (effect[FORK]) {
      const { args } = effect[FORK];

      if (args && args.length) {
        const { type } = args[0];
        return type || args[0];
      }
    }

    if (effect[PUT]) {
      return effect[PUT].action.type;
    }

    if (effect[TAKE]) {
      return effect[TAKE].pattern;
    }

    return null;
  },

  effectTriggered({ effectId, effect }) {
    if (!this.effectApplies(effect)) {
      return;
    }

    const action = this.getAction(effect);

    if (!action) {
      return;
    }

    this.triggeredEffects[effectId] = action;
  },

  effectResolved(effectId, result) {
    const currentEffect = this.triggeredEffects[effectId];

    if (!currentEffect) {
      return;
    }

    if (result) {
      // Only track an event if it's not in this last
      const shouldTrackEvent = !ACTION_IGNORE_LIST.includes(currentEffect);

      if (shouldTrackEvent) {
        trackEvent(result);
      }
    }

    delete this.triggeredEffects[effectId];
  },

  effectRejected(effectId, error) {
    const currentEffect = this.triggeredEffects[effectId];

    if (!currentEffect) {
      return;
    }

    trackError(currentEffect, error);
    delete this.triggeredEffects[effectId];
  },

  /*
   * This hook is ran for every action dispatched by the store, never mind
   * if there's a Saga listening to it.
   * The LOCATION_CHANGE is a particular case since there's not a saga listening to it.
   * So for this action, neither of the effectRejected or effectResolved hooks are run.
   * Hence the conditional below.
   */
  actionDispatched(action) {
    if (action.type === typesApp.LOCATION_CHANGE || action.type === LOCATION_CHANGE_ACTION_TYPE) {
      trackPageView(action);
    }
  },
};
