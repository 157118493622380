import React from 'react';

import { DataProp } from 'views/modules/moduleConstants';
import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { RichText } from 'views/components/RichText/RichText';
import { SkeletonText } from 'views/components/SkeletonText/SkeletonText';

export const ZEPLIN_ID = '2COL01-01a';

export interface TwoColumnTextProps {
  isLoading?: boolean;
  leftContent: DataProp;
  leftTitle: string;
  rightContent: DataProp;
  rightTitle: string;
}

export const TwoColumnText = ({
  isLoading,
  leftTitle,
  leftContent,
  rightTitle,
  rightContent,
}: TwoColumnTextProps) => {
  if (!leftContent && !rightContent && !isLoading) {
    return null;
  }

  return (
    <section className="TwoColumnText ModuleWrapper">
      <div className="Container">
        <ComponentInfo zeplinId={ZEPLIN_ID} />
        <div className="Grid">
          <div className="Grid-cell u-width1of1 u-md-width1of2 u-sm-mB-md">
            <div className="TwoColumnText-split">
              {isLoading ? (
                <>
                  <SkeletonText wordCount={4} className="h4" />
                  <SkeletonText wordCount={60} className="p" />
                </>
              ) : (
                <>
                  <h3 className="h4 u-mT-0 u-mB-sm">{leftTitle}</h3>
                  <RichText text={leftContent} />
                </>
              )}
            </div>
          </div>
          <div className="Grid-cell u-width1of1 u-md-width1of2">
            <div className="TwoColumnText-split">
              {isLoading ? (
                <>
                  <SkeletonText wordCount={4} className="h4" />
                  <SkeletonText wordCount={60} className="p" />
                </>
              ) : (
                <>
                  <h3 className="h4 u-mT-0 u-mB-sm">{rightTitle}</h3>
                  <RichText text={rightContent} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
