import types from './types';

/*
 * `data` is the actual record that was requested by the action.
 * `includes` contains the records linked to what was requested.
 */
const CMS_DATA_BOILERPLATE = {
  data: [],
  includes: {},
};

const initialState = {
  awards: {
    ...CMS_DATA_BOILERPLATE,
  },
  includesCache: {},
  caseStudy: {
    ...CMS_DATA_BOILERPLATE,
  },
  relatedCaseStudies: {
    ...CMS_DATA_BOILERPLATE,
  },
  homeContent: {
    data: null,
    includes: null,
  },
  openPositions: {
    data: null,
    includes: null,
  },
  genericJobDescription: {
    data: null,
    includes: null,
  },
  offices: {
    data: null,
    includes: null,
  },
  meta: {
    data: {},
    includes: {},
  },
  workPage: {
    data: null,
    includes: null,
  },
  usDetails: {
    data: null,
    includes: null,
  },
  notFound: {
    data: null,
    includes: null,
  },
  services: {
    data: null,
    includes: null,
  },
  ideasFactoryModules: {
    data: null,
    include: null,
  },
  ideasFactoryPosts: [],
  ideasFactoryRelatedPosts: [],
  isLoading: false,
  isLoadingModules: false,
  isLoadingPost: false,
  error: null,
  contentVersion: '',

  tsunamiReadyPrivacyPolicy: null,
};

const reducerCMS = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTENT_VERSION_SUCCESS:
      return {
        ...state,
        contentVersion:
          action.payload.data && action.payload.data[0]
            ? action.payload.data[0].contentVersion
            : null,
      };
    case types.GET_CASE_STUDY:
      return {
        ...state,
        caseStudy: {
          isLoading: true,
        },
      };
    case types.GET_CASE_STUDY_SUCCESS:
      return {
        ...state,

        /**
         * Cache of the "includes" payload from certain requests.
         * This is used by the getContentFromCMS saga, to avoid unnecessary requests to the CMS.
         * For simplicity, items are only added to this from certain reducers. It's also completely
         * overwritten (rather than being cleverly merged) when updated (but this could be changed
         * if it seems worthwhile).
         */
        includesCache: action.payload.includes,
        /*
         * Since it will be looking for one specific case study, it assumes
         * it's the first element in the
         */
        caseStudy: {
          isLoading: false,
          data: action.payload.data[0],
          includes: action.payload.includes,
        },
      };
    case types.GET_CASE_STUDY_FAIL:
      return {
        ...state,
        caseStudy: {
          isLoading: false,
        },
        error: action.error,
      };
    case types.GET_HOME_CONTENT:
      return {
        ...state,
        homeContent: {
          isLoading: true,
        },
      };
    case types.GET_HOME_CONTENT_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        homeContent: {
          isLoading: false,
          data: action.payload.data[0],
          includes: action.payload.includes,
        },
      };
    case types.GET_HOME_CONTENT_FAIL:
      return {
        ...state,
        homeContent: {
          isLoading: false,
        },
        error: action.error,
      };
    case types.GET_NOT_FOUND:
      return {
        ...state,
        notFound: {
          isLoading: true,
        },
      };
    case types.GET_NOT_FOUND_SUCCESS:
      return {
        ...state,
        notFound: {
          data: action.payload.data,
          isLoading: false,
        },
      };
    case types.GET_NOT_FOUND_FAIL:
      return {
        ...state,
        error: action.error,
        notFound: {
          isLoading: false,
        },
      };
    case types.GET_OPEN_POSITIONS:
      return {
        ...state,
        openPositions: {
          isLoading: true,
        },
      };
    case types.GET_OPEN_POSITIONS_SUCCESS:
      return {
        ...state,
        openPositions: {
          isLoading: false,
          data: action.payload.data,
        },
      };
    case types.GET_OPEN_POSITIONS_FAIL:
      return {
        ...state,
        openPositions: {
          isLoading: false,
        },
        error: action.error,
      };
    case types.GET_GENERIC_JOB_DESCRIPTION:
      return {
        ...state,
        genericJobDescription: {
          isLoading: true,
        },
      };
    case types.GET_GENERIC_JOB_DESCRIPTION_SUCCESS:
      return {
        ...state,
        genericJobDescription: {
          isLoading: false,
          data: action.payload.data,
        },
      };
    case types.GET_GENERIC_JOB_DESCRIPTION_FAIL:
      return {
        ...state,
        genericJobDescription: {
          isLoading: false,
        },
        error: action.error,
      };
    case types.GET_OFFICES:
      return {
        ...state,
        offices: {
          isLoading: true,
        },
      };
    case types.GET_OFFICES_SUCCESS:
      return {
        ...state,
        offices: {
          isLoading: false,
          data: action.payload.data,
        },
      };
    case types.GET_OFFICES_FAIL:
      return {
        ...state,
        offices: {
          isLoading: false,
        },
        error: action.error,
      };
    case types.GET_RELATED_CASE_STUDIES:
      return {
        ...state,

        relatedCaseStudies: {
          isLoading: true,
        },
      };
    case types.GET_RELATED_CASE_STUDIES_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        /*
         * Since it will be looking for one specific related case study, it assumes
         * it's the first element in the
         */
        relatedCaseStudies: {
          isLoading: false,
          data: (action.payload.data && action.payload.data[0]) || {},
          includes: action.payload.includes,
        },
      };
    case types.GET_RELATED_CASE_STUDIES_FAIL:
      return {
        ...state,
        relatedCaseStudies: {
          isLoading: true,
        },
        error: action.error,
      };
    case types.GET_US_DETAILS:
      return {
        ...state,
        usDetails: {
          isLoading: true,
        },
      };
    case types.GET_US_DETAILS_SUCCESS:
      return {
        ...state,
        /*
         * Since we only expect one UsPage we grab the first entry
         */
        usDetails: {
          isLoading: false,
          data: (action.payload.data && action.payload.data[0]) || {},
          includes: action.payload.includes,
        },
      };
    case types.GET_US_DETAILS_FAIL:
      return {
        ...state,
        usDetails: {
          isLoading: false,
        },
        error: action.error,
      };
    case types.GET_US_AWARDS:
      return {
        ...state,
      };
    case types.GET_US_AWARDS_SUCCESS:
      return {
        ...state,
        awards: action.payload,
      };
    case types.GET_US_AWARDS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case types.GET_META:
      return {
        ...state,
      };
    case types.GET_META_SUCCESS:
      return {
        ...state,
        meta: {
          data: (action.payload.data && action.payload.data[0]) || {},
          includes: action.payload.includes,
        },
      };
    case types.GET_META_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case types.GET_WORK_PAGE:
      return {
        ...state,
        workPage: {
          isLoading: true,
        },
      };
    case types.GET_WORK_PAGE_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        /*
         * Since we only expect one WorkPage we grab the first entry
         */
        workPage: {
          isLoading: false,
          data: (action.payload.data && action.payload.data[0]) || {},
          includes: action.payload.includes,
        },
      };
    case types.GET_WORK_PAGE_FAIL:
      return {
        ...state,
        workPage: {
          isLoading: false,
        },
        error: action.error,
      };
    case types.GET_SERVICES_PAGE:
      return {
        ...state,
        services: {
          isLoading: true,
        },
      };
    case types.GET_SERVICES_PAGE_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        services: {
          isLoading: false,
          data: (action.payload.data && action.payload.data[0]) || {},
          includes: action.payload.includes,
        },
      };
    case types.GET_SERVICES_PAGE_FAIL:
      return {
        ...state,
        services: {
          isLoading: false,
          error: action.error,
        },
      };
    case types.GET_IDEAS_FACTORY_CONTENT:
      return {
        ...state,
        isLoadingModules: true,
      };
    case types.GET_IDEAS_FACTORY_CONTENT_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        ideasFactoryModules: action.payload.data,
        isLoadingModules: false,
      };
    case types.GET_IDEAS_FACTORY_CONTENT_FAIL:
      return {
        ...state,
        isLoadingModules: false,
        error: action.error,
      };
    case types.GET_POST:
      return {
        ...state,
        isLoadingPost: true,
      };
    case types.GET_POST_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        post: action.payload.data[0],
        isLoadingPost: false,
      };
    case types.GET_POST_FAIL:
      return {
        ...state,
        isLoadingPost: false,
        error: action.error,
      };
    case types.GET_IDEAS_FACTORY_POSTS:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_IDEAS_FACTORY_POSTS_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        ideasFactoryPosts: action.payload.data,
        isLoading: false,
      };
    case types.GET_IDEAS_FACTORY_POSTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.GET_IDEAS_FACTORY_RELATED_POSTS:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_IDEAS_FACTORY_RELATED_POSTS_SUCCESS:
      return {
        ...state,
        includesCache: action.payload.includes,
        ideasFactoryRelatedPosts: action.payload.data,
        isLoading: false,
      };
    case types.GET_IDEAS_FACTORY_RELATED_POSTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.GET_TSUNAMI_READY_PRIVACY_POLICY:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_TSUNAMI_READY_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        tsunamiReadyPrivacyPolicy: action.payload.data,
        isLoading: false,
      };
    case types.GET_TSUNAMI_READY_PRIVACY_POLICY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducerCMS;
