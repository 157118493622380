import {
  applyMiddleware, combineReducers, createStore, compose,
} from 'redux';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createResponsiveStateReducer, responsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import { sagaMonitor } from 'tools/utilities/sagas';
import { trackError } from 'tools/analytics';
import * as reducers from './modules';
import rootSaga from './modules/sagas';
import { featureFlagsReducer } from './modules/featureFlags/reducers';

export const history = createBrowserHistory();

// this has to match media-queries.scss
const rootReducer = combineReducers({
  browser: createResponsiveStateReducer(
    {
      mobile: 767,
      tablet: 1023,
      laptop: 1279,
      desktop: 1599,
    },
    {
      // This assigns anything bigger than desktop as widescreen
      infinity: 'widescreen',
    },

  ),
  ...reducers,
  router: connectRouter(history),
  featureFlags: featureFlagsReducer,
});

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
  onError: trackError,
});

const middleware = [routerMiddleware(history), sagaMiddleware];

let composeEnhancers = compose;

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middleware)),
);

export default store;

sagaMiddleware.run(rootSaga);
