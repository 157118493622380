import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import classNames from 'classnames';

import { Icon } from 'views/components/Icon/Icon';
import TrackableLink from 'views/connectors/TrackableLink';
import { LinkArrow } from 'views/components/Button/Button';

export const CAPTION_VERTICAL_POSITIONS = {
  BOTTOM: 'bottom',
  TOP: 'top',
  MIDDLE: 'middle',
};

class SkewCaption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 200,
    };
  }

  render() {
    const { height } = this.state;
    const {
      icon,
      clientName,
      text,
      title,
      linkData,
      position,
      shouldFlipX,
      className: classes,
      isLeft,
      onHeightChange,
      skewPadding,
    } = this.props;

    const marginProperty = position === CAPTION_VERTICAL_POSITIONS.TOP ? 'marginBottom' : 'marginTop';

    let captionOffsetPercent = 0.5;

    if (position === CAPTION_VERTICAL_POSITIONS.TOP) {
      captionOffsetPercent = height > 250 ? 0.25 : 0.5;
    }

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setState({ height: contentRect.bounds.height }, () => {
            if (onHeightChange) {
              onHeightChange(contentRect.bounds.height);
            }
          });
        }}
      >
        {({ measureRef }) => (
          // This div is needed for IE
          <div className="u-flex">
            <div
              ref={measureRef}
              className={classNames('SkewCaption', classes)}
              style={{
                [marginProperty]: captionOffsetPercent * -height,
              }}
            >
              {/* This is to avoid texts to be near the edge of the borders  */}
              <svg
                className={classNames('SkewCaption-background', {
                  'SkewCaption-background-flipped': shouldFlipX,
                })}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox={`0 0 100 ${height}`}
              >
                <path
                  fill="#050158"
                  d={`M 0,0 L 100,${skewPadding} L 100,${height - skewPadding} L0, ${height} Z`}
                />
              </svg>
              <div
                className={classNames('SkewCaption-wrapper', {
                  'SkewCaption--left': isLeft,
                })}
              >
                {icon && (
                  <span className="SkewCaption-icon">
                    <Icon name={icon} />
                  </span>
                )}

                {clientName && <p className="SkewCaption-client h5 u-mT-0 u-mB-sm">{clientName}</p>}
                {title && <p className="h4 SkewCaption-title u-textWhite">{title}</p>}
                {text && <p className="SkewCaption-text u-textWhite">{text}</p>}
                {linkData && (
                <TrackableLink
                  className="SkewCaption-link Link u-textNoWrap"
                  url={`/work/${linkData.url}`}
                  event={linkData.event}
                >
                  <span className="p SkewCaption-link-title">
                    <strong>{linkData.label}</strong>
                    <LinkArrow />
                  </span>

                </TrackableLink>
                )}
              </div>
            </div>
          </div>
        )}
      </Measure>
    );
  }
}

SkewCaption.propTypes = {
  className: PropTypes.string,
  clientName: PropTypes.string,
  icon: PropTypes.string,
  isLeft: PropTypes.bool,
  linkData: PropTypes.shape({
    event: PropTypes.shape({}),
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  onHeightChange: PropTypes.func,
  position: PropTypes.oneOf(Object.values(CAPTION_VERTICAL_POSITIONS)),
  shouldFlipX: PropTypes.bool,
  skewPadding: PropTypes.number,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SkewCaption.defaultProps = {
  className: '',
  clientName: '',
  text: null,
  icon: null,
  isLeft: false,
  linkData: null,
  onHeightChange: () => {},
  position: CAPTION_VERTICAL_POSITIONS.TOP,
  shouldFlipX: false,
  skewPadding: 30,
};

export { SkewCaption };
