import {
  takeEvery, call, put, select,
} from 'redux-saga/effects';
import humps from 'humps';
import ReactGA from 'react-ga';
import { createActionEvent } from 'tools/analytics/constants';
import api from './api';

import * as selectorsApp from './selectors';
import types from './types';

function* send(action) {
  const { message } = action.payload;

  try {
    const response = yield call(api.send, message);
    const payload = humps.camelizeKeys(response);

    if (payload.success === false) {
      // eslint-disable-next-line no-console
      console.error(payload.error);
      yield put({ type: types.SEND_FAIL });
    }

    yield put({ type: types.SEND_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.SEND_FAIL });
  }
}

function* subscribeToNewsletter(action) {
  const { email } = action.payload;

  try {
    const response = yield call(api.subscribe, email);

    yield put({
      type: types.SUBSCRIBE_TO_NEWSLETTER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({ type: types.SUBSCRIBE_TO_NEWSLETTER_FAIL });
  }
}

function* analyticsToggleMenu(action) {
  const isMenuOpen = yield select(selectorsApp.isMenuOpen);
  const { payload } = action;

  try {
    const actionEvent = createActionEvent(payload.label, {
      menuStatus: isMenuOpen ? 'Opening' : 'Closing',
    });

    ReactGA.event(actionEvent);

    yield {};
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Sending analytics failed', error);
    yield {};
  }
}

function* getJobListings() {
  try {
    const response = yield call(api.getJobListings);

    // eslint-disable-next-line no-prototype-builtins
    if (response.hasOwnProperty('jobs')) {
      yield put({
        type: types.GET_JOB_LISTINGS_SUCCESS,
        payload: response.jobs,
      });
    } else {
      throw new Error('Malformed workable response');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Retrieving job listings failed', error);
    yield {};
  }
}

export function* appSaga() {
  yield takeEvery(types.SEND, send);
  yield takeEvery(types.SUBSCRIBE_TO_NEWSLETTER, subscribeToNewsletter);
  yield takeEvery(types.TOGGLE_MENU, analyticsToggleMenu);
  yield takeEvery(types.GET_JOB_LISTINGS, getJobListings);
}

export default appSaga;
