import React from 'react';
import { connect } from 'react-redux';

import * as selectorsApp from 'redux/modules/app/selectors';
import { CONTENTFUL_SETTINGS } from 'redux/modules/cms/constants';

interface IProps {
  description?: string;
  imageExtension?: string;
  size?: number;
  title?: string;
  shouldShowMeta?: boolean;
  assetId?: string;
}

const createContentfulAssetUrl = (assetId: string): string => `https://app.contentful.com/spaces/${CONTENTFUL_SETTINGS.space}/environments/dev/assets/${assetId}`;

const ImageInfo = ({
  description,
  imageExtension,
  size = 0,
  title,
  shouldShowMeta,
  assetId,
}: IProps) => (
  shouldShowMeta
    ? (
      <div className="ImageInfo">
        <ul>
          <li>
            <strong>Title: </strong>
            {assetId && (
              <a href={createContentfulAssetUrl(assetId)} target="_blank" rel="noopener noreferrer">
                <span>{title}</span>
              </a>
            )}
            {!assetId && (
              <span>{title}</span>
            )}
          </li>
          <li>
            <strong>Alt text: </strong>
            <span>{description}</span>
          </li>
          <li>
            <strong>Size: </strong>
            <span>{size / 1000} kB</span>
          </li>
          <li>
            <strong>Format: </strong>
            <span>
              {imageExtension}
            </span>
          </li>
        </ul>
      </div>
    ) : null
);

const mapStateToProps = (state: any) => ({
  shouldShowMeta: selectorsApp.shouldShowMeta(state),
});

const ImageInfoConnected = connect(mapStateToProps, null)(ImageInfo);

export { ImageInfoConnected as ImageInfo };
