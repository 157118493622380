import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { AwardProp } from 'views/modules/moduleConstants';
import { Button } from 'views/components/Button/Button';
import { RichText } from 'views/components/RichText/RichText';

export interface DescriptionModuleProps {
  appStore?: string;
  awards?: AwardProp[];
  whatWeDid?: string[];
  client?: string;
  description?: string;
  googlePlay?: string;
  id: string;
  isViewportMobile: boolean;
  siteLink?: string;
  header?: string;
  yearEnd?: number | string;
  yearStart?: number;
}

export const DescriptionModule = ({
  appStore,
  awards,
  whatWeDid = [],
  client,
  description,
  googlePlay,
  id,
  isViewportMobile,
  siteLink,
  header,
  yearStart,
  yearEnd,
}: DescriptionModuleProps) => {
  // If there's no categories or yearStarts then just hide this entire section
  if (!whatWeDid || !whatWeDid.length) {
    return null;
  }

  const hasTryItOut = appStore || googlePlay || siteLink;

  let ZEPLIN_ID;
  if (siteLink && (appStore || googlePlay)) {
    ZEPLIN_ID = 'DES02-01d';
  } else if (siteLink && !appStore && !googlePlay) {
    ZEPLIN_ID = 'DES02-01c';
  } else if (appStore || googlePlay) {
    ZEPLIN_ID = 'DES02-01b';
  } else {
    ZEPLIN_ID = 'DES02-01a';
  }

  return (
    <section className={classNames('CaseStudy-overview ModuleWrapper')}>
      <div className="Container">
        <ComponentInfo id={id} zeplinId={ZEPLIN_ID} />
        <div className="Grid">
          <div className="Grid-cell u-md-width10of12 u-md-before1of12 u-md-after1of12">
            {header && <h3 className="h4 u-mB-0">{header}</h3>}
            {description && (
              <p className="CaseStudy-overview-description">
                <RichText text={description} />
              </p>
            )}
          </div>

          <div className="Grid-cell u-md-width10of12 u-md-before1of12 u-md-after1of12">
            <div className="CaseStudy-overview-border">
              {yearStart && (
                <div className="Grid-cell u-sm-width1of1 u-md-width1of3 CaseStudy-overview-item">
                  {client && (
                    <>
                      <p className="small u-textBold u-mT-0 u-mB-xs u-textPrimary">
                        <FormattedMessage id="CLIENT" />
                      </p>
                      <p className="small u-mT-0 u-mB-0">{client}</p>
                    </>
                  )}

                  <div className="Grid-cell u-mT-md">
                    <p className="small u-textBold u-mT-0 u-mB-xs u-textPrimary">
                      <FormattedMessage id="YEAR" />
                    </p>
                    <p className="small u-mT-0 u-mB-0">
                      {yearStart}-{yearEnd || 'now'}
                    </p>
                  </div>

                  {isViewportMobile && (
                    <div className="Grid-cell u-mT-md">
                      <p className="small u-textBold u-mT-0 u-mB-xs u-textPrimary">
                        <FormattedMessage id="WHAT_WE_DID" />
                      </p>
                      <ul className="CaseStudy-overview-list small u-mT-0 u-lg-mB-0">
                        {whatWeDid.map((category) => (
                          <li key={category} className="u-mB-0">
                            <p className="small u-textMagicGrey u-mT-0 u-mB-0">
                              {category}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {awards && (
                    <div className="Grid-cell u-mT-md">
                      <p className="small u-textBold u-mT-0 u-mB-xs u-textPrimary">
                        <FormattedMessage id="AWARDS" />
                      </p>

                      {awards.map((award) => (
                        <p key={award.id} className="small u-mT-0 u-mB-0">
                          {award.type} {award.year}, {award.outcome}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {!isViewportMobile && (
                <div className="Grid-cell u-sm-width1of1 u-md-width1of3 CaseStudy-overview-item">
                  <p className="small u-textBold u-mT-0 u-mB-xs u-textPrimary">
                    <FormattedMessage id="WHAT_WE_DID" />
                  </p>
                  <ul className="CaseStudy-overview-list small u-mT-0 u-lg-mB-0">
                    {whatWeDid.map((category) => (
                      <li key={category} className="u-mB-0">
                        <p className="small u-textMagicGrey u-mT-0 u-mB-0">
                          {category}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="Grid-cell u-sm-width1of1 u-md-width1of3 CaseStudy-overview-item">
                {hasTryItOut && (
                  <div className="CaseStudy-overview-app">
                    <p className="small u-textBold u-mT-0 u-mB-xs u-textPrimary">
                      <FormattedMessage id="TRY_IT_OUT" />
                    </p>
                    <div className="Overview-tryout-content">
                      {appStore && (
                        <a
                          className="Overview-tryout-icon"
                          href={appStore}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/images/app-store.png"
                            alt="Download on the Apple App Store"
                          />
                        </a>
                      )}
                      {googlePlay && (
                        <a
                          className="Overview-tryout-icon"
                          href={googlePlay}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/images/google-play.png"
                            alt="Get it on Google Play"
                          />
                        </a>
                      )}
                      {siteLink && (
                        <Button
                          linkType="external"
                          className="Button Button--primary u-textNoWrap u-width2of12"
                          label="VIEW_WEBSITE"
                          linkTarget="_blank"
                          onClick={siteLink}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
