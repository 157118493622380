import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import types from "redux/modules/cms/types";
import {
  IDEAS_FACTORY_CONTENT_TYPES,
  IDEAS_FACTORY_MODULE_TYPES,
  IDEAS_FACTORY_POST_TYPES,
} from "redux/modules/cms/constants";
import { ACTION_EVENT, createActionEvent } from "tools/analytics/constants";
import { getIdeasFactoryContent } from "redux/modules/cms/selectors";

import { DefaultLayout } from "views/layouts/DefaultLayout/DefaultLayout";
import AccessibleLoadNotifier from "views/components/AccessibleLoadNotifier/AccessibleLoadNotifier";
import {
  IQuotePost,
  QuotePost,
} from "views/components/IdeasFactory/posts/QuotePost";
import { OpinionPost } from "views/components/IdeasFactory/posts/OpinionPost";
import { SnapshotPost } from "views/components/IdeasFactory/posts/SnapshotPost";
import {
  HighlightModule,
  IHighlightModule,
} from "views/components/IdeasFactory/modules/HighlightModule";
import {
  FeaturedModule,
  IFeaturedModule,
} from "views/components/IdeasFactory/modules/FeaturedModule";
import {
  PostCollageModule,
  IPostCollageModule,
} from "views/components/IdeasFactory/modules/PostCollageModule";
import { ICTAModule } from "views/components/IdeasFactory/modules/CTAModule";
import {
  QuoteModule,
  IQuoteModule,
} from "views/components/IdeasFactory/modules/QuoteModule";
import {
  LinkPost,
  ILinkPost,
} from "views/components/IdeasFactory/posts/LinkPost";
import { Button } from "views/components/Button/Button";
import { Meta } from "views/components/Meta/Meta";
import { SkeletonText } from "views/components/SkeletonText/SkeletonText";
import { ITag, Tags } from "views/components/IdeasFactory/Tags";
import {
  IPost,
  IPostWrapper,
} from "views/pages/IdeasFactoryPost/IdeasFactoryPost";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css"; // For ScrollAnimation

interface IIdeasFactory {
  description?: string;
  metaTitle: string;
  metaDescription: string;
  modules: (IFeaturedModule | ICTAModule | IHighlightModule | IQuoteModule)[];
  postCollage: (IQuotePost | IPost | ILinkPost)[];
}

export const IdeasFactory = () => {
  const content: IIdeasFactory = useSelector(getIdeasFactoryContent);
  const isLoading: boolean = useSelector((state: any) => state.cms.isLoading);
  const postFilters: ITag[] = useSelector(
    (state: any) => state.app.ideasFactoryPostFilters
  );
  const filteredPosts: IPost[] = useSelector((state: any) =>
    state.cms.ideasFactoryPosts.filter((post: IPostWrapper) => {
      const { tagsNew } = post.fields;

      const doesContainFilteredTag = tagsNew?.some((tag: ITag) =>
        postFilters.some((filter: any) => {
          const tagName = tag.fields ? tag.fields.name : tag.name;

          if (filter.fields) {
            return filter.fields.name === tagName;
          }

          return filter.name === tagName;
        })
      );

      return doesContainFilteredTag;
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: types.GET_IDEAS_FACTORY_CONTENT });
    dispatch({ type: types.GET_IDEAS_FACTORY_POSTS });

    return () => {};
  }, [dispatch]);

  if (isLoading) {
    return (
      <AccessibleLoadNotifier isLoading={isLoading}>
        <DefaultLayout>
          <div className="Container">
            <div className="IdeasFactory-header">
              <div className="u-flex u-flexCol">
                <SkeletonText
                  className="h3 IdeasFactory-header-title"
                  text="Welcome to the IdeasFactory"
                />
                <SkeletonText
                  className="p u-mB-0"
                  text="Check in regularly to find out what we’ve been exploring."
                />
              </div>
              <SkeletonText
                text="Want to talk about the Ideas Factory for your business?"
                className="IdeasFactory-header-cta"
              />
            </div>
          </div>
        </DefaultLayout>
      </AccessibleLoadNotifier>
    );
  }

  const { modules, description, metaTitle, metaDescription } = content;

  console.log(modules);

  if (!modules || modules.length === 0) {
    return null;
  }

  return (
    <AccessibleLoadNotifier isLoading={isLoading}>
      <Meta title={metaTitle} description={metaDescription} />
      <DefaultLayout>
        <div className="IdeasFactory u-posRelative">
          <div className="SkeletonGrid SkeletonGrid--landing">
            <div className="Container">
              <div className="Grid SkeletonGrid-row">
                <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
                <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
                <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of3 u-xl-width1of4" />
                <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-xl-inlineBlock u-xl-width1of4" />
              </div>
              <div className="Grid SkeletonGrid-row">
                <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
                <div className="Grid-cell SkeletonGrid-row-cell u-width1of2 u-lg-width1of3 u-xl-width1of4" />
                <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-lg-inlineBlock u-lg-width1of3 u-xl-width1of4" />
                <div className="Grid-cell SkeletonGrid-row-cell u-hidden u-xl-inlineBlock u-xl-width1of4" />
              </div>
            </div>
          </div>
          <div className="IdeasFactory-layout">
            <div className="Container">
              <div className="IdeasFactory-header">
                <div className="IdeasFactory-header-title">
                  <h2 className="h3 u-mB-sm u-mT-0">
                    Welcome to the Ideas Factory
                  </h2>
                  <p className="u-mB-md">{description}</p>
                </div>
                <div className="IdeasFactory-header-cta">
                  <p className="IdeasFactory-header-cta-description u-mB-sm">
                    Want to talk about the Ideas Factory for your business?
                  </p>
                  <Button
                    onClick="https://alphero.com/contact"
                    className="Button Button--primary u-md-width2of3 u-lg-width1of1 u-textNoWrap"
                    label="LETS_TALK"
                    locationState={{ formType: "ideas" }}
                    actionEvent={createActionEvent(ACTION_EVENT.LETS_TALK)}
                    linkType="external"
                    linkTarget="_blank"
                  />
                </div>
              </div>
            </div>

            {filteredPosts.length > 0 && (
              <>
                <div className="IdeasFactory-filters Container">
                  <p className="IdeasFactory-filters-heading u-textNoWrap">
                    <FormattedMessage id="FILTERING_BY" />
                  </p>
                  <Tags hasRemoveIcon tags={postFilters} />
                </div>
                <PostCollageModule
                  content={{
                    posts: filteredPosts,
                    contentType: "",
                  }}
                />
              </>
            )}
            {filteredPosts.length === 0 &&
              modules &&
              modules.length &&
              modules.map((module: any) => (
                <RenderModule
                  ideasFactoryModule={{
                    ...module.fields,
                    contentType: module.sys.contentType.sys.id,
                  }}
                  key={`module-${module.sys.id}`}
                />
              ))}
          </div>
        </div>
      </DefaultLayout>
    </AccessibleLoadNotifier>
  );
};

const RenderModule = ({
  ideasFactoryModule,
}: {
  ideasFactoryModule:
    | IFeaturedModule
    | ICTAModule
    | IHighlightModule
    | IQuoteModule
    | IPostCollageModule;
}) => {
  let module = null;

  switch (ideasFactoryModule.contentType) {
    case IDEAS_FACTORY_MODULE_TYPES.POST_COLLAGE:
      module = (
        <PostCollageModule content={ideasFactoryModule as IPostCollageModule} />
      );
      break;
    case IDEAS_FACTORY_MODULE_TYPES.CTA:
      // module = <CTAModule content={ideasFactoryModule as ICTAModule} />;
      module = null;
      break;
    case IDEAS_FACTORY_MODULE_TYPES.HIGHLIGHT:
      module = (
        <HighlightModule content={ideasFactoryModule as IHighlightModule} />
      );
      break;
    case IDEAS_FACTORY_MODULE_TYPES.QUOTE:
      module = <QuoteModule content={ideasFactoryModule as IQuoteModule} />;
      break;
    default:
      module = (
        <FeaturedModule content={ideasFactoryModule as IFeaturedModule} />
      );
  }

  return module;
};

export const RenderModuleContent = ({
  content,
  isLandscape,
  shouldScrollToTopOnFilter = false,
}: {
  content: {
    fields: IQuotePost | IPost | ILinkPost;
    contentType: string;
    id?: any;
  };
  isLandscape: boolean;
  shouldScrollToTopOnFilter?: boolean;
}) => {
  let post = null;

  switch (content.contentType) {
    case IDEAS_FACTORY_CONTENT_TYPES.IDEAS_FACTORY_LINK:
      post = (
        <LinkPost
          content={
            {
              ...content.fields,
              ...content,
            } as ILinkPost
          }
        />
      );
      break;
    case IDEAS_FACTORY_CONTENT_TYPES.IDEAS_FACTORY_QUOTE: {
      post = (
        <QuotePost
          content={
            {
              ...content.fields,
              ...content,
            } as IQuotePost
          }
        />
      );
      break;
    }
    case IDEAS_FACTORY_CONTENT_TYPES.IDEAS_FACTORY_POSTS:
    default: {
      const postContent = content.fields as IPost;

      if (postContent.type === IDEAS_FACTORY_POST_TYPES.OPINION) {
        post = (
          <OpinionPost
            content={postContent}
            shouldScrollToTopOnFilter={shouldScrollToTopOnFilter}
          />
        );
      } else {
        post = (
          <SnapshotPost
            content={postContent}
            isLandscape={isLandscape}
            shouldScrollToTopOnFilter={shouldScrollToTopOnFilter}
          />
        );
      }
    }
  }

  return (
    <ScrollAnimation animateIn="animate__fadeIn" animateOnce duration={0.75}>
      {post}
    </ScrollAnimation>
  );
};
