import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v1 as uuid } from 'uuid';

/**
 * Displays a blurred version of an image while the full-size image loads.
 *
 * For context on this technique, see https://css-tricks.com/the-blur-up-technique-for-loading-background-images/
 *
 * Created for use in the case study heroes, but written so that there is room to use it elsewhere (probably needs some minor tweaks)
 *
 * @param {string} fullImage URL for the full image
 * @param {string} previewImage URL for the preview image (which will be blurred)
 * @param {node} clipPathContent Optional SVG content to be used as a clip path for the image
 * @param {string} preserveAspectRatio Optional value to use as the preserveAspectRatio attr of the images
 * @param {number} blurSize Size of the blur filter (stdDeviation provided to the SVG filter)
 * @param {func} onReady Function to call when the image has loaded
 * @param {func} onPreviewReady Function to call when the preview image has loaded
 */
export const BlurUpImageLoader = (props) => {
  const {
    aspectRatio,
    blurSize,
    clipPathContent,
    elementRef,
    fullImage,
    imageClass,
    preserveAspectRatio,
    previewImage,
    onError,
    onReady,
    onPreviewReady,
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);

  // Load main image
  useEffect(() => {
    setIsLoaded(false);

    if (fullImage == null || fullImage === '') {
      return () => {};
    }

    const img = new Image();

    img.src = fullImage;

    img.onload = () => {
      setIsLoaded(true);

      if (onReady) {
        onReady(fullImage);
      }
    };

    img.onerror = () => {
      setIsError(true);
      setIsLoaded(false);

      if (onError) {
        onError(fullImage);
      }
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [fullImage, onReady, onError]);

  // Load preview image
  useEffect(() => {
    setIsPreviewLoaded(false);

    if (previewImage == null || previewImage === '') {
      return () => {};
    }

    const img = new Image();

    img.src = previewImage;

    img.onload = () => {
      setIsPreviewLoaded(true);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [previewImage]);

  useEffect(() => {
    if (isPreviewLoaded) {
      if (onPreviewReady) {
        onPreviewReady();
      }
    }
  }, [isPreviewLoaded, onPreviewReady]);

  if (isError) {
    return null;
  }

  const clipPathId = uuid();

  const clipPathOuter = clipPathContent ? (
    <defs>
      <clipPath
        id={clipPathId}
        clipPathUnits="objectBoundingBox"
      >
        {clipPathContent}
      </clipPath>
    </defs>
  ) : '';

  const componentClasses = classNames('BlurUpLoader', imageClass,
    {
      'is-ready': isLoaded,
    });

  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      className={componentClasses}
      ref={elementRef}
      viewBox={aspectRatio ? `0 0 ${1 * aspectRatio} ${1}` : null}
    >
      {clipPathOuter}
      <filter id="blur" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feGaussianBlur stdDeviation={blurSize} edgeMode="duplicate" />
        <feComponentTransfer>
          <feFuncA type="discrete" tableValues="1 1" />
        </feComponentTransfer>
      </filter>

      <image
        filter={isPreviewLoaded ? 'url(#blur)' : ''}
        xlinkHref={isPreviewLoaded ? previewImage : ''}
        clipPath={clipPathContent !== null ? `url(#${clipPathId})` : ''}
        x="0"
        y="0"
        height="100%"
        width="100%"
        preserveAspectRatio={preserveAspectRatio}
        className="BlurUpLoader-previewImage"
        style={{ visibility: isPreviewLoaded ? 'visible' : 'hidden' }}
      />

      <image
        xlinkHref={isLoaded ? fullImage : ''}
        clipPath={clipPathContent !== null ? `url(#${clipPathId})` : ''}
        x="0"
        y="0"
        height="100%"
        width="100%"
        preserveAspectRatio={preserveAspectRatio}
        className="BlurUpLoader-fullImage"
      />
    </svg>
  );
};

BlurUpImageLoader.propTypes = {
  aspectRatio: PropTypes.number,
  blurSize: PropTypes.number,
  clipPathContent: PropTypes.node,
  elementRef: PropTypes.func,
  fullImage: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  onError: PropTypes.func,
  onPreviewReady: PropTypes.func,
  onReady: PropTypes.func,
  preserveAspectRatio: PropTypes.string,
  previewImage: PropTypes.string.isRequired,
};

BlurUpImageLoader.defaultProps = {
  aspectRatio: null,
  blurSize: 50,
  clipPathContent: null,
  elementRef: null,
  imageClass: '',
  onError: null,
  onPreviewReady: null,
  onReady: null,
  preserveAspectRatio: 'xMidYMid meet', // like background-position: center and background-size: contain in CSS
};
