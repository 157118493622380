import React from 'react';
import PropTypes from 'prop-types';

import { ICONS } from 'redux/modules/app/constants';

export const toDarkIcon = (iconName) => iconName.replace(/light/, 'dark');
export const toLightIcon = (iconName) => iconName.replace(/dark/, 'light');

export const ALL_ICONS = { ...ICONS.SVGs, ...ICONS.IcoMoonIcons };

export const Icon = (props) => {
  const { SVGs, IcoMoonIcons } = ICONS;
  const { name, className } = props;
  const isSVG = SVGs[name];
  const icon = IcoMoonIcons[name] || SVGs[name];

  if (!icon) {
    return null;
  }

  if (isSVG) {
    const classes = className ? `SVG ${className}` : 'SVG';

    return (
      <span className={classes} data-icon={name} aria-hidden="true">
        {icon}
      </span>
    );
  }

  // @TODO Update iconMoon-content.js file to only include iconNames,
  // Create the icoMoon span here by combining the name with the prefix 'icon-'
  // <span className='icon icon-quote'></span>,
  // This would require updating the icomoon-generator script.
  // Then this should be added to the React Boilerplate
  return icon;
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  className: null,
};
